import React, { useState, useEffect } from 'react';
import { Flex } from 'rebass';

import {
  Box,
  Grid,
  Button,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { ArrowForward, ArrowBackSharp } from '@material-ui/icons';
import api from '../../../../../../../services/api';
import { useStyles } from './styles';

const Client = (props) => {
  const classes = useStyles();
  const { nextStep, showroom, token, handleClose } = props;
  const [agents, setAgents] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [hasAgent, setHasAgent] = useState(true);
  const [hasClient, setHasClient] = useState(true);

  const [state, setState] = useState({
    agent: showroom.agent,
    customer: showroom.customer,
    customer_obs: showroom.customer_obs,
  });

  const { agent, customer, customer_obs } = state;

  const fetch = async (endpoint) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resp = await api.get(`/${endpoint}`);
    return resp.data;
  };

  useEffect(() => {
    async function fetchAgents() {
      const data = await fetch('agents');
      setAgents(data);
    }

    async function fetchCostumer() {
      const data = await fetch('customers');
      setCustomers(data);
    }

    fetchAgents();
    fetchCostumer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
    showroom[name] = event.target.value;
  };

  const verifyFields = (type) => {
    if (type === 'agent') {
      !showroom.agent ? setHasAgent(false) : setHasAgent(true);
    }

    if (type === 'customer') {
      !showroom.customer ? setHasClient(false) : setHasClient(true);
    }
  };

  const verifyAllFields = (type) => {
    !showroom.agent ? setHasAgent(false) : setHasAgent(true);
    !showroom.customer ? setHasClient(false) : setHasClient(true);
  };

  const handleChange = (event, item) => {
    if (item) {
      let name = event.target.id.split('-', 1)[0];
      setState({ ...state, [name]: Number(item.value) });
      showroom[name] = item;
      verifyFields(name);
    }
  };

  const continues = (e) => {
    if (showroom.agent && showroom.customer) {
      nextStep();
    } else {
      verifyAllFields();
    }
  };

  const agentProps = {
    options:
      agents &&
      agents.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  const customerProps = {
    options:
      customers &&
      customers.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  return (
    <>
      <Box>
        <form className={classes.root} noValidate>
          <input type="hidden" value="something" />

          <div className={classes.row}>
            <Typography pt={5} variant="h5" component="h3">
              <Flex justifyContent="center">Informações do Solicitante</Flex>
            </Typography>
            <Autocomplete
              {...agentProps}
              id="agent"
              value={agent}
              closeText="Fechar"
              clearText="Limpar"
              defaultValue={agent}
              clearOnEscape
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => handleChange(event, newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!hasAgent}
                  label="Solicitante"
                  margin="normal"
                  required
                  fullWidth
                  autocomplete="new-password"
                >
                  {' '}
                  Não encontrado
                </TextField>
              )}
            />
          </div>
          <div className={classes.row}>
            <Typography pt={5} variant="h5" component="h3">
              <Flex justifyContent="center">Informações do Cliente</Flex>
            </Typography>
            <Autocomplete
              {...customerProps}
              id="customer"
              value={customer}
              closeText="Fechar"
              clearText="Limpar"
              defaultValue={customer}
              clearOnEscape
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => handleChange(event, newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!hasClient}
                  label="Cliente"
                  margin="normal"
                  required
                  fullWidth
                  autocomplete="new-password"
                >
                  {' '}
                  Não encontrado
                </TextField>
              )}
            />
          </div>

          <div className={classes.row}>
            <TextField
              id="customer_obs"
              type="text"
              label="Observação do Cliente"
              value={customer_obs}
              margin="normal"
              fullWidth
              onChange={handleInputChange('customer_obs')}
              className={classes.textField}
            />
          </div>
        </form>
      </Box>
      <Box>
        <Divider />
        <div className={classes.bottom}>
          <Grid
            container
            direction="row"
            className={classes.buttons}
            alignItems="flex-end"
            justify="space-between"
          >
            <Button
              variant="contained"
              onClick={handleClose.bind(this)}
              startIcon={<ArrowBackSharp />}
              className={classes.button}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              onClick={continues}
              endIcon={<ArrowForward />}
              className={classes.button}
            >
              Avançar
            </Button>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default Client;
