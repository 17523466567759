/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import Client from './components/Client';
import Roominess from './components/Roominess';
import RoominessHas from './components/RoominessHas';
import Ending from './components/Ending';
import Annotations from './components/Annotations';
import Carrier from './components/Carrier';
import Showroom from './components/Showroom';

const data = {
  agent: undefined,
  customer: undefined,
  showroomType: undefined,
  laborType: undefined,
  projectType: undefined,
  payment_method_id: '',
  carrier_id: '',
  rule_id: '',
  user_id: '',
  customer_obs: '',
  showroom_obs: '',
  cif_shipping: false,
  fob_shipping: false,
  height: undefined,
  width: undefined,
  length: undefined,
  forehead_height: undefined,
  forehead_depth: undefined,
  total_height: '',
  total_square_feet_metters: '',
  pilar: false,
  fire_extinguisher: false,
  forehead: false,
  circuit_breaker: false,
  window_or_door: false,
  beam: false,
  other_items: undefined,
  mix: '',
  product_sugestion: '',
  manager_obs: '',
  start_date: '',
  end_date: '',
  budget_limit: '',
  flag: '',
  flag_description: '',
};

const ShowRoomNewModal = props => {
  const { handleClose, handleOpenNotification } = props;
  const [showroom, setShowroom] = useState(data);
  const token = localStorage.getItem('@icep-app/token');
  const [step, setStep] = useState(0);

  useEffect(() => {
    setShowroom(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <Client
            token={token}
            showroom={showroom}
            nextStep={nextStep}
            handleClose={handleClose}
            handleChange={handleChange}
          />
        );

      case 1:
        return (
          <Showroom
            token={token}
            showroom={showroom}
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
          />
        );
      case 2:
        return (
          <Carrier
            token={token}
            showroom={showroom}
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
          />
        );
      case 3:
        return (
          <Roominess
            token={token}
            showroom={showroom}
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
          />
        );
      case 4:
        return (
          <RoominessHas
            token={token}
            showroom={showroom}
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
          />
        );
      case 5:
        return (
          <Annotations
            token={token}
            showroom={showroom}
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
          />
        );
      case 6:
        return (
          <Ending
            token={token}
            showroom={showroom}
            nextStep={nextStep}
            prevStep={prevStep}
            handleOpenNotification={handleOpenNotification}
            handleClose={handleClose}
            handleChange={handleChange}
          />
        );
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = input => e => {
    setShowroom({ [input]: e.target.value });
  };

  return getStepContent(step);
};
export default ShowRoomNewModal;
