import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import {
  Grid,
  Button,
  Drawer,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';
import _ from 'lodash';
import DoneAll from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:320px)': {
      marginLeft: '90px',
      marginTop: '15px',
      marginBottom: '0px',
    },
  },
  drawer: {
    width: 420,
    maxWidth: '100%',
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  contentSection: {
    padding: theme.spacing(2, 0),
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  tags: {
    marginTop: theme.spacing(1),
  },
  minAmount: {
    marginRight: theme.spacing(3),
  },
  maxAmount: {
    marginLeft: theme.spacing(3),
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Filter = (props) => {
  const {
    onClose,
    onClear,
    onFilter,
    className,
    filterParams,
    isDateRequired,
    ...rest
  } = props;

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);
  const [machines, setMachines] = useState([]);

  const [state, setState] = useState({
    flag: filterParams.flag,
    selectMachines: filterParams.selectMachines,
    startDate: filterParams.startDate,
    endDate: filterParams.endDate,
  });
  const [alignment, setAlignment] = useState('thisMonth');

  const { selectMachines, startDate, endDate } = state;

  const machinesProps = {
    options:
      machines &&
      machines.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  useEffect(() => {
    async function handleGetMachines() {
      const resp = await api.get(`/machines`);
      setMachines(resp.data);
    }
    handleGetMachines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItem = (item) => {
    if (_.isArray(item)) {
      let selecteId = [];
      item.forEach((element) => {
        selecteId.push(element.value);
      });

      return selecteId;
    } else {
      return item;
    }
  };

  const handleChange = (event, item) => {
    if (item) {
      let name = event.target.id.split('-', 1)[0];
      setState({ ...state, [name]: item });
      filterParams[name] = handleItem(item);
    } else {
      let name = event.target.id;
      setState({ ...state, [name]: event.target.value });
      filterParams[name] = event.target.value;
    }
  };

  const handleInputChange = (event, newAlignment) => {
    if (newAlignment && newAlignment.length) {
      setState({ ...state, flag: newAlignment });
      setAlignment(newAlignment);
      filterParams['flag'] = newAlignment;
    }
  };

  const handleClear = () => {
    setState({
      flag: '',
      selectMachines: [],
      startDate: '',
      endDate: '',
    });
    onClear();
    handleFilterClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onFilter &&
      onFilter({
        flag: filterParams.flag,
        selectMachines: _.join(filterParams.selectMachines, ','),
        startDate: state.startDate,
        endDate: state.endDate,
      });
    handleFilterClose();
  };

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  return (
    <Grid {...rest} className={clsx(classes.root, className)}>
      <Button
        size="small"
        color="primary"
        onClick={handleFilterOpen}
        variant="outlined"
        className={classes.filterButton}
      >
        <FilterListIcon className={classes.filterIcon} />
        <Typography className={classes.filterText}>Filtros</Typography>
      </Button>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        onClose={handleFilterClose}
        open={openFilter}
        variant="temporary"
      >
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
        >
          <div className={classes.header}>
            <Button onClick={handleFilterClose} size="small">
              <CloseIcon className={classes.buttonIcon} />
              Fechar
            </Button>
          </div>
          <Divider />
          <div className={classes.content}>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <Typography component="p" gutterBottom variant="overline">
                  Maquinas
                </Typography>

                <Autocomplete
                  {...machinesProps}
                  id="selectMachines"
                  name="selectMachines"
                  value={selectMachines}
                  multiple
                  onChange={(event, newValue) => handleChange(event, newValue)}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecione"
                      variant="outlined"
                      fullWidth
                    >
                      {' '}
                      Não encontrado
                    </TextField>
                  )}
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <Typography component="p" gutterBottom variant="overline">
                Status
              </Typography>
              <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
              >
                <Grid item>
                  <ToggleButtonGroup
                    size="medium"
                    value={alignment}
                    exclusive
                    onChange={handleInputChange}
                  >
                    <ToggleButton key={1} value="0">
                      Pendente
                    </ToggleButton>
                    <ToggleButton key={2} value="1">
                      Em Andamento
                    </ToggleButton>
                    <ToggleButton key={3} value="2">
                      Finalizado
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <Typography component="p" gutterBottom variant="overline">
                  Selecione o Período Desejado
                </Typography>
                <TextField
                  required={
                    isDateRequired ||
                    (startDate && startDate !== '') ||
                    (endDate && endDate !== '')
                  }
                  id="startDate"
                  label="Data Inicial"
                  type="date"
                  defaultValue={startDate}
                  className={classes.textField}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setState({ ...state, startDate: e.target.value })
                  }
                />
                &nbsp; &nbsp; &nbsp;
                <TextField
                  required={
                    isDateRequired ||
                    (startDate && startDate !== '') ||
                    (endDate && endDate !== '')
                  }
                  id="endDate"
                  label="Data Final"
                  type="date"
                  fullWidth
                  defaultValue={endDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setState({ ...state, endDate: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.actions}>
            <Button fullWidth onClick={handleClear} variant="contained">
              <DeleteIcon className={classes.buttonIcon} />
              Limpar
            </Button>
            <Button color="primary" fullWidth type="submit" variant="contained">
              <DoneAll className={classes.buttonIcon} />
              Aplicar Filtro
            </Button>
          </div>
        </form>
      </Drawer>
    </Grid>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
};

export default Filter;
