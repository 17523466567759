import React, { useState, useEffect } from 'react';
import { easeQuadInOut } from 'd3-ease';
import { CircularProgressbar } from 'react-circular-progressbar';
import _ from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import { Grid, CircularProgress } from '@material-ui/core';
import Paginate from '../../../components/Paginate';
import { Box, Flex } from 'rebass';
import { useStyles } from '../../styles';
import AnimatedProgressProvider from '../../../components/AnimatedProgressProvider';
import 'react-circular-progressbar/dist/styles.css';
import { formatDate } from '../../../utils/format';
import api from '../../../services/api';
import Filter from '../../../components/Filter';

const EarlyCarStocks = () => {
  const classes = useStyles();
  const [progress, setProgress] = useState(true);
  const [carsData, setCarsData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState();
  const [filterParams, setFilterParams] = useState({});
  const idUser = localStorage.getItem('@icep-app/iduser');
  const unity_id = localStorage.getItem('@icep-app/unity');
  const params = {
    period: '',
    selectedLine: selectedLine,
  };

  useEffect(() => {
    async function fetch() {
      if (!!selectedLine) {
        const resp = await api.get(
          `/earlycarstocks/unity/${unity_id}/line/${selectedLine}?page=${
            page + 1
          }`,
        );
        setTotalRows(resp.data.metadata.totalRows);
        setTotalPages(resp.data.metadata.totalPages);
        setCarsData(resp.data.source);
        setProgress(false);
      }
    }
    fetch();
  }, [page, selectedLine, unity_id]);

  const onPageChange = async (item) => {
    setPage(item.selected);
  };

  useEffect(() => {
    async function handleGetLines() {
      const resp = await api.get(
        `/userlines/${idUser}/unity/${unity_id}/lines`,
      );
      setLines(resp?.data);
      setSelectedLine(resp?.data[0]?.line_id);
    }
    handleGetLines();
  }, [idUser, unity_id]);

  const handleFilter = async (item) => {
    let line_id =
      item.selectedLine && item.selectedLine.value
        ? item.selectedLine.value
        : params.selectedLine;
    setSelectedLine(line_id);
  };

  const handleClear = async () => {
    setFilterParams({ period: filterParams.period });
    setSelectedLine(lines[0]?.line_id);
  };

  const getLine = (id) => {
    var filtered = lines.filter((item) => {
      return item.line_id === parseInt(id);
    });
    return filtered[0]?.line?.description;
  };

  return (
    <>
      <Grid className={classes.qualityRoot}>
        <Grid
          alignItems="flex-end"
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              Produção
            </Typography>
            <Typography component="h1" variant="h3">
              Carro Box
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {getLine(selectedLine)}
            </Typography>
          </Grid>
          <Grid item>
            <Filter
              onClear={handleClear}
              onFilter={handleFilter}
              className={classes.filterButton}
              showPeriod={false}
              filterParams={filterParams}
            />
          </Grid>
        </Grid>
        {progress ? (
          <Paper className={classes.paper}>
            <Box container justifyContent="center" alignItems="center">
              <Flex justifyContent="center" alignItems="center">
                <CircularProgress
                  className={classes.circularProgress}
                  color="secondary"
                />
              </Flex>
            </Box>
          </Paper>
        ) : (
          <div className={classes.results}>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Total de {totalRows} registros econtrados. Pagina {page + 1} de{' '}
              {totalPages}
            </Typography>
            <Paper>
              <List dense className={classes.root}>
                {carsData?.map((row) => (
                  <React.Fragment key={row.id}>
                    <ListItem key={row.id} button alignItems="flex-start">
                      <ListItemAvatar className={classes.progress}>
                        <AnimatedProgressProvider
                          valueStart={0}
                          valueEnd={row.quantity}
                          duration={1.4}
                          easingFunction={easeQuadInOut}
                        >
                          {(value) => {
                            const roundedValue = _.round(value, 0);
                            return (
                              <CircularProgressbar
                                value={value}
                                maxValue={40}
                                text={roundedValue}
                                styles={{
                                  root: {
                                    width: '70px',
                                  },
                                  path: {
                                    stroke: '#5C6BC0',
                                    strokeLinecap: 'butt',
                                    transition: 'none',
                                  },
                                  trail: {
                                    stroke: '#d6d6d6',
                                  },
                                  text: {
                                    fill: '#9FA8DA',
                                    fontSize: '20px',
                                    fontWeight: '700',
                                  },
                                }}
                              />
                            );
                          }}
                        </AnimatedProgressProvider>
                      </ListItemAvatar>
                      <ListItemText
                        className={classes.text}
                        primary={
                          row.product_spec.reference +
                          ' - ' +
                          (row.product_spec.format &&
                          row.product_spec.format.description
                            ? row.product_spec.format.description
                            : '')
                        }
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {formatDate(row.date)}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider className={classes.divider} />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </div>
        )}
        <div className={classes.paginate}>
          <Paginate
            pageCount={totalPages}
            onPageChange={onPageChange}
            pageRangeDisplayed={1}
          />
        </div>
      </Grid>
    </>
  );
};

export default EarlyCarStocks;
