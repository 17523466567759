import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, #root {
    height: 100%;
  }
  
  body {
    background: #f9f9fc;
    font: 14px 'Roboto', sans-serif;
    text-rendering: optimizeLegibility;
    color: #383838;
    -webkit-font-smoothing: antialiased !important;
  }
`;

export default GlobalStyle;
