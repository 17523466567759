import React, { useState } from "react";
import { Flex } from "rebass";
import {
  Box,
  Grid,
  Button,
  Divider,
  Checkbox,
  FormGroup,
  Typography,
  FormControl,
  FormControlLabel
} from "@material-ui/core";
import { ArrowForward, ArrowBackSharp } from "@material-ui/icons";
import { useStyles } from "./styles";

const RoominessHas = props => {
  const classes = useStyles();
  const { nextStep, prevStep, showroom } = props;

  const [state, setState] = useState({
    beam: showroom.beam,
    pilar: showroom.pilar,
    forehead: showroom.forehead,
    window_or_door: showroom.window_or_door,
    circuit_breaker: showroom.circuit_breaker,
    fire_extinguisher: showroom.fire_extinguisher
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
    showroom[name] = event.target.checked;
  };

  const {
    beam,
    pilar,
    forehead,
    window_or_door,
    circuit_breaker,
    fire_extinguisher
  } = state;

  //const error = [gilad, jason, antoine].filter(v => v).length !== 2;

  const continues = e => {
    nextStep();
  };

  const back = e => {
    prevStep();
  };

  return (
    <>
      <Box>
        <Typography className={classes.tipografy} variant="h5" component="h3">
          <Flex justifyContent="center">Espaço Possui</Flex>
        </Typography>
        <div className={classes.roominess}>
          <Grid container justify="center">
            <FormControl
              component="fieldset"
              className={classes.formControlRoominess}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pilar}
                      onChange={handleChange("pilar")}
                      value="pilar"
                    />
                  }
                  label="Pilar"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fire_extinguisher}
                      onChange={handleChange("fire_extinguisher")}
                      value="fire_extinguisher"
                    />
                  }
                  label="Estintor"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={beam}
                      onChange={handleChange("beam")}
                      value="beam"
                    />
                  }
                  label="Vigas"
                />
              </FormGroup>
              {/* <FormHelperText>Be careful</FormHelperText> */}
            </FormControl>
            <FormControl
              required
              component="fieldset"
              className={classes.formControlRoominess}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={forehead}
                      onChange={handleChange("forehead")}
                      value="forehead"
                    />
                  }
                  label="Testeira"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={circuit_breaker}
                      onChange={handleChange("circuit_breaker")}
                      value="circuit_breaker"
                    />
                  }
                  label="Disjuntor"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={window_or_door}
                      onChange={handleChange("window_or_door")}
                      value="window_or_door"
                    />
                  }
                  label="Portas / Janelas"
                />
              </FormGroup>
              {/*<FormHelperText>You can display an error</FormHelperText> */}
            </FormControl>
          </Grid>
        </div>
      </Box>
      <Box>
        <div className={classes.bottom}>
          <Divider />
          <Grid
            className={classes.buttons}
            alignItems="flex-end"
            container
            justify="space-between"
          >
            <Button
              variant="outlined"
              onClick={back}
              startIcon={<ArrowBackSharp />}
              className={classes.button}
            >
              Voltar
            </Button>
            <Button
              variant="outlined"
              onClick={continues}
              endIcon={<ArrowForward />}
              className={classes.button}
            >
              Avançar
            </Button>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default RoominessHas;
