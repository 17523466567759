import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Box, Flex } from 'rebass';

import PieChart from '../../../components/PieChart';
import api from '../../../services/api';
import Filter from '../../../components/Filter';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  top: {
    flexGrow: 1,
    backgroundColor: 'text',
    paddingBottom: '15px',
  },
  title: {
    paddingLeft: '40px',
    paddingTop: '25px',
    flexGrow: 1,
    backgroundColor: '#f9f9fc',
  },
  container: {
    marginTop: theme.spacing(3),
  },
  barChartIcon: {
    marginRight: theme.spacing(1),
  },
  circularProgress: {
    margin: '20px',
  },
}));

const VisualAnalysisOSA = () => {
  const classes = useStyles();
  const [filterParams, setFilterParams] = useState({});
  const [data, setData] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState();
  const unity = localStorage.getItem('@icep-app/unity');
  const token = localStorage.getItem('@icep-app/token');
  const unity_id = localStorage.getItem('@icep-app/unity');
  const [progress, setProgress] = useState(true);
  const [subtext, setSubtext] = useState('Este mês');
  const idUser = localStorage.getItem('@icep-app/iduser');

  useEffect(() => {
    async function fetchData() {
      const resp = await api.get(
        `/spcvisualanalysisosas/unity/${unity}/line/${selectedLine}/defects/count/`,
      );
      setData(resp.data);
      setProgress(false);
    }
    fetchData();
  }, [selectedLine, unity]);

  useEffect(() => {
    async function handleGetLines() {
      const resp = await api.get(`/userlines/${idUser}/unity/${unity}/lines`);
      setLines(resp.data);
      setSelectedLine(resp.data[0].line_id);
    }
    handleGetLines();
  }, [idUser, unity]);

  const params = {
    rework_flag: '',
    period: '',
    selectedLine: '',
    selectedLines: '',
    selectShifts: '',
    selectFormats: '',
    reference: '',
    endDate: '',
    startDate: '',
  };

  const handleSubText = (item) => {
    switch (item) {
      case 'today':
        setSubtext('Hoje');
        break;
      case 'yesterday':
        setSubtext('Ontem');
        break;
      case 'thisMonth':
        setSubtext('Este Mês');
        break;
      case 'custom':
        setSubtext('Customizado');
        break;

      default:
        break;
    }
  };

  const handle = (
    rework_flag,
    period,
    format,
    shift,
    reference,
    startDate,
    endDate,
    selectedLines,
  ) => {
    let queryParams =
      period === 'custom' ||
      selectedLines ||
      format ||
      shift ||
      reference ||
      rework_flag
        ? '?'
        : '';
    let p =
      period === 'custom'
        ? 'start_date=' + startDate + '&end_date=' + endDate
        : '';
    let l = selectedLines ? 'inspected_line_ids=' + selectedLines : '';
    let r = reference ? 'reference=' + reference : '';
    let s = shift ? 'shift_id=' + shift : '';
    let f = format ? 'format_id=' + format : '';
    let rf = rework_flag ? 'rework_flag=' + rework_flag : '';

    let array = [p, l, r, s, f, rf];
    let newArray = [];

    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if (item !== '') {
        newArray.push(item);
      }
    }

    for (let i = 0; i < newArray.length; i++) {
      const item = newArray[i];
      if (i === 0) {
        queryParams = queryParams + item;
      } else {
        queryParams = queryParams + '&' + item;
      }
    }

    return queryParams;
  };

  const handleFilter = async (item) => {
    setProgress(true);
    handleSubText(item.period);

    let period = item.period ? item.period : params.period;
    let rework_flag = item.rework_flag ? item.rework_flag : params.rework_flag;
    let endDate = item.endDate ? item.endDate : params.endDate;
    let startDate = item.startDate ? item.startDate : params.startDate;
    let line_id = item?.selectedLine?.value
      ? item?.selectedLine?.value
      : params.selectedLine?.value;

    let line_ids = item?.selectedLines
      ? item?.selectedLines
      : params.selectedLines;

    let reference = item.reference ? item.reference : params.reference;

    let selectFormats = item.selectFormats
      ? item.selectFormats
      : params.selectFormats;

    let selectShifts = item.selectShifts
      ? item.selectShifts
      : params.selectShifts;

    setSelectedLine(line_id);

    const resp = await api.get(
      `/spcvisualanalysisosas/unity/${unity_id}/line/${line_id}/defects/count/${
        period !== 'thisMonth' ? period : ''
      }${handle(
        rework_flag,
        period,
        selectFormats,
        selectShifts,
        reference,
        startDate,
        endDate,
        line_ids,
      )}`,
    );

    setData(resp.data);
    setProgress(false);
  };

  const handleClear = async (item) => {
    setProgress(true);
    setFilterParams({ period: filterParams.period });

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resp = await api.get(
      `/spcvisualanalysisosas/unity/${unity_id}/line/${selectedLine}/defects/count`,
    );
    setData(resp.data);
    setProgress(false);
    setSelectedLine();
  };

  const getLine = (id) => {
    var filtered = lines.filter((item) => {
      return item.line_id === parseInt(id);
    });
    return filtered[0]?.line?.description;
  };

  return (
    <Grid className={classes.root}>
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            Ranking de Defeito Barrado
          </Typography>
          <Typography component="h1" variant="h3">
            Mês e Dia
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {getLine(selectedLine)}
          </Typography>
        </Grid>
        <Grid item>
          <Filter
            onClear={handleClear}
            onFilter={handleFilter}
            className={classes.filterButton}
            filterParams={filterParams}
            isDateRequired={true}
            showReworkedFlag={true}
            showCustomPeriod={true}
            showMultipleLines={true}
          />
        </Grid>
      </Grid>
      {progress ? (
        <Paper className={classes.container}>
          <Box container justifyContent="center" alignItems="center">
            <Flex justifyContent="center" alignItems="center">
              <CircularProgress
                className={classes.circularProgress}
                color="secondary"
              />
            </Flex>
          </Box>
        </Paper>
      ) : (
        <>
          <Grid className={classes.container} justify="space-between" container>
            <Grid item lg={12} xl={12} xs={12}>
              {data && data.data && data.data.length > 0 ? (
                <Card>
                  <PieChart
                    data={data.data}
                    title="Controle de Qualidade"
                    subtext={subtext}
                  />
                  <br />
                  <Typography
                    fontSize={20}
                    className={classes.circularProgress}
                    align="center"
                    variant="body2"
                    color="black"
                  >
                    Total barrado:{' '}
                    {data.count &&
                      data.count.total &&
                      data.count.total.replace('.', ',')}
                    m²
                  </Typography>
                </Card>
              ) : (
                <Card>
                  <Typography
                    align="center"
                    color="textPrimary"
                    variant="body2"
                    className={classes.circularProgress}
                  >
                    Sem dados
                  </Typography>
                </Card>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default VisualAnalysisOSA;
