import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    fab: {
      right: theme.spacing(4),
      position: 'fixed',
      bottom: theme.spacing(4)
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    progress: {
      position: 'absolute',
      top: '50%'
    }
  }));