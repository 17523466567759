import { Grid } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { Text } from '../../styles/components';

const Container = styled.div`
  width: 100%;
  padding: 14px 0px;
`;

const Cell = styled.div`
  height: 100%;
  display: flex;
  padding: 14px 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor || '#ccc'};
`;

export default function OEEGroupedLines({ data = [] }) {
  return (
    <Container>
      {data &&
        data.length > 0 &&
        data.map((item) => {
          return (
            <Grid container style={{ marginBottom: 24 }}>
              {/* Linha */}
              <Grid item lg={2} sm={12} xs={12}>
                <Cell>
                  <Text
                    fontSizeMobile="18px"
                    fontSize="18px"
                    fontWeight={700}
                    color="#000"
                    textAlign="center"
                  >
                    {item.line.description}
                  </Text>
                </Cell>
              </Grid>

              {/* Disponibilidade */}
              <Grid item lg={2} sm={12} xs={12}>
                <Cell backgroundColor="green">
                  <Text
                    fontSizeMobile="18px"
                    fontSize="24px"
                    fontWeight={700}
                    fontFamily="Poppins"
                    color="#fff"
                    textAlign="center"
                  >
                    <NumberFormat
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(item.availability)}
                      displayType={'text'}
                    />
                  </Text>
                  <Text
                    fontSizeMobile="10px"
                    fontSize="14px"
                    color="#fff"
                    textAlign="center"
                  >
                    Disponibilidade (DGFM)
                  </Text>
                </Cell>
              </Grid>

              {/* Eficiência */}
              <Grid item lg={1} sm={12} xs={12}>
                <Cell backgroundColor="#f7ca49">
                  <Text
                    fontSizeMobile="18px"
                    fontSize="24px"
                    fontWeight={700}
                    fontFamily="Poppins"
                    color="#000"
                    textAlign="center"
                  >
                    <NumberFormat
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(item.performance)}
                      displayType={'text'}
                    />
                  </Text>
                  <Text
                    fontSizeMobile="10px"
                    fontSize="14px"
                    color="#000"
                    textAlign="center"
                  >
                    Eficiência
                  </Text>
                </Cell>
              </Grid>

              {/* Qualidade */}
              <Grid item lg={1} sm={12} xs={12}>
                <Cell backgroundColor="blue">
                  <Text
                    fontSizeMobile="18px"
                    fontSize="24px"
                    fontWeight={700}
                    fontFamily="Poppins"
                    color="#fff"
                    textAlign="center"
                  >
                    <NumberFormat
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(item.quality)}
                      displayType={'text'}
                    />
                  </Text>
                  <Text
                    fontSizeMobile="10px"
                    fontSize="14px"
                    color="#fff"
                    textAlign="center"
                  >
                    Qualidade
                  </Text>
                </Cell>
              </Grid>

              {/* OEE */}
              <Grid item lg={2} sm={12} xs={12}>
                <Cell backgroundColor="orange">
                  <Text
                    fontSizeMobile="18px"
                    fontSize="24px"
                    fontWeight={700}
                    fontFamily="Poppins"
                    color="#fff"
                    textAlign="center"
                  >
                    <NumberFormat
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={Number(item.oee)}
                      displayType={'text'}
                    />
                  </Text>
                  <Text
                    fontSizeMobile="10px"
                    fontSize="14px"
                    color="#fff"
                    textAlign="center"
                  >
                    OEE
                  </Text>
                </Cell>
              </Grid>

              {/* MTBF */}
              <Grid item lg={2} sm={12} xs={12}>
                <Cell backgroundColor="#27ab83">
                  <Text
                    fontSizeMobile="18px"
                    fontSize="24px"
                    fontWeight={700}
                    fontFamily="Poppins"
                    color="#fff"
                    textAlign="center"
                  >
                    {item.mtbf}
                  </Text>
                  <Text
                    fontSizeMobile="10px"
                    fontSize="14px"
                    color="#fff"
                    textAlign="center"
                  >
                    MTBF
                  </Text>
                </Cell>
              </Grid>

              {/* MTTR */}
              <Grid item lg={2} sm={12} xs={12}>
                <Cell backgroundColor="#65d5ac">
                  <Text
                    fontSizeMobile="18px"
                    fontSize="24px"
                    fontWeight={700}
                    fontFamily="Poppins"
                    color="#fff"
                    textAlign="center"
                  >
                    {item.mttr}
                  </Text>
                  <Text
                    fontSizeMobile="10px"
                    fontSize="14px"
                    color="#fff"
                    textAlign="center"
                  >
                    MTTR
                  </Text>
                </Cell>
              </Grid>
            </Grid>
          );
        })}
    </Container>
  );
}
