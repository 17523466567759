import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import io from 'socket.io-client';

import {
  Content,
  Row,
  Text,
  Span,
  Logo,
  Container,
} from '../../styles/components';

import api from '../../services/api';
import logo from '../../assets/images/icep.png';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { useStyles } from '../styles';
import NumberFormat from 'react-number-format';

const ProductionData = ({ ...props }) => {
  const classes = useStyles();
  const [productions, setProductions] = useState([]);
  const unity = localStorage.getItem('@icep-app/unity');

  const handleGetData = useCallback(async () => {
    const productions = await api.get(`/productions/unity/${unity}/line/all`);

    setProductions(productions.data);
  }, [unity]);

  const registerToSocket = useCallback(() => {
    const socket = io('https://api.iforth.net');

    socket.on('DISCARD_UPDATE', (newData) => {
      if (unity) handleGetData();
    });

    socket.on('PRODUCTION_UPDATE', (newData) => {
      if (unity) handleGetData();
    });
  }, [handleGetData, unity]);

  useEffect(() => {
    registerToSocket();
  }, [registerToSocket]);

  useEffect(() => {
    if (unity) handleGetData();
  }, [handleGetData, unity]);

  return (
    <Content>
      <Row margin="10px 0px 0px 10px">
        <Span display="flex" flexDirection="column" width="33%">
          <Text fontSize="24px" fontWeight={900}>
            Produção
          </Text>
        </Span>

        <Span
          display="flex"
          flexDirection="column"
          width="33%"
          alignItems="flex-end"
        >
          <Text padding="0px 20px 0px 0px">
            <Logo src={logo} alt="logo" />
          </Text>
        </Span>
      </Row>
      <Row>
        <Text fontSize="16px" fontWeight={400} color="#ccc" padding="0px 10px">
          Apresenta em tempo real, indicadores de produção, qualidade e
          eficiência.
        </Text>
      </Row>

      <div className={classes.results}>
        {productions &&
          productions.map((item) => (
            <React.Fragment key={item.id}>
              <Card>
                <CardContent className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item lg={3} sm={6} xs={6}>
                      <Container>
                        <Grid className={classes.stats}>
                          <Typography variant="body2">Linha</Typography>
                          <Typography variant="h6">
                            {item.line_id + ' - ' + item.line.description}
                          </Typography>
                        </Grid>
                      </Container>
                    </Grid>

                    <Grid item lg={3} sm={6} xs={6}>
                      <Container>
                        <Grid className={classes.stats}>
                          <Typography variant="body2">Produção mês</Typography>
                          <Typography variant="h6">
                            <NumberFormat
                              fixedDecimalScale={true}
                              decimalScale={2}
                              thousandSeparator="."
                              decimalSeparator=","
                              value={Number(item.month_production)}
                              displayType={'text'}
                            />
                            m²
                          </Typography>
                          <br />
                          <Typography variant="body2">Produção dia</Typography>
                          <Typography variant="h6">
                            <NumberFormat
                              fixedDecimalScale={true}
                              decimalScale={2}
                              thousandSeparator="."
                              decimalSeparator=","
                              value={Number(item.day_production)}
                              displayType={'text'}
                            />
                            m²
                          </Typography>
                        </Grid>
                      </Container>
                    </Grid>

                    <Grid item lg={3} sm={6} xs={6}>
                      <Container>
                        <Typography variant="body2">Qualidade mês</Typography>
                        <Typography variant="h6">
                          <NumberFormat
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            value={Number(item.month_quality)}
                            displayType={'text'}
                          />
                          %
                        </Typography>
                        <br />
                        <Typography variant="body2">Qualidade dia</Typography>
                        <Typography variant="h6">
                          <NumberFormat
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            value={Number(item.day_quality)}
                            displayType={'text'}
                          />
                          %
                        </Typography>
                      </Container>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={6}>
                      <Container>
                        <Typography variant="body2">Eficiência mês</Typography>
                        <Typography variant="h6">
                          <NumberFormat
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            value={Number(item.month_efficiency)}
                            displayType={'text'}
                          />
                          %
                        </Typography>
                        <br />
                        <Typography variant="body2">Eficiência dia</Typography>
                        <Typography variant="h6">
                          <NumberFormat
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            value={Number(item.day_efficiency)}
                            displayType={'text'}
                          />
                          %
                        </Typography>
                      </Container>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <br />
            </React.Fragment>
          ))}
      </div>
    </Content>
  );
};

export default ProductionData;
