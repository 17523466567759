import React, { useState, useEffect } from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import _ from 'lodash';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { easeQuadInOut } from 'd3-ease';
import Typography from '@material-ui/core/Typography';
import Modal from '../../../components/Modal';
import AnimatedProgressProvider from '../../../components/AnimatedProgressProvider';
import { Grid, CircularProgress, colors } from '@material-ui/core';
import 'react-circular-progressbar/dist/styles.css';
import IconButton from '@material-ui/core/IconButton';
import RemovedRedEye from '@material-ui/icons/RemoveRedEyeRounded';
import { formatDate } from '../../../utils/format';
import api from '../../../services/api';
import Label from '../../../components/Label';
import { Box, Flex } from 'rebass';
import Paginate from '../../../components/Paginate';
import Filter from '../../../components/Filter';
import { useStyles } from '../../styles';

const StyledListItemText = styled(ListItemText)`
  max-width: auto;
  @media (max-width: 780px) {
    max-width: 120px;
  }
`;

const QualityItems = () => {
  const classes = useStyles();
  const token = localStorage.getItem('@icep-app/token');
  const unity = localStorage.getItem('@icep-app/unity');
  const idUser = localStorage.getItem('@icep-app/iduser');
  const [progress, setProgress] = useState(true);
  const [selectedLine, setSelectedLine] = useState();
  const [openNew, setOpenNew] = useState(false);
  const [motivation, setMotivation] = useState([]);
  const [carsData, setCarsData] = useState([]);
  const [lines, setLines] = useState([]);
  const [filterParams, setFilterParams] = useState({});

  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const params = {
    selectedLine: selectedLine,
  };

  useEffect(() => {
    async function fetch() {
      if (!!selectedLine) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const resp = await api.get(
          `/spcqualities/unity/${unity}/line/${selectedLine}?page=${page + 1}`,
        );
        setTotalRows(resp.data.metadata.totalRows);
        setTotalPages(resp.data.metadata.totalPages);
        setCarsData(resp.data.source);
        setProgress(false);
      }
    }
    fetch();
  }, [page, selectedLine, token, unity]);

  const handleOpenModal = async (id) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resp = await api.get(`/spcqualities/${id}/items`);
    setMotivation(resp.data);
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const onPageChange = async (item) => {
    setPage(item.selected);
  };

  useEffect(() => {
    async function handleGetLines() {
      const resp = await api.get(`/userlines/${idUser}/unity/${unity}/lines`);
      setLines(resp?.data);
      setSelectedLine(resp?.data[0]?.line_id);
    }
    handleGetLines();
  }, [idUser, unity]);

  const handleFilter = async (item) => {
    let line_id =
      item.selectedLine && item.selectedLine.value
        ? item.selectedLine.value
        : params.selectedLine;
    setSelectedLine(line_id);
  };

  const handleClear = async (item) => {
    setFilterParams({ period: filterParams.period });
    setSelectedLine(lines[0]?.line_id);
  };

  const getLine = (id) => {
    var filtered = lines.filter((item) => {
      return item.line_id === parseInt(id);
    });
    return filtered[0]?.line?.description;
  };

  return (
    <>
      <Grid className={classes.qualityRoot}>
        <Grid
          alignItems="flex-end"
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography component="h1" variant="h3">
              Qualidade
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {getLine(selectedLine)}
            </Typography>
          </Grid>
          <Grid item>
            <Filter
              onClear={handleClear}
              onFilter={handleFilter}
              filterParams={filterParams}
              showPeriod={false}
            />
          </Grid>
        </Grid>
        {progress ? (
          <Paper className={classes.paper}>
            <Box container justifyContent="center" alignItems="center">
              <Flex justifyContent="center" alignItems="center">
                <CircularProgress
                  className={classes.circularProgress}
                  color="secondary"
                />
              </Flex>
            </Box>
          </Paper>
        ) : (
          <div className={classes.results}>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Total de {totalRows} registros econtrados. Pagina {page + 1} de{' '}
              {totalPages}
            </Typography>
            <Paper>
              <List dense className={classes.root}>
                {carsData.map((row) => (
                  <React.Fragment key={row.id}>
                    <ListItem
                      button
                      alignItems="flex-start"
                      onClick={() => handleOpenModal(row.id)}
                    >
                      <ListItemAvatar className={classes.progress}>
                        <AnimatedProgressProvider
                          valueStart={0}
                          valueEnd={row.quality}
                          duration={1.4}
                          easingFunction={easeQuadInOut}
                        >
                          {(value) => {
                            const roundedValue = _.round(value, 2);
                            return (
                              <CircularProgressbar
                                value={value}
                                text={`${roundedValue}%`}
                                styles={{
                                  root: {
                                    width: '70px',
                                  },
                                  path: {
                                    stroke: '#5C6BC0',
                                    strokeLinecap: 'butt',
                                    transition: 'none',
                                  },
                                  trail: {
                                    stroke: '#d6d6d6',
                                  },
                                  text: {
                                    fill: '#9FA8DA',
                                    fontSize: '20px',
                                    fontWeight: '700',
                                  },
                                }}
                              />
                            );
                          }}
                        </AnimatedProgressProvider>
                      </ListItemAvatar>
                      <ListItemText
                        className={classes.text}
                        primary={row.product_spec.reference}
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {formatDate(row.date)}
                            </Typography>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => handleOpenModal(row.id)}
                          edge="end"
                          aria-label="comments"
                        >
                          <RemovedRedEye color="secondary" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider className={classes.divider} />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </div>
        )}
        <div className={classes.paginate}>
          <Paginate
            pageCount={totalPages}
            onPageChange={onPageChange}
            pageRangeDisplayed={1}
          />
        </div>
      </Grid>

      <Modal
        title="Vizualizar"
        subheader=""
        open={openNew}
        handleClose={handleCloseNew.bind(this)}
        component={
          <>
            <List component="nav" aria-label="main mailbox folders">
              {motivation.map((row) => (
                <ListItem key={row.id} button>
                  <StyledListItemText primary={row.defect.description} />
                  <ListItemSecondaryAction>
                    <div className={classes.conversion}>
                      <Label color={colors.orange[400]}>
                        <Typography variant="h6">
                          Qtd C: {row.quantity}
                        </Typography>
                      </Label>
                    </div>
                    <div>
                      <Label
                        color={colors.orange[400]}
                        style={{ marginTop: 2 }}
                      >
                        <Typography variant="h6">
                          Qtd Quebra: {row.broken}
                        </Typography>
                      </Label>
                    </div>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Divider />
          </>
        }
        actions={<Button size="small">Learn More</Button>}
      />
    </>
  );
};

export default QualityItems;
