import { colors } from '@material-ui/core';

const buildGradient = (start, end) =>
  `linear-gradient(180deg, ${start} 0%, ${end} 100%)`;

const grey = buildGradient(colors.grey[500], colors.grey[900]);
const blue = buildGradient(colors.blue[500], colors.blue[900]);
const lightBlue = buildGradient(colors.lightBlue[500], colors.lightBlue[900]);
const indigo = buildGradient(colors.indigo[500], colors.indigo[900]);
const green = buildGradient(colors.green[500], colors.green[900]);
const orange = buildGradient(colors.orange[500], colors.orange[900]);
const red = buildGradient(colors.red[500], colors.red[900]);

export default {
  grey,
  blue,
  lightBlue,
  indigo,
  green,
  orange,
  red,
};
