import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  faChartPie,
  faChartBar,
  faIndustry,
  faChartLine,
  faBirthdayCake,
} from '@fortawesome/free-solid-svg-icons';

import Header from '../../components/Header';
import CardRoute from '../../components/CardRoute';
import CardRouteProduction from '../../components/CardRouteProduction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  top: {
    flexGrow: 1,
    backgroundColor: 'text',
    paddingBottom: '15px',
  },
  title: {
    paddingLeft: '40px',
    paddingTop: '25px',
    flexGrow: 1,
    backgroundColor: '#f9f9fc',
  },
  container: {
    marginTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const username = localStorage.getItem('@icep-app/username');
  const { hasPermission } = useAuth();

  return (
    <div className={classes.root}>
      <Header
        title="Dashboard"
        subtitle={'Bem vindo, ' + username}
        description="Veja o que está acontecendo."
      />
      <Grid className={classes.container} container spacing={3}>
        {hasPermission('PRODUCTION_STOP_MOTIVES') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              icon={<FontAwesomeIcon icon={faChartPie} />}
              path="/production-stop-motives"
              color="orange"
              title="Ranking de Parada"
              variant="rounded"
              description="Ranking dos 10 mais motivos de parada do mês."
            />
          </Grid>
        )}
        {hasPermission('DEFECTS') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              icon={<FontAwesomeIcon icon={faChartPie} />}
              path="/defects"
              color="red"
              title="Ranking de Defeito"
              variant="rounded"
              description="Ranking dos 10 mais defeitos do mês."
            />
          </Grid>
        )}
        {hasPermission('VISUAL_ANALYSIS') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              icon={<FontAwesomeIcon icon={faChartPie} />}
              path="/visual-analysis"
              color="indigo"
              title="Controle de Qualidade"
              variant="rounded"
              description="Ranking dos 10 mais defeitos barrado."
            />
          </Grid>
        )}
        {hasPermission('PRODUCTION_DISCARD') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRouteProduction
              icon={<FontAwesomeIcon icon={faChartBar} />}
              path="/producaodescarte"
              color="blue"
              title="Produção e Descarte"
              variant="rounded"
              description="Indicadores de produção e descarte (TV)."
            />
          </Grid>
        )}
        {hasPermission('EFFICIENCY_AND_QUALITY') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRouteProduction
              icon={<FontAwesomeIcon icon={faChartBar} />}
              path="/eficienciaequalidade"
              color="green"
              title="Eficiência e Qualidade (TV)"
              variant="rounded"
              description="Indicadores de eficiência e qualidade (TV)."
            />
          </Grid>
        )}
        {hasPermission('EFFICIENCY_AND_QUALITY') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              color="red"
              icon={<FontAwesomeIcon icon={faChartLine} />}
              path="/new-reports"
              title="Produção"
              variant="rounded"
              description="Indicadores de qualidade e eficiência."
            />
          </Grid>
        )}
        {hasPermission('PRODUCTION_DATA') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              icon={<FontAwesomeIcon icon={faIndustry} />}
              path="/production-data"
              color="blue"
              title="Produção"
              variant="rounded"
              description="Indicadores de produção, qualidade e eficiência."
            />
          </Grid>
        )}
        {hasPermission('PRODUCTION_PERFORMANCE') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              icon={<FontAwesomeIcon icon={faChartLine} />}
              path="/production-performance"
              color="blue"
              title="Produção"
              variant="rounded"
              description="Disponibilidade, performance, qualidade e OEE."
            />
          </Grid>
        )}
        {hasPermission('BIRTHDAYS') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              icon={<FontAwesomeIcon icon={faBirthdayCake} />}
              path="/birthdays"
              title="Aniversariantes"
              variant="rounded"
              description="Aniversariantes do mês e do dia corrente."
            />
          </Grid>
        )}
        
      </Grid>
    </div>
  );
};

export default Dashboard;
