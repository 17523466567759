import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import {
  Grid,
  Button,
  Drawer,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';
import _ from 'lodash';
import DoneAll from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:320px)': {
      marginLeft: '90px',
      marginTop: '15px',
      marginBottom: '0px',
    },
  },
  drawer: {
    width: 420,
    maxWidth: '100%',
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  contentSection: {
    padding: theme.spacing(2, 0),
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  tags: {
    marginTop: theme.spacing(1),
  },
  minAmount: {
    marginRight: theme.spacing(3),
  },
  maxAmount: {
    marginLeft: theme.spacing(3),
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Filter = (props) => {
  const {
    onClose,
    onClear,
    onFilter,
    className,
    filterParams,
    showMultipleLines = false,
    showLine = true,
    showCustomPeriod = false,
    showReworkedFlag = false,
    showPeriod = true,
    ...rest
  } = props;
  const idUser = localStorage.getItem('@icep-app/iduser');

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);
  const [lines, setLines] = useState([]);
  const [formats, setFormats] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [state, setState] = useState({
    rework_flag: filterParams.rework_flag,
    period: filterParams.period,
    selectedLine: filterParams.selectedLine,
    selectedLines: filterParams.selectedLines,
    selectShifts: filterParams.selectShifts,
    selectFormats: filterParams.selectFormats,
    reference: filterParams.reference,
    startDate: filterParams.startDate,
    endDate: filterParams.endDate,
  });

  const [periodAlignment, setPeriodAlignment] = useState('thisMonth');
  const [reworkAlignment, setReworkAlignment] = useState('1,2');

  const {
    selectedLine,
    selectedLines,
    selectShifts,
    selectFormats,
    reference,
    startDate,
    endDate,
  } = state;
  const unity = localStorage.getItem('@icep-app/unity');

  const linesProps = {
    options:
      lines &&
      lines.map((option) => ({
        value: option.line.id,
        title: option.line.description,
      })),
    getOptionLabel: (option) => option.title,
  };
  const formatsProps = {
    options:
      formats &&
      formats.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  const shiftsProps = {
    options:
      shifts &&
      shifts.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  useEffect(() => {
    async function fetchShifts() {
      const resp = await api.get(`/shifts`);
      setShifts(resp.data);
    }
    async function fetchFormats() {
      const resp = await api.get(`/formats`);
      setFormats(resp.data);
    }

    async function handleGetLines() {
      const resp = await api.get(`/userlines/${idUser}/unity/${unity}/lines`);
      setLines(resp.data);
    }
    fetchShifts();
    fetchFormats();
    handleGetLines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItem = (item) => {
    if (_.isArray(item)) {
      let selecteId = [];
      item.forEach((element) => {
        selecteId.push(element.value);
      });

      return selecteId;
    } else {
      return item;
    }
  };

  const handleChange = (event, item) => {
    if (item) {
      let name = event.target.id.split('-', 1)[0];
      setState({ ...state, [name]: item });
      filterParams[name] = handleItem(item);
    } else {
      let name = event.target.id;
      setState({ ...state, [name]: event.target.value });
      filterParams[name] = event.target.value;
    }
  };

  const handlePeriodChange = (event, newAlignment) => {
    if (newAlignment && newAlignment.length) {
      setState({ ...state, period: newAlignment });
      setPeriodAlignment(newAlignment);
      filterParams['period'] = newAlignment;
    }
  };

  const handleReworkedChange = (event, newAlignment) => {
    if (newAlignment && newAlignment.length) {
      setState({ ...state, rework_flag: newAlignment });
      setReworkAlignment(newAlignment);
      filterParams['rework_flag'] = newAlignment;
    }
  };

  const handleClear = () => {
    setState({
      rework_flag: '',
      period: '',
      selectedLine: undefined,
      selectedLines: [],
      selectShifts: '',
      selectFormats: '',
      reference: '',
      startDate: '',
      endDate: '',
    });
    onClear();
    handleFilterClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onFilter &&
      onFilter({
        period: filterParams.period,
        rework_flag: filterParams.rework_flag,
        selectedLine: filterParams.selectedLine,
        selectedLines: _.join(filterParams.selectedLines, ','),

        selectShifts:
          filterParams.selectShifts && filterParams.selectShifts.value,
        selectFormats:
          filterParams.selectFormats && filterParams.selectFormats.value,
        reference: filterParams.reference,
        startDate: state.startDate,
        endDate: state.endDate,
      });
    handleFilterClose();
  };

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  return (
    <Grid {...rest} className={clsx(classes.root, className)}>
      <Button
        size="small"
        color="primary"
        onClick={handleFilterOpen}
        variant="outlined"
        className={classes.filterButton}
      >
        <FilterListIcon className={classes.filterIcon} />
        <Typography className={classes.filterText}>Filtros</Typography>
      </Button>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        onClose={handleFilterClose}
        open={openFilter}
        variant="temporary"
      >
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
        >
          <div className={classes.header}>
            <Button onClick={handleFilterClose} size="small">
              <CloseIcon className={classes.buttonIcon} />
              Fechar
            </Button>
          </div>
          <Divider />
          <div className={classes.content}>
            <div className={classes.contentSectionContent}>
              {!showLine === false && (
                <div className={classes.formGroup}>
                  <Typography component="p" gutterBottom variant="overline">
                    Linha Mestre
                  </Typography>

                  <Autocomplete
                    {...linesProps}
                    id="selectedLine"
                    name="selectedLine"
                    value={selectedLine}
                    onChange={(event, newValue) =>
                      handleChange(event, newValue)
                    }
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione"
                        variant="outlined"
                        fullWidth
                      >
                        {' '}
                        Não encontrado
                      </TextField>
                    )}
                  />
                </div>
              )}

              {showMultipleLines && (
                <div className={classes.formGroup}>
                  <Typography component="p" gutterBottom variant="overline">
                    Linhas
                  </Typography>

                  <Autocomplete
                    {...linesProps}
                    id="selectedLines"
                    multiple
                    name="selectedLines"
                    value={selectedLines}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, newValue) =>
                      handleChange(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione"
                        variant="outlined"
                        fullWidth
                      >
                        {' '}
                        Não encontrado
                      </TextField>
                    )}
                  />
                </div>
              )}
              {showCustomPeriod && (
                <>
                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Formato
                    </Typography>

                    <Autocomplete
                      {...formatsProps}
                      id="selectFormats"
                      name="selectFormats"
                      value={selectFormats}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) =>
                        handleChange(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecione"
                          variant="outlined"
                          fullWidth
                        >
                          {' '}
                          Não encontrado
                        </TextField>
                      )}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Turno
                    </Typography>

                    <Autocomplete
                      {...shiftsProps}
                      id="selectShifts"
                      name="selectShifts"
                      value={selectShifts}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) =>
                        handleChange(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecione"
                          variant="outlined"
                          fullWidth
                        >
                          {' '}
                          Não encontrado
                        </TextField>
                      )}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <Typography component="p" gutterBottom variant="overline">
                      Referência
                    </Typography>
                    <TextField
                      id="reference"
                      type="text"
                      name="reference"
                      value={reference}
                      variant="outlined"
                      autoFocus
                      fullWidth
                      placeholder="Referência"
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
            </div>
            {showPeriod && (
              <div className={classes.formGroup}>
                <Typography component="p" gutterBottom variant="overline">
                  Período
                </Typography>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <ToggleButtonGroup
                      size="medium"
                      value={periodAlignment}
                      exclusive
                      onChange={handlePeriodChange}
                    >
                      <ToggleButton key={1} value="yesterday">
                        Ontem
                      </ToggleButton>
                      <ToggleButton key={2} value="today">
                        Hoje
                      </ToggleButton>
                      <ToggleButton key={3} value="thisMonth">
                        Este mês
                      </ToggleButton>
                      {showCustomPeriod && (
                        <ToggleButton key={4} value="custom">
                          Customizado
                        </ToggleButton>
                      )}
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </div>
            )}

            {filterParams['period'] === 'custom' && (
              <div className={classes.contentSectionContent}>
                <div className={classes.formGroup}>
                  <Typography component="p" gutterBottom variant="overline">
                    Selecione o Período Desejado
                  </Typography>
                  <TextField
                    id="startDate"
                    type="date"
                    label="Data Inicial"
                    required
                    fullWidth
                    className={classes.textField}
                    defaultValue={startDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setState({ ...state, startDate: e.target.value })
                    }
                  />
                  &nbsp; &nbsp; &nbsp;
                  <TextField
                    id="endDate"
                    type="date"
                    label="Data Final"
                    required
                    fullWidth
                    className={classes.textField}
                    defaultValue={endDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setState({ ...state, endDate: e.target.value })
                    }
                  />
                </div>
              </div>
            )}

            {showReworkedFlag && (
              <div className={classes.formGroup}>
                <Typography component="p" gutterBottom variant="overline">
                  Material retrabalhado ou pendente
                </Typography>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <ToggleButtonGroup
                      size="medium"
                      value={reworkAlignment}
                      exclusive
                      onChange={handleReworkedChange}
                    >
                      <ToggleButton key={1} value="1,2">
                        Ambos
                      </ToggleButton>
                      <ToggleButton key={2} value="1">
                        Pendente
                      </ToggleButton>
                      <ToggleButton key={3} value="2">
                        Retrabalhado
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
          <div className={classes.actions}>
            <Button fullWidth onClick={handleClear} variant="contained">
              <DeleteIcon className={classes.buttonIcon} />
              Limpar
            </Button>
            <Button color="primary" fullWidth type="submit" variant="contained">
              <DoneAll className={classes.buttonIcon} />
              Aplicar Filtro
            </Button>
          </div>
        </form>
      </Drawer>
    </Grid>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
};

export default Filter;
