import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  qualityRoot: {
    padding: theme.spacing(3),
  },
  filterButton: {
    marginBottom: '12px',
  },
  fab: {
    right: theme.spacing(4),
    position: 'fixed',
    bottom: theme.spacing(4),
  },
  progress: {
    width: '70px',
  },
  circularProgress: {
    margin: '20px',
  },
  textDense: {
    fontSize: '30px',
    marginLeft: '15px',
    padingLeft: '15px',
    marginTop: '-8px',
  },
  text: {
    fontSize: '30px',
    marginLeft: '15px',
    padingLeft: '15px',
    marginTop: '5px',
  },
  listRoot: {
    width: '100%',
    paddingRight: '8px',
    overflowY: 'scroll',
    height: '470px',
    '@media (max-width:780px)': {
      height: '550px',
    },
    '@media (max-width:360px)': {
      height: '470px',
    },
    '@media (max-width:320px)': {
      height: '390px',
    },
  },
  divider: {
    marginLeft: '105px',
  },
  results: {
    marginTop: theme.spacing(3),
  },
  arrow: {},
  repairs: {
    fontWeight: '900',
    paddingRight: '5px',
  },
  labelList: {
    fontWeight: '500',
    paddingRight: '5px',
  },
  labelListFormat: {
    paddingRight: '15px',
  },
  dividerSilo: {
    '&:last-child': {
      display: 'none',
    },
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
  projectCard: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
  },

  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap',
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  header: {
    maxWidth: '50%',
    width: 80,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      flexBasis: '50%',
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  stats: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
      paddingTop: '0px',
      paddingBottom: '4px',
    },
  },
  actions: {
    justifyContent: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
    },
  },
  eyeRounded: {
    marginRight: '5px',
    fontSize: '18px',
  },
}));
