import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import io from 'socket.io-client';

import { Content } from '../../styles/components';

import api from '../../services/api';
import OEE from '../SlideShowDashboard/OEE';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Filter from '../../components/Filter';
import OEEGroupedLines from './OEEGroupedLines';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  top: {
    flexGrow: 1,
    backgroundColor: 'text',
    paddingBottom: '15px',
  },
  title: {
    paddingLeft: '40px',
    paddingTop: '25px',
    flexGrow: 1,
    backgroundColor: '#f9f9fc',
  },
  container: {
    marginTop: theme.spacing(3),
  },
  barChartIcon: {
    marginRight: theme.spacing(1),
  },
  chart: {
    margin: '15px !important',
  },
  circularProgress: {
    margin: '20px',
  },
}));

const ProductionPerformance = ({ ...props }) => {
  const classes = useStyles();
  const [lines, setLines] = useState([]);
  const [productions, setProductions] = useState([]);
  const [selectedLine, setSelectedLine] = useState();
  const [filterParams, setFilterParams] = useState({});
  const [showGroupedLines, setShowGroupedLines] = useState(false);

  const unity = localStorage.getItem('@icep-app/unity');
  const idUser = localStorage.getItem('@icep-app/iduser');

  useEffect(() => {
    async function handleGetLines() {
      const resp = await api.get(`/userlines/${idUser}/unity/${unity}/lines`);
      if (!!resp?.data && resp?.data?.length > 0) {
        setLines(resp.data);
        setSelectedLine(resp.data[0]?.line_id);
      }
    }
    handleGetLines();
  }, [idUser, unity]);

  const handleGetData = useCallback(async () => {
    if (!!unity && !!selectedLine) {
      const productions = await api.get(
        `/productions/unity/${unity}/line/${
          showGroupedLines ? 'all' : selectedLine
        }`,
      );

      setProductions(productions.data);
    }
  }, [selectedLine, showGroupedLines, unity]);

  const registerToSocket = useCallback(() => {
    const socket = io('https://api.iforth.net');

    socket.on('DISCARD_UPDATE', (newData) => {
      if (unity && selectedLine) handleGetData();
    });

    socket.on('PRODUCTION_UPDATE', (newData) => {
      if (unity && selectedLine) handleGetData();
    });
  }, [handleGetData, selectedLine, unity]);

  useEffect(() => {
    registerToSocket();
  }, [registerToSocket]);

  useEffect(() => {
    if (unity && selectedLine) handleGetData();
  }, [handleGetData, selectedLine, unity]);

  const handleFilter = async (item) => {
    let line_id =
      item.selectedLine && item.selectedLine.value
        ? item.selectedLine.value
        : selectedLine;
    setSelectedLine(line_id);
  };

  const handleClear = async (item) => {
    setFilterParams({ period: filterParams.period });
    setSelectedLine(lines[0]?.line_id);
  };

  const getLine = (id) => {
    var filtered = lines.filter((item) => {
      return item?.line_id === parseInt(id);
    });
    return filtered[0]?.line?.description;
  };

  return (
    <div className={classes.root}>
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            Apresenta em tempo real, indicadores de produção, qualidade e
            eficiência.
          </Typography>
          <Typography component="h1" variant="h3">
            Produção
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {showGroupedLines ? 'TODAS AS LINHAS' : getLine(selectedLine)}
          </Typography>
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={showGroupedLines}
                onChange={() => setShowGroupedLines(!showGroupedLines)}
              />
            }
            label="Exibir linhas agrupadas"
          />
          <Filter
            onClear={handleClear}
            onFilter={handleFilter}
            className={classes.filterButton}
            filterParams={filterParams}
            showPeriod={false}
          />
        </Grid>
      </Grid>

      <Content>
        {showGroupedLines ? (
          <OEEGroupedLines data={productions} />
        ) : (
          <OEE
            productions={productions}
            cardPadding="30px 0px"
            cardFontSize="52px"
          />
        )}
      </Content>
    </div>
  );
};

export default ProductionPerformance;
