import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Box, Flex } from 'rebass';

import api from '../../../services/api';
import Filter from '../../../components/Filter';
import BarAndLineChart from '../../../components/BarAndLineChart';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  top: {
    flexGrow: 1,
    backgroundColor: 'text',
    paddingBottom: '15px',
  },
  title: {
    paddingLeft: '40px',
    paddingTop: '25px',
    flexGrow: 1,
    backgroundColor: '#f9f9fc',
  },
  container: {
    marginTop: theme.spacing(3),
  },
  barChartIcon: {
    marginRight: theme.spacing(1),
  },
  circularProgress: {
    margin: '20px',
  },
}));

const months = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

const cleanAndFormatDate = (date) => {
  const fullDate = date.split('T')[0];
  const formattedDate = fullDate.split('-')[2] + '/' + fullDate.split('-')[1];
  return formattedDate;
};

const getYearFromDate = (date) => {
  const fullDate = date.split('T')[0];
  return fullDate.split('-')[0].substring(2);
};

const customValueFormatter = (value) => {
  return new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 4,
  }).format(value);
};

const NewReports = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [lines, setLines] = useState([]);
  const [chartType, setChartType] = useState('quality');
  const [periodType, setPeriodType] = useState('MONTH');
  const [selectedLine, setSelectedLine] = useState();
  const unity = localStorage.getItem('@icep-app/unity');
  const unity_id = localStorage.getItem('@icep-app/unity');
  const [progress, setProgress] = useState(true);
  const idUser = localStorage.getItem('@icep-app/iduser');

  const prepareChartData = useCallback(
    (apiData = []) => {
      const POA_VALUE = 95;

      let dates = [];
      let values = [];
      let poa_values = [];

      (apiData?.groupedData || []).reverse().forEach((item) => {
        const date =
          periodType === 'MONTH'
            ? item.groupDate
            : months[Number(item.groupDate) - 1];
        const value =
          chartType === 'performance'
            ? customValueFormatter(
                (item.performed_production / item.standard_production) * 100,
              )
            : customValueFormatter((item.sumQuantity / item.sumSample) * 100);

        const formattedValue = item.isGroupedData
          ? {
              value: value,
              itemStyle: {
                color: '#677ec7',
              },
            }
          : value;

        dates.push(date);
        values.push(formattedValue);
        poa_values.push(POA_VALUE);
      });

      (apiData?.detailedData || []).reverse().forEach((item) => {
        dates.push(
          periodType === 'MONTH'
            ? months[Number(item.groupDate) - 1] +
                '/' +
                getYearFromDate(item.date)
            : cleanAndFormatDate(item.date),
        );
        values.push(
          chartType === 'performance'
            ? customValueFormatter(
                (item.performed_production / item.standard_production) * 100,
              )
            : customValueFormatter((item.sumQuantity / item.sumSample) * 100),
        );
        poa_values.push(POA_VALUE);
      });

      return { dates, values, poa_values };
    },
    [chartType, periodType],
  );

  const fetchData = useCallback(async () => {
    const resp = await api.get(
      `/efficiencyandqualityperforms/${
        chartType || 'quality'
      }/unity/${unity_id}?periodType=${periodType}${
        selectedLine ? `&line_id=${selectedLine}` : ''
      }`,
    );
    setData(prepareChartData(resp.data));
    setProgress(false);
  }, [chartType, periodType, prepareChartData, selectedLine, unity_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    async function handleGetLines() {
      const resp = await api.get(`/userlines/${idUser}/unity/${unity}/lines`);
      setLines(resp.data);
    }
    handleGetLines();
  }, [idUser, unity]);

  const handleFilter = async (item) => {
    let line_id =
      item.selectedLine && item.selectedLine.value
        ? item.selectedLine.value
        : selectedLine;
    setSelectedLine(line_id);
  };

  const handleClear = async (item) => {
    setSelectedLine();
  };

  const getLine = (id) => {
    var filtered = lines.filter((item) => {
      return item.line_id === parseInt(id);
    });
    return filtered[0]?.line?.description;
  };

  const handleChartType = (event, newAlignment) => {
    if (newAlignment && newAlignment.length) {
      setChartType(newAlignment);
    }
  };

  const handlePeriodType = (event, newAlignment) => {
    if (newAlignment && newAlignment.length) {
      setPeriodType(newAlignment);
    }
  };

  return (
    <Grid className={classes.root}>
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            Qualidade e Eficiência
          </Typography>
          <div style={{ display: 'flex' }}>
            <ToggleButtonGroup
              size="medium"
              value={chartType}
              exclusive
              onChange={handleChartType}
            >
              <ToggleButton key={1} value="quality">
                Qualidade
              </ToggleButton>
              <ToggleButton key={2} value="performance">
                Eficiência
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              size="medium"
              value={periodType}
              exclusive
              onChange={handlePeriodType}
              style={{ marginLeft: 10 }}
            >
              <ToggleButton key={1} value="MONTH">
                Mês
              </ToggleButton>
              <ToggleButton key={2} value="DAY">
                Dia
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <br />
          <Typography variant="body1" color="textPrimary">
            {getLine(selectedLine)}
          </Typography>
        </Grid>
        <Grid item>
          <Filter
            onClear={handleClear}
            onFilter={handleFilter}
            className={classes.filterButton}
            filterParams={{}}
            showPeriod={false}
          />
        </Grid>
      </Grid>
      {progress ? (
        <Paper className={classes.container}>
          <Box container justifyContent="center" alignItems="center">
            <Flex justifyContent="center" alignItems="center">
              <CircularProgress
                className={classes.circularProgress}
                color="secondary"
              />
            </Flex>
          </Box>
        </Paper>
      ) : (
        <>
          <Grid className={classes.container} justify="space-between" container>
            <Grid item lg={12} xl={12} xs={12}>
              <Card>
                <BarAndLineChart
                  data={data}
                  chartType={chartType}
                  periodType={periodType}
                />
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default NewReports;
