import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isAuthenticated } from '../services/auth';

const RouteWithLayout = ({ hideLayout = false, ...props }) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        isAuthenticated() ? (
          hideLayout ? (
            <Component {...matchProps} />
          ) : (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          )
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: matchProps.location } }}
          />
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
