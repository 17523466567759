import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Header from '../../components/Header';
import CardRoute from '../../components/CardRoute';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  top: {
    flexGrow: 1,
    backgroundColor: 'text',
    paddingBottom: '15px',
  },
  title: {
    paddingLeft: '40px',
    paddingTop: '25px',
    flexGrow: 1,
    backgroundColor: '#f9f9fc',
  },
  container: {
    marginTop: theme.spacing(3),
  },
}));

const Production = () => {
  const classes = useStyles();
  const { hasPermission } = useAuth();

  return (
    <div className={classes.root}>
      <Header title="Produção" description="Veja o que está acontecendo." />
      <Grid className={classes.container} container spacing={3}>
        {hasPermission('PRODUCTION_STOP') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              path="/production/production-stop"
              icon={<FontAwesomeIcon icon={faListUl} />}
              title="Produção/Parada"
              description="Eficiência da linha"
              color="indigo"
            />
          </Grid>
        )}
        {hasPermission('EARLY_CARS_STOCKS') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              path="/production/early-cars-stocks"
              icon={<FontAwesomeIcon icon={faListUl} />}
              color="indigo"
              title="Carro Box"
              description="Estoque de carro box"
            />
          </Grid>
        )}
        {hasPermission('SILO_MASS_STOCKS') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              path="/production/silo-mass-stocks"
              icon={<FontAwesomeIcon icon={faListUl} />}
              color="indigo"
              title="SILOS DE MASSA"
              description="Estoque de Massa dos Silos"
            />
          </Grid>
        )}
        {hasPermission('SILO_MASS_STOCKS_BY_FORMULE') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              path="/production/silo-mass-stocks-by-formule"
              icon={<FontAwesomeIcon icon={faListUl} />}
              color="indigo"
              title="SILOS DE MASSA"
              description="Estoque de Massa dos Silos - Por Fórmula"
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Production;
