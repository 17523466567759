import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import io from 'socket.io-client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';

import {
  Content,
  Row,
  Text,
  Span,
  Select,
  Button,
  Logo,
} from '../../styles/components';

import ProductionDiscard from './ProductionDiscard';
import OEE from './OEE';

import api from '../../services/api';
import logo from '../../assets/images/icep.png';
import Birthdays from './BirthDays';

const SlideShowDashboard = ({ ...props }) => {
  const [showingItem, setShowingItem] = useState(0);
  const [birthdays, setBirthdays] = useState([]);
  const [productions, setProductions] = useState([]);
  const [unities, setUnities] = useState([]);
  const [lines, setLines] = useState([]);
  const [unity, setUnity] = useState(0);
  const [line, setLine] = useState(0);
  const [showFilter, setShowFilter] = useState(true);

  useEffect(() => {
    handleGetUnities();
    handleGetBirthdays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowingItem((showingItem) => {
        return showingItem === 2 ? 0 : showingItem + 1;
      });
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleGetUnities = async () => {
    const unities = await api.get(`/unities`);

    if (!unities.status || unities.status !== 200 || !unities.data[0].id) {
    } else {
      setUnities(unities.data);
      setUnity(unities.data[0].id);
    }

    registerToSocket();
  };

  const handleGetBirthdays = async () => {
    const birthDays = await api.get(`/users/month/birthdays`);

    if (
      !birthDays.status ||
      birthDays.status !== 200 ||
      !birthDays.data[0].id
    ) {
      return;
    } else {
      setBirthdays(birthDays.data);
    }
  };

  const handleGetLines = async (e) => {
    const lines = await api.get(`/lines/unity/${unity}`);

    if (!lines.status || lines.status !== 200) {
      return;
    } else {
      setLines(lines.data);
      setLine(lines.data[0].id);
    }
  };

  useEffect(() => {
    if (unities && unity > 0) handleGetLines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unities, unity]);

  useEffect(() => {
    if (lines && line > 0) handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line, lines]);

  const registerToSocket = () => {
    const socket = io('https://api.iforth.net');

    socket.on('DISCARD_UPDATE', (newData) => {
      if (lines && line > 0) handleGetData();
    });

    socket.on('PRODUCTION_UPDATE', (newData) => {
      if (lines && line > 0) handleGetData();
    });
  };

  const handleGetData = async () => {
    /*const discards = await api.get(
      `/discards/unity/${unity}/line/${line}`,
    );*/
    const productions = await api.get(
      `/productions/unity/${unity}/line/${line}`,
    );

    setProductions(productions.data);
  };

  return (
    <Content>
      <Row margin="10px 0px 0px 10px">
        <Span display="flex" flexDirection="column" width="33%">
          <Text fontSize="24px" fontWeight={900}>
            SlideShow Dashboard
          </Text>
        </Span>

        <Span
          display="flex"
          flexDirection="column"
          width="34%"
          alignItems="center"
        >
          {showFilter && (
            <>
              <Select
                width="120px"
                margin="0px 0px 5px 0px"
                padding="3px"
                heigth="20px"
                required={false}
                onChange={(e) => setUnity(e.target.value)}
                type="text"
                name="unity"
                placeholder=""
                value={unity}
              >
                {unities &&
                  unities.map(
                    (item) =>
                      item.id !== 99 && (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ),
                  )}
              </Select>
              <Select
                width="120px"
                margin="0px 0px 5px 0px"
                padding="3px"
                required={false}
                onChange={(e) => setLine(e.target.value)}
                type="text"
                name="line"
                placeholder=""
                value={line}
              >
                {lines &&
                  lines.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
              </Select>
            </>
          )}
          <Button
            kind="outlined"
            padding="8px 5px"
            width="120px"
            margin="0px 0px 5px 0px"
            onClick={() => setShowFilter(!showFilter)}
          >
            <FontAwesomeIcon
              icon={showFilter ? faSearchMinus : faSearchPlus}
              color="#0069ff"
            />
          </Button>
        </Span>
        <Span
          display="flex"
          flexDirection="column"
          width="33%"
          alignItems="flex-end"
        >
          <Text padding="0px 20px 0px 0px">
            <Logo src={logo} alt="logo" />
          </Text>
        </Span>
      </Row>
      <Row>
        <Text fontSize="16px" fontWeight={400} color="#ccc" padding="0px 10px">
          Apresenta em tempo real, de forma alternada, indicadores relacionados
          a produção, disponibilidade, performance, qualidade de mercadorias e
          aniversariantes do mês.
        </Text>
      </Row>

      {showingItem === 0 && <ProductionDiscard productions={productions} />}
      {showingItem === 1 && <OEE productions={productions} />}
      {showingItem === 2 && <Birthdays birthdays={birthdays} />}
    </Content>
  );
};

export default SlideShowDashboard;
