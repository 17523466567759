import React, { useState } from "react";
import { Flex } from "rebass";
import {
  Box,
  Grid,
  Button,
  Divider,
  TextField,
  Typography
} from "@material-ui/core";
import { ArrowForward, ArrowBackSharp } from "@material-ui/icons";
import { useStyles } from "./styles";

const Anotations = props => {
  const { nextStep, prevStep, showroom } = props;
  const [hasMix, setHasMix] = useState(true);
  const classes = useStyles();

  const [state, setState] = useState({
    manager_obs: showroom.manager_obs,
    mix: showroom.mix,
    product_sugestion: showroom.product_sugestion,
    showroom_obs: showroom.showroom_obs
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.value });
    showroom[name] = event.target.value;
  };

  const { manager_obs, mix, product_sugestion, showroom_obs } = state;

  const verifyFields = hande => {
    if (!mix) {
      setHasMix(false);
    } else {
      setHasMix(true);
    }
  };

  const continues = e => {
    if (mix && mix.trim() !== "") {
      nextStep();
    } else {
      verifyFields();
    }
  };

  const back = e => {
    prevStep();
  };

  return (
    <>
      <Box>
        <Typography variant="h5" component="h3">
          <Flex justifyContent="center">Observações</Flex>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="mix"
              type="text"
              value={mix}
              label="Mix de Produtos da Loja"
              onChange={handleChange("mix")}
              margin="normal"
              fullWidth
              className={classes.textField}
              error={!hasMix}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="product_sugestion"
              type="text"
              label="Sugestão de Produtos para Exposição nesse Showroom"
              margin="normal"
              fullWidth
              value={product_sugestion}
              onChange={handleChange("product_sugestion")}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="manager_obs"
              type="text"
              label="Observações Gerente Regional / Nacional"
              margin="normal"
              value={manager_obs}
              onChange={handleChange("manager_obs")}
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="showroom_obs"
              type="text"
              label="Observações Gerais do Showroom"
              margin="normal"
              value={showroom_obs}
              onChange={handleChange("showroom_obs")}
              fullWidth
              className={classes.textField}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <div className={classes.bottom}>
          <Divider />
          <Grid
            container
            direction="row"
            className={classes.buttons}
            alignItems="flex-end"
            justify="space-between"
          >
            <Button
              variant="outlined"
              onClick={back}
              startIcon={<ArrowBackSharp />}
              className={classes.button}
            >
              Voltar
            </Button>
            <Button
              variant="outlined"
              onClick={continues}
              endIcon={<ArrowForward />}
              className={classes.button}
            >
              Avançar
            </Button>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default Anotations;
