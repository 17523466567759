import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';

import api from '../../../../services/api';
import Table from '../../../../components/Table';
import Modal from '../../../../components/Modal';
import ShowRoomNewModal from './components/ShowRoomNewModal';
import NotificationContent from '../../../../components/Notification';
import { useStyles } from './styles';

import {
  Fab,
  Grid,
  Button,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import AttatchmentsModal from './components/AttatchmentsModal';

const Overview = () => {
  const classes = useStyles();
  const headCells = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
    { id: 'client', numeric: false, disablePadding: false, label: 'Cliente' },
    {
      id: 'city',
      numeric: false,
      disablePadding: false,
      label: 'Cidade',
    },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Ações' },
  ];

  const fab = {
    color: 'primary',
    className: classes.fab,
    icon: <AddIcon />,
    label: 'Add',
  };

  const token = localStorage.getItem('@icep-app/token');
  const [openNew, setOpenNew] = useState(false);
  const [openAttatchment, setOpenAttatchment] = useState(false);
  const [openedData, setOpenedData] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [showrooms, setShowrooms] = useState([]);
  const [progress, setProgress] = useState(true);

  const getShowroom = async data => {
    const row = [];

    const handleAction = data => {
      setOpenedData(data);
      setOpenAttatchment(true);
    };

    data.forEach(item => {
      const obj = {};
      obj.id = item.id;
      obj.client = item.customer.description;
      obj.status = item.flag;
      obj.city = item.customer.city.description;
      obj.handleAction = handleAction;
      row.push(obj);
    });
    setShowrooms(row);
  };

  useEffect(() => {
    async function fetch() {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(`/showrooms`);
      setProgress(false);
      getShowroom(resp.data);
    }
    fetch();
  }, [token]);

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNotification(false);
  };

  const handleOpenNotification = () => {
    setOpenNotification(true);
    window.location.reload();
  };

  const handleOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  return (
    <>
      {progress ? (
        <Grid
          className={classes.progress}
          container
          justify="center"
          alignItems="center"
        >
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Table rows={showrooms} headCells={headCells} />
      )}
      <Tooltip title="Novo Showroom" aria-label="add">
        <Fab
          aria-label={fab.label}
          className={fab.className}
          onClick={handleOpenNew}
          color={fab.color}
        >
          {fab.icon}
        </Fab>
      </Tooltip>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openNotification}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <NotificationContent
          onClose={handleCloseNotification}
          variant="success"
          message="Showroom cadastrado com sucesso!"
        />
      </Snackbar>

      <Modal
        title="Showroom"
        subheader="Cadastrar"
        open={openNew}
        handleClose={handleCloseNew.bind(this)}
        component={
          <ShowRoomNewModal
            handleOpenNotification={handleOpenNotification}
            handleClose={handleCloseNew.bind(this)}
          />
        }
        actions={<Button size="small">Learn More</Button>}
      />

      <Modal
        title="Anexos"
        subheader={`Anexos do Showroom ${openedData &&
          openedData.id + ' ' + openedData.client}`}
        open={openAttatchment}
        handleClose={() => setOpenAttatchment(false)}
        component={<AttatchmentsModal data={openedData} />}
      />
    </>
  );
};

export default Overview;
