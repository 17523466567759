import React, { useState, useEffect } from 'react';
import { formatDate } from '../../../utils/format';
import { useStyles } from '../../styles';
import { List, Divider, ListItem, Typography } from '@material-ui/core';
import { Box, Flex } from 'rebass';
import 'react-circular-progressbar/dist/styles.css';
import api from '../../../services/api';

const DetailModal = ({ id, endpoint, type, typeItem, label }) => {
  const classes = useStyles();
  const token = localStorage.getItem('@icep-app/token');
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    async function fetch() {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(`${endpoint}/${id}/repairs`);
      setDetail(resp.data);
    }
    fetch();
  }, [endpoint, id, token]);

  return (
    <Box>
      {detail ? (
        <List
          className={classes.listRoot}
          component="nav"
          aria-label="main mailbox folders"
        >
          {detail.map((row) => (
            <React.Fragment key={row.id}>
              <ListItem key={row.id} button>
                <Box key={row.id}>
                  <Flex>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      Id:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {row.id}
                    </Typography>
                  </Flex>
                  <Flex>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      Data Início:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {formatDate(
                        row.initial_date,
                        "d 'de' MMM 'de' yy, kk:mm",
                      )}
                    </Typography>
                  </Flex>

                  <Flex className={classes.repairs}>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      Data Fim:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {formatDate(row.final_date, "d 'de' MMM 'de' yy, kk:mm")}
                    </Typography>
                  </Flex>

                  <Flex className={classes.repairs}>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      Fornecedor:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {row.supplier.name}
                    </Typography>
                  </Flex>
                  <Flex className={classes.repairs}>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      {label}:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {row[typeItem][type].format.cavities}
                    </Typography>
                  </Flex>
                  <Flex className={classes.repairs}>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      Formato:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {row[typeItem][type].format.description}
                    </Typography>
                  </Flex>
                  <Flex className={classes.repairs}>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      Relevo:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {row[typeItem][type].description}
                    </Typography>
                  </Flex>
                  <Flex className={classes.repairs}>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      Ciclo Limite:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {row[typeItem][type].limit_cycle}
                    </Typography>
                  </Flex>
                  <Flex className={classes.repairs}>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      Ciclos Alcançados:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {row[typeItem][type].current_cycle}
                    </Typography>
                  </Flex>
                  <Flex className={classes.repairs}>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      NF de envio:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {row.send_document}
                    </Typography>
                  </Flex>
                  <Flex className={classes.repairs}>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      NF de Retorno:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {row.return_document}
                    </Typography>
                  </Flex>
                  <Flex className={classes.repairs}>
                    <Typography
                      className={classes.repairs}
                      variant="body2"
                      color="textPrimary"
                    >
                      Observações:
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {row.obs}
                    </Typography>
                  </Flex>
                </Box>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Typography align="center" variant="body2" color="textPrimary">
          Sem dados
        </Typography>
      )}
    </Box>
  );
};
export default DetailModal;
