import {
  format as formatUsingFns,
  subDays,
  isSameDay,
  isSameYear,
  startOfDay,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';

export function formatDate(date, isChartDate, showDateOnly) {
  const timeZone = 'BRT';
  const zonedDate = utcToZonedTime(date, timeZone);
  const todayDate = startOfDay(new Date());
  const yesterdayDate = subDays(todayDate, 1);

  let format = "d 'de' MMM, kk:mm";

  if (isSameDay(zonedDate, todayDate)) {
    format = "d 'de' MMM 'de' yy, kk:mm";
  }

  if (isSameDay(zonedDate, yesterdayDate)) {
    format = "d 'de' MMM 'de' yy, kk:mm";
  }

  if (!isSameYear) {
    format = "d 'de' MMM 'de' yy, kk:mm";
  }

  if (isChartDate) {
    format = 'iiii, dd/MM/yyyy';
  }

  if (showDateOnly) {
    format = 'dd/MM/yyyy';
  }

  const formattedDate = formatUsingFns(
    zonedDate,
    format,
    {
      locale: ptBR,
    },
    true,
  );

  if (isChartDate) {
    return formattedDate.replace(/^\w/, c => c.toUpperCase());
  }

  // it converts "15 de fev" to "15 de Fev".
  const capitalizedDate = formattedDate.replace(/\b\w/g, l => l.toUpperCase());
  return capitalizedDate.replace(/De /g, 'de ');
}

export function formatPercent(value) {
  return value + '%';
}
