import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import gradients from '../utils/gradients';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  details: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  avatar: {
    width: 48,
    height: 48,
    backgroundImage: props => {
      return gradients[props.color];
    },
  },
  description: {
    paddingRight: theme.spacing(2),
  },
  router: {
    textDecoration: 'none',
  },
}));

const CardRoute = prop => {
  const {
    ref,
    icon,
    path,
    title,
    color,
    variant,
    className,
    description,
    ...rest
  } = prop;
  const props = { color };
  const classes = useStyles(props);
  return (
    <RouterLink className={classes.router} to={path}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <Grid
          xs={12}
          item
          container
          className={classes.details}
          direction="row"
        >
          <Grid
            item
            xs={10}
            container
            className={classes.description}
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              container
              className={classes.description}
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Typography component="h3" variant="overline">
                {title}
              </Typography>
            </Grid>

            <Typography component="h4" variant="h6">
              {description}
            </Typography>
          </Grid>
          <Grid
            xs={2}
            item
            justify="center"
            container
            direction="row"
            alignItems="center"
          >
            <Avatar variant={variant} className={`${classes.avatar}`}>
              {icon}
            </Avatar>
          </Grid>
        </Grid>
      </Card>
    </RouterLink>
  );
};

CardRoute.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
};

export default CardRoute;
