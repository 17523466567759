import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { easeQuadInOut } from 'd3-ease';
import { CircularProgressbar } from 'react-circular-progressbar';
import RemoveRedEyeRounded from '@material-ui/icons/RemoveRedEyeRounded';
import _ from 'lodash';
import AnimatedProgressProvider from './AnimatedProgressProvider';

import { Grid, Card, Button, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap',
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  header: {
    maxWidth: '50%',
    width: 80,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      flexBasis: '50%',
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  stats: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
      paddingTop: '0px',
      paddingBottom: '4px',
    },
  },
  actions: {
    justifyContent: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
    },
  },
  eyeRounded: {
    marginRight: '5px',
    fontSize: '18px',
  },
}));

const ProjectCard = (props) => {
  const { data, type, className, openModal, ...rest } = props;
  const classes = useStyles();

  const handleCyclePercent = (row) => {
    let percent = (data.current_cycle / data.limit_cycle) * 100;
    let total = _.round(percent, 0);
    if (total >= 70 && total <= 80) {
      return '#ffeb3b';
    } else if (total > 100) {
      return '#f44336';
    } else if (total >= 80 && total <= 100) {
      return '#ff9800';
    } else if (total < 70) {
      return '#4CAF50';
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <Grid className={classes.header}>
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={(data.current_cycle / data.limit_cycle) * 100}
            duration={1.4}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              const roundedValue = _.round(value, 2);
              handleCyclePercent(data);
              return (
                <CircularProgressbar
                  value={value}
                  text={`${roundedValue}%`}
                  maxValue={100}
                  styles={{
                    root: {
                      width: '70px',
                    },
                    path: {
                      stroke: handleCyclePercent(data),
                      strokeLinecap: 'butt',
                      transition: 'none',
                    },
                    trail: {
                      stroke: '#d6d6d6',
                    },
                    text: {
                      fill: '#9E9E9E',
                      fontSize: '20px',
                      fontWeight: '900',
                    },
                  }}
                />
              );
            }}
          </AnimatedProgressProvider>
        </Grid>
        <Grid className={classes.stats}>
          <Typography variant="body2">Id - Descrição</Typography>
          <Typography variant="h6">
            {data.id + ' - ' + data[type].description}
          </Typography>
        </Grid>
        <Grid className={classes.stats}>
          <Typography variant="body2">Formato</Typography>
          <Typography variant="h6"> {data[type].format.description}</Typography>
        </Grid>
        <Grid className={classes.stats}>
          <Typography variant="body2">Fornecedor</Typography>
          <Typography variant="h6">{data.supplier.name}</Typography>
        </Grid>
        <Grid className={classes.stats}>
          <Typography variant="body2">Ciclos</Typography>
          <Typography variant="h6">
            {data.current_cycle + '/' + data.limit_cycle}
          </Typography>
        </Grid>
        <Grid className={classes.stats}>
          <Typography variant="body2">Restam</Typography>
          <Typography variant="h6">
            {data.limit_cycle - data.current_cycle}
          </Typography>
        </Grid>

        <Grid className={classes.actions}>
          <Button
            onClick={() => openModal(data[type].id)}
            color="primary"
            size="small"
            variant="outlined"
          >
            <RemoveRedEyeRounded
              className={classes.eyeRounded}
              color="primary"
              mr={5}
            />
            Recuperações
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

ProjectCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default ProjectCard;
