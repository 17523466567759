import React, { Component } from 'react';

import {
  Grid,
  Link,
  Select,
  Button,
  MenuItem,
  Container,
  TextField,
  Typography,
  InputLabel,
  IconButton,
  FormControl,
  InputAdornment,
} from '@material-ui/core';

import PermIdentityOutlined from '@material-ui/icons/PermIdentityOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { destroyLocalSession } from '../../services/auth';
import api from '../../services/api';

import { ErrorMessage } from './style.js';

const styles = (theme) => ({
  '@global': {
    body: {
      backgroundColor: '#f4f6f8',
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },

  formControl: {
    marginTop: theme.spacing(2),
  },

  avatar: {
    width: '130px',
    height: '130px',
    backgroundColor: '#f4f6f8',
    margin: theme.spacing(2),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  conected: {
    '@media (max-width:320px)': {
      display: 'none',
    },
  },
  loginTextDivider: {
    margin: '1rem 0 2rem 0',
    marginTop: theme.spacing(5),
    '@media (max-width:780px)': {
      marginTop: theme.spacing(5),
    },
    '@media (max-width:320px)': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
    },
    marginBottom: theme.spacing(1),

    ' &:not(:first-child):not(:last-child)': {
      fontWeight: '400',
      color: '#607D8B',
      fontSize: '1rem',
    },
  },

  loginDivider: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& span': {
      '&:first-child': {
        width: '100%',
        height: '1px',
        flex: '1',
        background: '#C5CAE9',
        display: 'inline-block',
      },

      '&:last-child': {
        width: '100%',
        height: '1px',
        flex: '1',
        background: ' #C5CAE9',
        display: 'inline-block',
      },

      '&:not(:first-child):not(:last-child)': {
        padding: '0 2rem',
      },
    },
  },
});
class auth extends Component {
  state = {
    user: '',
    unity: '',
    line: '',
    idUser: 0,
    lines: [],
    unities: [],
    username: '',
    password: '',
    idProfile: 0,
    errorMessage: '',
    showPassword: false,
    profileItems: [],
  };

  async componentDidMount() {
    const idUser = localStorage.getItem('@icep-app/iduser');
    const idProfile = localStorage.getItem('@icep-app/idprofile');
    const profileItems = localStorage.getItem('@icep-app/profileitems');
    const user = localStorage.getItem('@icep-app/user');
    const username = localStorage.getItem('@icep-app/username');
    const token = localStorage.getItem('@icep-app/token');

    if (idUser && user) {
      this.setState({ idUser, idProfile, user, username, token, profileItems });

      try {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const unities = await api.get(`/userunities/${idUser}/unities`);

        if (
          !unities.status ||
          unities.status !== 200 ||
          !unities.data[0].unity_id
        ) {
          this.setState({
            errorMessage:
              'Ops, não foram localizadas unidades para este usuário.',
          });
          return;
        } else {
          this.setState({ unities: unities.data }, () => {
            this.setState({ unity: this.state.unities[0].unity_id }, () => {
              this.handleGetLines();
            });
          });
        }
      } catch (e) {
        if (e.message === 'Request failed with status code 401') {
          destroyLocalSession();
        }
      }
    }
  }

  handleAnotherAccountClick = () => {
    this.setState({
      user: '',
      username: '',
      password: '',
      token: '',
      unity: '',
      line: '',
      errorMessage: '',
      idUser: 0,
      idProfile: 0,
      unities: [],
      lines: [],
    });

    destroyLocalSession();
  };

  checkAccesCredentials = () => {
    return;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.idUser === 0) {
      this.setState({
        errorMessage: '',
        idUser: 0,
        idProfile: 0,
        username: '',
        token: '',
        unities: [],
        lines: [],
      });

      try {
        const auth = await api.post('/sessions', {
          username: this.state.user,
          password: this.state.password,
        });

        // Verifica credenciais de acesso
        if (!auth.status || auth.status !== 200) {
          this.setState({ errorMessage: 'Credenciais inválidas.' });
          return;
        }

        // Captura unidades e módulos do usuário
        const idUser = parseInt(auth.data.user.id);
        const idProfile = parseInt(auth.data.user.profile_id);
        const username = auth.data.user.name;
        const token = auth.data.token;

        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const unities = await api.get(`/userunities/${idUser}/unities`);
        const profileItems = await api.get(
          `/profileitems/profile/${idProfile}/items/web`,
        );

        let formattedProfileItems = [];
        profileItems.data.map((item) => {
          return formattedProfileItems.push(item?.form?.name);
        });

        this.setState({ idUser, idProfile, username, token }, () => {
          // persiste dados de id e nome do usuário (nunca expiram)
          localStorage.setItem('@icep-app/iduser', this.state.idUser);
          localStorage.setItem('@icep-app/idprofile', this.state.idProfile);
          localStorage.setItem('@icep-app/profileitems', formattedProfileItems);
          localStorage.setItem('@icep-app/username', this.state.username);
          localStorage.setItem('@icep-app/user', this.state.user);
          localStorage.setItem('@icep-app/token', this.state.token);
        });

        if (!unities.status || unities.status !== 200) {
          this.setState({
            errorMessage:
              'Ops, não foi possível carregar as unidades deste usuário.',
          });
          return;
        } else {
          this.setState({ unities: unities.data }, () => {
            this.setState({ unity: this.state.unities[0].unity_id }, () => {
              this.handleGetLines();
            });
          });
        }
      } catch (error) {
        this.setState({ errorMessage: 'Credenciais inválidas.' });
        return;
      }
    } else {
      if (
        this.state.idUser !== 0 &&
        this.state.idProfile !== 0 &&
        this.state.user !== '' &&
        this.state.unity !== '' &&
        this.state.line !== ''
      ) {
        const profileItems = await api.get(
          `/profileitems/profile/${this.state.idProfile}/items/web`,
        );

        let formattedProfileItems = [];
        profileItems.data.map((item) => {
          return formattedProfileItems.push(item?.form?.name);
        });

        localStorage.setItem('@icep-app/unity', this.state.unity);
        localStorage.setItem('@icep-app/iduser', this.state.idUser);
        localStorage.setItem('@icep-app/idprofile', this.state.idProfile);
        localStorage.setItem('@icep-app/profileitems', formattedProfileItems);
        localStorage.setItem('@icep-app/username', this.state.username);
        localStorage.setItem('@icep-app/user', this.state.user);
        localStorage.setItem('@icep-app/unity', this.state.unity);

        this.props.history.push('/');
      } else {
        this.setState({ errorMessage: 'Erro ao gerar sessão de usuário' });
      }
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeUnity = async (e) => {
    this.setState({ [e.target.name]: e.target.value, lines: [] }, () =>
      this.handleGetLines(),
    );
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleGetLines = async (e) => {
    const lines = await api.get(
      `/userlines/${this.state.idUser}/unity/${this.state.unity}/lines`,
    );

    if (!lines.status || lines.status !== 200) {
      this.setState({
        errorMessage:
          'Ops, não foi possível carregar as linhas deste usuário e unidade.',
      });
      return;
    } else {
      this.setState({ lines: lines.data }, () => {
        this.setState({
          line:
            this.state.lines && this.state.lines[0]
              ? this.state.lines[0].line_id
              : null,
        });
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { showPassword } = this.state;
    return (
      <Grid className={classes.main}>
        <img alt="Logo" src="/images/logos/logoicep.png" height="50px" />
        <Container component="main" maxWidth="xs">
          <form id="auth" onSubmit={this.handleSubmit}>
            <ErrorMessage
              display={this.state.errorMessage !== '' ? 'inherited' : 'none'}
            >
              {this.state.errorMessage}
            </ErrorMessage>

            {this.state.idUser === 0 ? (
              <>
                <TextField
                  id="user"
                  type="text"
                  name="user"
                  label="Usuário"
                  value={this.state.user}
                  margin="normal"
                  required
                  variant="outlined"
                  autoFocus
                  fullWidth
                  placeholder="Usuário"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          <PermIdentityOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.handleChange}
                />

                <TextField
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={this.state.password}
                  variant="outlined"
                  label="Senha"
                  margin="normal"
                  onChange={this.handleChange}
                  required
                  fullWidth
                  placeholder="Senha"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            ) : (
              <>
                <FormControl
                  error={false}
                  className={classes.formControl}
                  fullWidth
                >
                  <TextField
                    id="user"
                    type="text"
                    name="user"
                    label="Conectado Como:"
                    value={this.state.username}
                    margin="normal"
                    variant="outlined"
                    disabled
                    autoFocus
                    fullWidth
                    onChange={this.handleChange}
                  />
                </FormControl>
                <FormControl
                  error={false}
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel id="unity-label">Unidade</InputLabel>
                  <Select
                    id="unity"
                    name="unity"
                    value={this.state.unity}
                    labelId="unity-label"
                    required={this.state.idUser === 0 ? false : true}
                    onChange={this.handleChangeUnity}
                    fullWidth
                    labelWidth={60}
                    placeholder="Selecione uma Unidade"
                  >
                    {this.state.unities.map((item) => (
                      <MenuItem key={item.unity.id} value={item.unity.id}>
                        {item.unity.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            <Box mt={4}>
              <Button
                type="submit"
                fullWidth
                margin="normal"
                variant="contained"
                color="primary"
              >
                Acessar
              </Button>
            </Box>

            {this.state.idUser !== 0 ? (
              <>
                <div className={classes.loginTextDivider}>
                  <div className={classes.loginDivider}>
                    <span />
                    <span> Não é Você?</span>
                    <span />
                  </div>
                </div>
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  onClick={this.handleAnotherAccountClick}
                >
                  Acessar com outra conta
                </Button>
              </>
            ) : (
              <></>
            )}
          </form>
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="http://iforth.net/">
                iForth
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
            </Typography>
          </Box>
        </Container>
      </Grid>
    );
  }
}
export default withStyles(styles)(auth);
