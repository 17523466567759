import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPallet, faChartPie } from '@fortawesome/free-solid-svg-icons';
import SidebarItem from './SidebarItem';
import { doLogout } from '../../../../../services/auth';
import InputIcon from '@material-ui/icons/Input';

import {
  Dashboard,
  StoreOutlined,
  AssignmentTurnedInOutlined,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {},
  palletIcon: {
    width: '1.9em',
  },
}));

const getSidebarItem = (key) =>
  ({
    PRODUÇÃO: {
      id: '1',
      title: 'Produção',
      href: '/production',
      icon: <FontAwesomeIcon icon={faPallet} />,
    },
    QUALIDADE: {
      id: '2',
      title: 'Qualidade',
      href: '/quality',
      icon: <AssignmentTurnedInOutlined />,
    },
    SHOWROOM: {
      id: '3',
      title: 'Showroom',
      href: '/showroom',
      icon: <StoreOutlined />,
    },
    MATRIZARIA: {
      id: '4',
      title: 'Matrizaria',
      href: '/matrix',
      icon: <Dashboard />,
    },
  }[key]);

const dashboard = {
  id: '0',
  title: 'Dashboard',
  href: '/dashboard',
  icon: <FontAwesomeIcon icon={faChartPie} />,
};
const handleLogoff = () => {
  doLogout();
};

const leave = {
  id: '5',
  title: 'Sair',
  href: '/login',
  icon: <InputIcon />,
};

const loadModules = (data) => {
  if (data) {
    let key = data.module.description;
    let item = getSidebarItem(key);
    return item;
  }
};

const SidebarNav = (props) => {
  const { modules, className, onClose, open, ...rest } = props;
  const classes = useStyles();
  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <SidebarItem
        key={dashboard.id}
        open={open}
        item={dashboard}
        onClose={onClose}
      />
      {modules.map((item) => {
        return (
          <SidebarItem
            onClose={onClose}
            key={item.id}
            item={loadModules(item)}
          />
        );
      })}
      <SidebarItem
        onClose={onClose}
        key={leave.id}
        item={leave}
        onClick={handleLogoff}
        z
      />
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
};

export default SidebarNav;
