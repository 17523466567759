import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Table as ExternalTable } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

import TableHead from './components/TableHead';
import Menu from '../Menu';
import Status from '../Status';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

const Pagination = styled(TablePagination)`
  @media (max-width: 768px) {
    .MuiTablePagination-toolbar {
      display: flex;
      flex-direction: column;
    }
    .MuiTablePagination-spacer {
      flex: 1 1 100%;
      padding-top: 10px;
    }
    .MuiTablePagination-selectRoot {
      margin: 0px;
    }
    .MuiTablePagination-actions {
      margin: 0px;
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const Table = props => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('mix');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { headCells, rows } = props;

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    /* 
   if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
    */
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getSorting = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <ExternalTable
            className={classes.table}
            aria-label="enhanced table"
            aria-labelledby="tableTitle"
          >
            <TableHead
              order={order}
              classes={classes}
              orderBy={orderBy}
              rowCount={rows.length}
              headCells={headCells}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell align="right" width={20}>
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.client}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">
                        <Status type={row.status}></Status>
                      </TableCell>
                      <TableCell align="center" width={50}>
                        <Menu
                          handleOpen={() => row.handleAction(row)}
                          width={100}
                          options={[
                            {
                              id: '1',
                              item: 'Anexar',
                              icon: faPaperclip,
                              handleClick: () => row.handleAction(row),
                            },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </ExternalTable>
        </div>
        <Pagination
          page={page}
          count={rows.length}
          component="div"
          rowsPerPage={rowsPerPage}
          className={classes.TablePagination}
          onChangePage={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={'Registros por Página'}
          backIconButtonProps={{
            'aria-label': 'Anterior',
          }}
          nextIconButtonProps={{
            'aria-label': 'Próxima',
          }}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default Table;
