import "core-js/es/map";
import "core-js/es/set";
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import App from "./App";

Sentry.init({
  dsn: "https://47267a3a8af8462b8bf282d46d35db9d@sentry.io/1518286"
});

ReactDOM.render(<App />, document.getElementById("root"));
