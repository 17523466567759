import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Paper,
  Modal as ExternalModal,
  Backdrop,
  IconButton,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    width: '700px',
    height: '600px',
    '@media (max-width:780px)': {
      width: '90%',
      height: '90%',
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },

  paperSmall: {
    width: '500px',
    height: '400px',
    '@media (max-width:780px)': {
      width: '70%',
      height: '65%',
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },

  card: {
    width: '100%',
    height: '100%',
  },

  cardContent: {
    width: '100%',
    height: 'calc(100% - 73px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  dividerBottom: {
    position: 'relative',
    bottom: '140px',
  },
}));

const Modal = (props) => {
  const classes = useStyles();
  const { title, subheader, handleClose, open, component } = props; //actions

  return (
    <ExternalModal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <Paper
        className={props.size === 'small' ? classes.smallPaper : classes.paper}
      >
        <Card className={classes.card}>
          <CardHeader
            action={
              <IconButton onClick={handleClose} aria-label="close">
                <Close />
              </IconButton>
            }
            title={title}
            subheader={subheader}
          />
          <Divider />
          <CardContent className={classes.cardContent}>{component}</CardContent>
        </Card>
      </Paper>
    </ExternalModal>
  );
};

export default Modal;
