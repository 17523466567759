import React from 'react';
import 'chart.piecelabel.js';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import pieTheme from '../../theme/charts/trade_map';
import _ from 'lodash';

import Option from './option';

const PieChart = (props) => {
  const { title, subtext, data } = props;

  const dataNames =
    data &&
    data.map((i) => {
      let stop =
        i.stop_motive && _.upperFirst(_.lowerCase(i.stop_motive.description));
      let defect =
        i.defect &&
        (i.retained_square_meters
          ? i.retained_square_meters.replace('.', ',') + ' m² - '
          : '') + _.upperFirst(_.lowerCase(i.defect.description));
      let string = stop || defect;

      return string;
    });

  const names = dataNames;

  const optData =
    data &&
    data.map((d) => {
      return {
        value:
          d.stops_time ||
          d.retained_square_meters ||
          d.defects_quantity + d.defects_broken,
        name:
          (d.stop_motive &&
            _.upperFirst(_.lowerCase(d.stop_motive.description))) ||
          (d.defect &&
            (d.retained_square_meters
              ? d.retained_square_meters.replace('.', ',') + ' m² - '
              : '') + _.upperFirst(_.lowerCase(d.defect.description))),
      };
    });

  echarts.registerTheme('icep', pieTheme);

  const style = {
    height: window.screen.width <= 768 ? '575px' : '450px',
    width: '100%',
  };

  return (
    <div>
      <ReactEcharts
        style={style}
        option={Option(title, subtext, optData, names)}
        theme="icep"
      />
    </div>
  );
};

export default PieChart;
