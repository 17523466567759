import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import {
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatDate } from '../../../utils/format';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  fab: {
    right: theme.spacing(1),
    position: 'relative',
  },
}));

const ViewProvisionsModal = (props) => {
  const [data, setData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    async function fetch() {
      const resp = await api.get(`/msiresolutions/${props.item}/dispositions`);
      if (resp.data) {
        setData(resp.data);
      }
    }

    fetch();
  }, [props.item]);

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Código</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell>Usuário</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>
                  {' '}
                  {formatDate(row.updatedAt, "d 'de' MMM 'de' yy, kk:mm")}
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell align="center" width={50}>
                  {row.user.name}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ViewProvisionsModal;
