import React from 'react';
import { Card, Row, Text, Container } from '../../../styles/components';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

const StyledCard = styled.div`
  border-radius: 4px;
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
`;

const Birthdays = ({ birthdays, ...props }) => {
  const today = new Date();

  return (
    <>
      <Row>
        <Card width="50%" minWidth="100px">
          <Container>
            <Text
              fontSize="42px"
              fontWeight={500}
              color="#595d6e"
              fontFamily="Poppins"
            >
              <FontAwesomeIcon icon={faCalendarDay} /> Aniversariantes do dia{' '}
              <img
                alt="party face"
                src="https://i.pinimg.com/originals/bc/79/99/bc79994238ff340879b2c012a73dc35e.png"
                height="38px"
                width="38px"
              />
            </Text>
            <br />

            {birthdays &&
              birthdays.map(item => {
                return today.getDate().toString() ===
                  item.birthdate.split('T')[0].split('-')[2] ? (
                  <StyledCard key={item.id}>
                    <Text fontSize="32px" fontWeight={500} color="#595d6e">
                      {item.name}
                    </Text>

                    <img
                      key={item.id + '-img'}
                      alt="cake"
                      src="https://pngimage.net/wp-content/uploads/2018/05/cake-emoji-png-2.png"
                      height="36px"
                      width="36px"
                    />
                  </StyledCard>
                ) : null;
              })}
          </Container>
        </Card>

        <Card width="50%" minWidth="100px">
          <Container>
            <Text
              fontSize="42px"
              fontWeight={500}
              color="#595d6e"
              fontFamily="Poppins"
            >
              <FontAwesomeIcon icon={faCalendar} /> Aniversariantes do mês
            </Text>
            <br />

            {birthdays &&
              birthdays.map(item => {
                return today.getDate().toString() !==
                  item.birthdate.split('T')[0].split('-')[2] ? (
                  <Text fontSize="24px" color="#595d6e" key={item.id}>
                    {item.birthdate.split('T')[0].split('-')[2] +
                      '/' +
                      item.birthdate.split('T')[0].split('-')[1] +
                      ' - ' +
                      item.name}
                  </Text>
                ) : null;
              })}
          </Container>
        </Card>
      </Row>
    </>
  );
};

export default Birthdays;
