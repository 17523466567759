import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import GlobalStyle from "./styles/globals";
import theme from './theme';
import Routes from "./routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GlobalStyle />
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

