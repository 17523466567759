import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import { Link } from '../styles/components';
import { Grid } from '@material-ui/core';
import gradients from '../utils/gradients';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    width: '560px',
  },
  description: {
    paddingRight: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  avatar: {
    width: 48,
    height: 48,
    backgroundImage: (props) => {
      return gradients[props.color];
    },
  },
}));

const CardRouteProduction = (prop) => {
  const {
    ref,
    icon,
    path,
    title,
    color,
    variant,
    className,
    description,
    ...rest
  } = prop;
  const props = { color };
  const classes = useStyles(props);
  return (
    <Link href={path} target="_blank">
      <Card {...rest} className={clsx(classes.root, className)}>
        <Grid className={classes.details} direction="row" xs={12}>
          <Grid
            className={classes.description}
            xs={10}
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              container
              className={classes.description}
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Typography component="h3" variant="overline">
                {title}
              </Typography>
            </Grid>

            <Typography component="h4" variant="h6">
              {description}
            </Typography>
          </Grid>
          <Grid xs={2} direction="row" justify="center" alignItems="center">
            <Avatar variant={variant} className={`${classes.avatar}`}>
              {icon}
            </Avatar>
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
};

CardRouteProduction.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
};

export default CardRouteProduction;
