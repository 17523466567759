import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  startDateButton: {
    marginRight: theme.spacing(1),
  },
  endDateButton: {
    marginLeft: theme.spacing(1),
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Header = props => {
  const { title, subtitle, description, className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {title && (
        <Typography component="h2" gutterBottom variant="overline">
          {title}
        </Typography>
      )}

      {subtitle && (
        <Typography component="h1" gutterBottom variant="h3">
          {subtitle}
        </Typography>
      )}

      {description && (
        <Typography variant="subtitle1">{description}</Typography>
      )}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  description: PropTypes.string,
};

Header.defaultProps = {};

export default Header;
