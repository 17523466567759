import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { fontWeight, space } from 'styled-system';
import { switchProp } from 'styled-tools';

const StyledChip = styled.span`
  color: #ffffff;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  line-height: 18px;
  padding: 0 5px;
  border: 1px solid transparent;
  ${switchProp('size', {
    large: css`
      height: 44px;
      font-size: 16px;
      padding: 0 16px;
      border-radius: 22px;
      font-weight: 500;
    `,
    medium: css`
      height: 34px;
      font-size: 14px;
      padding: 0 12px;
      border-radius: 18px;
      font-weight: 600;
    `,
    small: css`
      height: 24px;
      font-size: 12px;
      padding: 0 8px;
      border-radius: 12px;
    `,
  })};
  ${switchProp('variant', {
    contained: css`
      background: ${props => props.color.light};
    `,
    outlined: css`
      color: ${props => props.color.main};
      background: ${props => props.color.lightest};
      border-color: ${props => props.color.main};
    `,
    text: css`
      color: ${props => props.color.dark};
      background: ${props => props.color.light};
    `,
  })};
  ${space};
  ${fontWeight};

  > svg {
    margin-top: -0.125em;
  }
`;

const Chip = React.memo(
  ({
    variant = 'contained',
    size = 'small',
    color = 'blue',
    fontWeight = 500,
    ...props
  }) => {
    return (
      <StyledChip
        variant={variant}
        size={size}
        color={color}
        fontWeight={fontWeight}
        {...props}
      />
    );
  },
);

Chip.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.object,
  children: PropTypes.node,
  ...space.propTypes,
  ...fontWeight.propTypes,
};

export default Chip;
