import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { Content, Row, Text, Span, Logo } from '../../styles/components';

import api from '../../services/api';
import logo from '../../assets/images/icep.png';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { useStyles } from '../styles';

const Birthdays = ({ ...props }) => {
  const today = new Date();

  const classes = useStyles();
  const [birthdays, setBirthdays] = useState([]);

  const handleGetBirthdays = useCallback(async () => {
    const birthDays = await api.get(`/users/month/birthdays`);

    if (
      !birthDays.status ||
      birthDays.status !== 200 ||
      !birthDays.data[0].id
    ) {
      return;
    } else {
      setBirthdays(birthDays.data);
    }
  }, []);

  useEffect(() => {
    handleGetBirthdays();
  }, [handleGetBirthdays]);

  return (
    <Content>
      <Row margin="10px 0px 0px 10px">
        <Span display="flex" flexDirection="column" width="33%">
          <Text fontSize="24px" fontWeight={900}>
            Aniversariantes
          </Text>
        </Span>

        <Span
          display="flex"
          flexDirection="column"
          width="33%"
          alignItems="flex-end"
        >
          <Text padding="0px 20px 0px 0px">
            <Logo src={logo} alt="logo" />
          </Text>
        </Span>
      </Row>
      <Row>
        <Text fontSize="16px" fontWeight={400} color="#ccc" padding="0px 10px">
          Aniversariantes do dia e do mês.
        </Text>
      </Row>

      <div className={classes.results}>
        <Grid container spacing={3}>
          {birthdays &&
            birthdays.map(
              (item) =>
                today.getDate().toString() ===
                  item.birthdate.split('T')[0].split('-')[2] && (
                  <Grid key={item.id} item lg={4} sm={4} xs={12}>
                    <Card>
                      <CardContent className={classes.content}>
                        <Grid className={classes.stats}>
                          <Typography variant="body2">
                            Aniversariante do dia
                          </Typography>
                          <Typography variant="h6">
                            <span
                              role="img"
                              alt="birthday cake"
                              aria-label="birthday cake"
                            >
                              🎂
                            </span>{' '}
                            {item.birthdate.split('T')[0].split('-')[2] +
                              '/' +
                              item.birthdate.split('T')[0].split('-')[1] +
                              ' - ' +
                              item.name}
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ),
            )}

          {birthdays &&
            birthdays.map(
              (item) =>
                today.getDate().toString() !==
                  item.birthdate.split('T')[0].split('-')[2] && (
                  <Grid key={item.id} item lg={4} sm={4} xs={12}>
                    <Card>
                      <CardContent className={classes.content}>
                        <Grid className={classes.stats}>
                          <Typography variant="body2">
                            Aniversariante do mês
                          </Typography>
                          <Typography variant="h6">
                            {item.birthdate.split('T')[0].split('-')[2] +
                              '/' +
                              item.birthdate.split('T')[0].split('-')[1] +
                              ' - ' +
                              item.name}
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ),
            )}
        </Grid>
      </div>
    </Content>
  );
};

export default Birthdays;
