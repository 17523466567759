import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  ${(props) => props.padding && `padding: ${props.padding};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
`;

export const Row = styled.div`
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const Sidebar = styled.aside`
  min-width: 250px;
  padding: 5px 10px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  padding-bottom: 10px;
  width: 100%;
  ${(props) => props.maxWidth && `max-width:${props.maxWidth}`};
  ${(props) => props.padding && `padding:${props.padding}`};
  ${(props) =>
    props.shadow && `box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);`};
  display: flex;
  background: #fff;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  align-items: center;
  vertical-align: middle;
  border-radius: 5px;

  img {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h1 {
    color: #202124;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
  }

  label {
    width: 100%;
    margin-top: 20px;
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  }

  input {
    width: 100%;
  }

  input[type='text'] {
    height: 42px;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 0 20px;
    font-size: 14px;
  }

  input[type='password'] {
    height: 42px;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 0 20px;
    font-size: 14px;
  }

  select {
    background-color: #fff;
    width: 100%;
    height: 42px;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 0 20px;
    font-size: 14px;
  }

  button {
    width: 100%;
    margin: 20px 0px;
    height: 45px;
    padding: 10px 20px;
    border-radius: 3px;
    border: 0;
    background: #0069ff;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const Card = styled.div`
  margin: ${(props) => (props.margin ? `${props.margin}` : '10px;')};
  padding: ${(props) => (props.padding ? `${props.padding}` : '25px')};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color:  ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
  border-radius: 4px;
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.minHeight && `min-height: ${props.minHeight};`}
  ${(props) => props.minWidth && `min-width: ${props.minWidth};`}

  ${(props) =>
    props.hoverEffect &&
    `
    cursor: pointer;
    &:hover {
      transition: background-color 0.5s ease;
      background-color: ${props.hoverColor};
    }
  `}
`;

export const Text = styled.span`
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.fontSize && `font-size: ${props.fontSize};`}
  ${(props) => props.fontFamily && `font-family: ${props.fontFamily};`}

  @media only screen and (max-width: 768px) {
    ${(props) =>
      props.fontSizeMobile
        ? `font-size: ${props.fontSizeMobile};`
        : `font-size: 16px;`}
    
  }
`;

export const Span = styled.span`
  ${(props) => props.display && `display: ${props.display};`}
  ${(props) => props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.minHeight && `min-height: ${props.minHeight};`}
  ${(props) => props.minWidth && `min-width: ${props.minWidth};`}
`;

export const Select = styled.select`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 14px;
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
`;

export const Button = styled.button`
  border-radius: 3px;
  border: ${(props) => (props.kind === 'outlined' ? '1px solid #0069ff' : '0')};
  background: ${(props) => (props.kind === 'outlined' ? '#fff' : '#0069ff')};
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 128px;

  @media only screen and (max-width: 768px) {
    width: 64px;
  }
`;
