import React from 'react';
import ReactEcharts from 'echarts-for-react';

const BarAndLineChart = ({ data }) => {
  return (
    <div>
      <ReactEcharts
        style={{
          margin: '6px auto',
        }}
        option={{
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999',
              },
            },
          },

          legend: {
            data: ['Real', 'POA ' + new Date().getFullYear()],
          },
          xAxis: [
            {
              type: 'category',
              data: data?.dates || [],
              axisPointer: {
                type: 'shadow',
              },
              axisLabel: {
                interval: 0,
                rotate: 90,
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: 'Eficiência',
              min: 0,
              max: 100,
              axisLabel: {
                formatter: '{value}',
              },
            },
          ],
          series: [
            {
              name: 'Real',
              type: 'bar',
              itemStyle: {
                normal: {
                  color: '#EA7369',
                  label: {
                    show: true,
                    position: 'inside',
                    rotate: 90,
                  },
                },
              },
              data: data?.values || [],
            },
            {
              name: 'POA ' + new Date().getFullYear(),
              type: 'line',
              itemStyle: { color: 'green' },
              data: data?.poa_values || [],
            },
          ],
        }}
      />
    </div>
  );
};

export default BarAndLineChart;
