import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { useStyles, tableStyles } from './styles';
import { CircularProgress, Grid } from '@material-ui/core';
import Modal from '../../../../components/Modal';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Menu from '../../../../components/Menu';
import {
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core';
import ManageStageModal from './components/ManageStageModal';
import styled from 'styled-components';

const StyledTableRow = styled(TableRow)`
  .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(0, 0, 0, 0.07);
    border-left-color: #0086fb;
    border-left: solid;
    border-left-width: 6px;
  }
`;
const Progress = () => {
  const classes = useStyles();
  const classesTable = tableStyles();

  const token = localStorage.getItem('@icep-app/token');
  const [action, setAction] = useState(null);
  const [currentItem, setCurrentItem] = useState([]);
  const [isManageModalOpened, setIsManageModalOpened] = useState(false);
  const [showrooms, setShowrooms] = useState([]);
  const [progress, setProgress] = useState(true);

  const getShowroom = async data => {
    const row = [];

    data.forEach(showroom => {
      const obj = {};
      obj.showroom_id = showroom.id;
      obj.customer_description = showroom.customer.description;
      obj.city = showroom.customer.city.description;
      obj.status = showroom.flag;

      obj.rule_id = showroom.rule.id;
      obj.rule_description = showroom.rule.description;

      obj.current_stage_id = showroom.current_stage.id;
      obj.current_stage_description = showroom.current_stage.description;
      obj.current_stage_long_description =
        showroom.current_stage.long_description;

      row.push(obj);
    });
    setShowrooms(row);
  };

  useEffect(() => {
    async function fetch() {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(`/progress`);
      if (resp.status === 200 && resp.data) {
        getShowroom(resp.data);
        setProgress(false);
      }
    }
    fetch();
  }, [token]);

  const handleToNext = item => {
    setAction('next');
    setCurrentItem(item);
    setIsManageModalOpened(true);
  };

  const handleToPrevious = item => {
    setAction('previous');
    setCurrentItem(item);
    setIsManageModalOpened(true);
  };

  const updateData = async () => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resp = await api.get(`/progress`);
    setProgress(false);
    getShowroom(resp.data);
  };

  const handleCloseActionStage = async reload => {
    setAction(null);
    setCurrentItem(null);
    setIsManageModalOpened(false);

    if (reload === true) {
      setProgress(true);
      updateData();
    }
  };

  return (
    <>
      {progress ? (
        <Grid
          className={classes.progress}
          container
          justify="center"
          alignItems="center"
        >
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <Paper className={classes.paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Showroom</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Cidade</TableCell>
                  <TableCell>Etapa Atual</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {showrooms.map(row => (
                  <StyledTableRow
                    key={row.showroom_id}
                    hover
                    className={classesTable.hover}
                  >
                    <TableCell component="th" scope="row">
                      {row.showroom_id}
                    </TableCell>
                    <TableCell>{row.customer_description}</TableCell>

                    <TableCell>{row.city}</TableCell>
                    <TableCell>{`${row.current_stage_description}: ${row.current_stage_long_description} `}</TableCell>

                    <TableCell align="center" width={50}>
                      <Menu
                        width={200}
                        options={[
                          {
                            id: '2',
                            item: 'Avançar Etapa',
                            icon: faArrowRight,
                            handleClick: () => handleToNext(row),
                          },
                          {
                            id: '3',
                            item: 'Retroceder Etapa',
                            icon: faArrowLeft,
                            color: 'red',
                            handleClick: () => handleToPrevious(row),
                          },
                        ]}
                      />
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <br />
        </>
      )}
      {isManageModalOpened && (
        <Modal
          title={`${action === 'next' ? 'Avançar' : 'Retroceder'} etapa`}
          subheader={`Preencha as informações necessárias para realizar esta ação.`}
          open={true}
          handleClose={handleCloseActionStage}
          children={
            <ManageStageModal
              action={action}
              data={currentItem}
              handleClose={handleCloseActionStage}
            />
          }
        />
      )}
    </>
  );
};

export default Progress;
