import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Flex } from 'rebass';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

const WorkInProgress = props => {
  const classes = useStyles();
  return (
    <Flex justifyContent="center">
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          Desculpe!
        </Typography>
        <Typography component="p">Pagina em construção!</Typography>
      </Paper>
    </Flex>
  );
};

export default WorkInProgress;
