const useAuth = () => {
  const profileItems =
    localStorage.getItem('@icep-app/profileitems').split(',') || [];

  return {
    hasPermission: (item) => {
      return profileItems.includes(item);
    },
  };
};

export default useAuth;
