import { makeStyles, emphasize } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  main: {
    height: '460px',
    '@media (max-width:780px)': {
      height: '590px',
    },
  },
  roominess: {
    display: 'flex',
  },
  row: {
    padding: theme.spacing(2, 2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tipografy: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    minWidth: '100%',
  },
  formControlRoominess: {
    margin: theme.spacing(3),
  },
  notes: {
    position: 'relative',
    top: '0px',
    marginTop: '-65px',
    paddingTop: '0px',
  },
  bottom: {
    marginTop: '20px',
  },
  buttons: {
    marginTop: '20px',
  },
  divider: {
    width: '100%',
  },
  img: {
    marginTop: '5px',
    width: '50% !important',
    height: 'auto',

    '@media (max-width:1024px) and (max-height:1366px)': {
      width: '70% !important',
      height: '100% !important',
    },
  },
  list: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  roominessBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    flexGrow: 1,
    minWidth: 290,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));
