import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import api from '../../../../services/api';
import Footer from '../Footer';
import Profile from '../Profile';
import SidebarNav from './components/SidebarNav';

export const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: '#f9f9fc',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const [modules, setModules] = useState([]);
  const idUser = localStorage.getItem('@icep-app/iduser');
  const token = localStorage.getItem('@icep-app/token');
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(`/usermodules/${idUser}/modules`);
      setModules(resp.data);
    }
    fetchData();
  }, [idUser, token]);

  return (
    <Drawer
      open={open}
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          onClose={onClose}
          open={open}
          className={classes.nav}
          modules={modules}
        />
      </div>
      <Footer className={classes.footer} />
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
