import React from 'react';
import NumberFormat from 'react-number-format';
import { Card, Text, Container } from '../../../styles/components';
import { Grid } from '@material-ui/core';

const OEE = ({
  productions,
  cardPadding = null,
  cardFontSize = null,
  ...props
}) => {
  return (
    <div>
      <br />
      <Grid container spacing={3}>
        <Grid item lg={4} sm={12} xs={12}>
          <Card
            width="100%"
            minWidth="100px"
            backgroundColor="green"
            padding={cardPadding ? cardPadding : '75px 0px'}
            margin="0px"
          >
            <Container>
              <Text
                fontSizeMobile="24px"
                padding="10px 0px"
                fontSize={cardFontSize ? cardFontSize : '90px'}
                fontWeight={700}
                fontFamily="Poppins"
                color="#fff"
                textAlign="center"
              >
                {productions && productions[0] ? (
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={Number(productions[0].availability)}
                    displayType={'text'}
                  />
                ) : (
                  '#'
                )}
                %
              </Text>
              <Text
                fontSize="24px"
                fontWeight={500}
                color="#fff"
                fontFamily="Poppins"
                textAlign="center"
              >
                Disponibilidade (DGFM)
              </Text>
            </Container>
          </Card>
        </Grid>
        <Grid item lg={4} sm={12} xs={12}>
          <Card
            width="100%"
            minWidth="100px"
            backgroundColor="#f7ca49"
            padding={cardPadding ? cardPadding : '75px 0px'}
            margin="0px"
          >
            <Container>
              <Text
                fontSizeMobile="24px"
                padding="10px 0px"
                fontSize={cardFontSize ? cardFontSize : '90px'}
                fontWeight={700}
                fontFamily="Poppins"
                color="#000"
                textAlign="center"
              >
                {productions && productions[0] ? (
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={Number(productions[0].performance)}
                    displayType={'text'}
                  />
                ) : (
                  '#'
                )}
                %
              </Text>
              <Text
                fontSize="24px"
                fontWeight={500}
                color="#000"
                fontFamily="Poppins"
                textAlign="center"
              >
                Eficiência
              </Text>
            </Container>
          </Card>
        </Grid>

        <Grid item lg={4} sm={12} xs={12}>
          <Card
            width="100%"
            minWidth="100px"
            backgroundColor="blue"
            padding={cardPadding ? cardPadding : '75px 0px'}
            margin="0px"
          >
            <Container>
              <Text
                fontSizeMobile="24px"
                padding="10px 0px"
                fontSize={cardFontSize ? cardFontSize : '90px'}
                fontWeight={700}
                fontFamily="Poppins"
                color="#fff"
                textAlign="center"
              >
                {productions && productions[0] ? (
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={Number(productions[0].quality)}
                    displayType={'text'}
                  />
                ) : (
                  '#'
                )}
                %
              </Text>
              <Text
                fontSize="24px"
                fontWeight={500}
                color="#fff"
                fontFamily="Poppins"
                textAlign="center"
              >
                Qualidade
              </Text>
            </Container>
          </Card>
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <Card
            width="100%"
            minWidth="100px"
            backgroundColor="orange"
            padding={cardPadding ? cardPadding : '100px 0px'}
            margin="0px"
          >
            <Container>
              <Text
                fontSizeMobile="26px"
                textAlign="center"
                fontSize={cardFontSize ? cardFontSize : '110px'}
                fontWeight={700}
                fontFamily="Poppins"
                color="#fff"
              >
                OEE{' '}
                {productions && productions[0] ? (
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={Number(productions[0].oee)}
                    displayType={'text'}
                  />
                ) : (
                  '#'
                )}
                %
              </Text>
            </Container>
          </Card>
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <Card
            width="100%"
            minWidth="100px"
            backgroundColor="#27ab83"
            padding={cardPadding ? cardPadding : '100px 0px'}
            margin="0px"
          >
            <Container>
              <Text
                fontSizeMobile="26px"
                textAlign="center"
                fontSize={cardFontSize ? cardFontSize : '110px'}
                fontWeight={700}
                fontFamily="Poppins"
                color="#fff"
              >
                MTBF {productions && productions[0] ? productions[0].mtbf : '#'}
              </Text>
            </Container>
          </Card>
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <Card
            width="100%"
            minWidth="100px"
            backgroundColor="#65d5ac"
            padding={cardPadding ? cardPadding : '100px 0px'}
            margin="0px"
          >
            <Container>
              <Text
                fontSizeMobile="26px"
                textAlign="center"
                fontSize={cardFontSize ? cardFontSize : '110px'}
                fontWeight={700}
                fontFamily="Poppins"
                color="#fff"
              >
                MTTR {productions && productions[0] ? productions[0].mttr : '#'}
              </Text>
            </Container>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default OEE;
