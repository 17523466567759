/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Flex } from 'rebass';
import { TextField } from '@material-ui/core';
import { Grid, Button, Divider, Typography } from '@material-ui/core';
import { ArrowForward, ArrowBackSharp } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import api from '../../../../../../../services/api';
import { useStyles } from './styles';

const Shoowroom = (props) => {
  const { nextStep, prevStep, showroom, token } = props;
  const classes = useStyles();

  const [showroomTypes, setShowroomTypes] = useState([]);
  const [laborTypes, setLaborTypes] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);

  const [hasType, setHasType] = useState(true);
  const [hasLaborType, setHasLaborType] = useState(true);
  const [hasProjectType, setHasProjectType] = useState(true);

  const [state, setState] = useState({
    laborType: showroom.laborType,
    projectType: showroom.projectType,
    showroomType: showroom.showroomType,
  });

  const { showroomType, laborType, projectType } = state;

  const showroomTypesProps = {
    options:
      showroomTypes &&
      showroomTypes.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  const laborTypesProps = {
    options:
      laborTypes &&
      laborTypes.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  const projectTypesProps = {
    options:
      projectTypes &&
      projectTypes.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  const continues = (e) => {
    if (showroom.showroomType && showroom.laborType && showroom.projectType) {
      nextStep();
    } else {
      verifyAllFields();
    }
  };

  const back = (e) => {
    prevStep();
  };

  const verifyAllFields = (type) => {
    !showroom.showroomType ? setHasType(false) : setHasType(true);
    !showroom.laborType ? setHasLaborType(false) : setHasLaborType(true);
    !showroom.projectType ? setHasProjectType(false) : setHasProjectType(true);
  };

  const handleChange = (event, item) => {
    if (item) {
      let name = event.target.id.split('-', 1)[0];
      setState({ ...state, [name]: Number(item.value) });
      showroom[name] = item;
      verifyFields(name);
    }
  };
  const verifyFields = (type) => {
    if (type === 'laborType') {
      !showroom.laborType ? setHasLaborType(false) : setHasLaborType(true);
    }

    if (type === 'projectType') {
      !showroom.projectType
        ? setHasProjectType(false)
        : setHasProjectType(true);
    }

    if (type === 'showroomType') {
      !showroom.showroomType ? setHasType(false) : setHasType(true);
    }
  };

  const fetch = async (endpoint) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resp = await api.get(`/${endpoint}`);
    return resp.data;
  };

  useEffect(() => {
    async function fetchShowroomTypes() {
      const data = await fetch('showroomtypes');
      setShowroomTypes(data);
    }

    async function fetchLaborTypes() {
      const data = await fetch('labortypes');
      setLaborTypes(data);
    }

    async function fetchProjectTypes() {
      const data = await fetch('projecttypes');
      setProjectTypes(data);
    }

    fetchLaborTypes();
    fetchProjectTypes();
    fetchShowroomTypes();
  }, []);

  return (
    <>
      <div className={classes.row}>
        <Typography pt={5} variant="h5" component="h3">
          <Flex justifyContent="center">Tipo do Showroom</Flex>
        </Typography>
        <Autocomplete
          {...showroomTypesProps}
          id="showroomType"
          name="showroomType"
          value={showroomType}
          onChange={(event, newValue) => handleChange(event, newValue)}
          autocomplete="off"
          clearOnEscape
          getOptionLabel={(option) => option.title}
          renderInput={(textParams) => (
            <TextField
              {...textParams}
              error={!hasType}
              label="Tipo de showroom"
              margin="normal"
              fullWidth
            >
              {' '}
              Não encontrado
            </TextField>
          )}
        />
      </div>

      <div className={classes.row}>
        <Typography pt={5} variant="h5" component="h3">
          <Flex justifyContent="center">Mão de Obra</Flex>
        </Typography>
        <Autocomplete
          {...laborTypesProps}
          id="laborType"
          name="laborType"
          value={laborType}
          onChange={(event, newValue) => handleChange(event, newValue)}
          autocomplete="off"
          clearOnEscape
          getOptionLabel={(option) => option.title}
          renderInput={(textParams) => (
            <TextField
              {...textParams}
              error={!hasLaborType}
              label="Tipo de mão de obra"
              margin="normal"
              fullWidth
            >
              {' '}
              Não encontrado
            </TextField>
          )}
        />
      </div>

      <div className={classes.row}>
        <Typography pt={5} variant="h5" component="h3">
          <Flex justifyContent="center">Tipo do Projeto</Flex>
        </Typography>
        <Autocomplete
          {...projectTypesProps}
          id="projectType"
          name="projectType"
          value={projectType}
          autocomplete="off"
          clearOnEscape
          getOptionLabel={(option) => option.title}
          onChange={(event, newValue) => handleChange(event, newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!hasProjectType}
              label="Tipo de projeto"
              margin="normal"
              fullWidth
            >
              {' '}
              Não encontrado
            </TextField>
          )}
        />
      </div>

      <div className={classes.bottom}>
        <Divider />
        <Grid
          justify="space-between"
          container
          direction="row"
          className={classes.buttons}
          alignItems="flex-end"
        >
          <Button
            onClick={back}
            variant="contained"
            startIcon={<ArrowBackSharp />}
            className={classes.button}
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            onClick={continues}
            endIcon={<ArrowForward />}
            className={classes.button}
          >
            Avançar
          </Button>
        </Grid>
      </div>
    </>
  );
};

export default Shoowroom;
