import React, { useState, useEffect } from 'react';
import api from '../../../../../../services/api';
import { Flex } from 'rebass';
import {
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Button,
  Typography,
} from '@material-ui/core';

const ManageStageModal = ({ action, data, ...props }) => {
  const token = localStorage.getItem('@icep-app/token');
  const [loading, setLoading] = useState(false);

  const [obs, setObs] = useState(null);

  const [file, setFile] = useState(null);

  const [orderDescription, setOrderDescription] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);

  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const [invoiceValue, setInvoiceValue] = useState(null);

  const [hasObsError, setHasObsError] = useState(false);

  const [hasFileError, setHasFileError] = useState(false);

  const [hasOrderNumberError, setHasOrderNumberError] = useState(false);
  const [hasOrderStatusError, setHasOrderStatusError] = useState(false);
  const [hasOrderDescriptionError, setHasOrderDescriptionError] = useState(
    false,
  );

  const [hasInvoiceNumberError, setHasInvoiceNumberError] = useState(false);
  const [hasInvoiceStatusError, setHasInvoiceStatusError] = useState(false);
  const [hasInvoiceValueError, setHasInvoiceValueError] = useState(false);

  const [ruleItemData, setRuleItemData] = useState(null);

  useEffect(() => {
    async function fetch() {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(
        `/showrooms/${data.showroom_id}/currentruleitem`,
      );
      setRuleItemData(resp.data);
    }

    if (data && data.showroom_id) {
      fetch();
    }
  }, [data, token]);

  const handleImageChange = e => {
    setFile(e.target.files[0]);
  };

  const validateFields = () => {
    setHasObsError(!obs || obs.trim() === '');

    setHasFileError(!file && ruleItemData.force_attachment);

    setHasOrderNumberError(
      (!orderNumber || orderNumber.trim() === '') &&
        ruleItemData.force_order_data,
    );
    setHasOrderStatusError(
      (!orderStatus || orderStatus.trim() === '') &&
        ruleItemData.force_order_data,
    );
    setHasOrderDescriptionError(
      (!orderDescription || orderDescription.trim() === '') &&
        ruleItemData.force_order_data,
    );

    setHasInvoiceNumberError(
      (!invoiceNumber || invoiceNumber.trim() === '') &&
        ruleItemData.force_invoice_data,
    );
    setHasInvoiceStatusError(
      (!invoiceStatus || invoiceStatus.trim() === '') &&
        ruleItemData.force_invoice_data,
    );
    setHasInvoiceValueError(
      (!invoiceValue || invoiceValue.trim() === '') &&
        ruleItemData.force_invoice_data,
    );
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    validateFields();

    if (
      !obs ||
      obs.trim() === '' ||
      (!file && ruleItemData.force_attachment) ||
      ((!orderNumber || orderNumber.trim() === '') &&
        ruleItemData.force_order_data) ||
      ((!orderStatus || orderStatus.trim() === '') &&
        ruleItemData.force_order_data) ||
      ((!orderDescription || orderDescription.trim() === '') &&
        ruleItemData.force_order_data) ||
      ((!invoiceNumber || invoiceNumber.trim() === '') &&
        ruleItemData.force_invoice_data) ||
      ((!invoiceStatus || invoiceStatus.trim() === '') &&
        ruleItemData.force_invoice_data) ||
      ((!invoiceValue || invoiceValue.trim() === '') &&
        ruleItemData.force_invoice_data)
    ) {
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      formData.append('file', file);
      formData.append('showroom_id', data.showroom_id);
      formData.append('obs', obs);
      formData.append('action', action);

      formData.append('order_number', orderNumber);
      formData.append('order_status', orderStatus);
      formData.append('order_description', orderDescription);

      formData.append('invoice_number', invoiceNumber);
      formData.append('invoice_status', invoiceStatus);
      formData.append('invoice_value', invoiceValue);

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const res = await api.post(`progress/action`, formData, config);

      if (res && res.status === 200) {
        props.handleClose(true);
      } else alert('Ops, não foi possível realizar esta operação. :(');
      setLoading(false);
    } catch (e) {
      alert('Ops, não foi possível realizar esta operação. :( ' + e.message);
      setLoading(false);
    }
  };

  if (!data || !action || !ruleItemData) return null;

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        {ruleItemData.force_attachment && (
          <Grid item xs={12}>
            <Typography pt={5} pb={5} variant="h5" component="h3">
              <Flex>Anexo</Flex>
            </Typography>
            <FormControl error={hasFileError}>
              <br />
              <input
                id="file"
                type="file"
                label="Arquivo"
                margin="normal"
                required
                data-file={file}
                onChange={e => handleImageChange(e)}
                aria-describedby="attatchment-file"
              />
              <br />
            </FormControl>
          </Grid>
        )}

        {ruleItemData.force_order_data && (
          <Grid item xs={12}>
            <Typography pt={5} pb={5} variant="h5" component="h3">
              <br />
              <Flex>Dados do Pedido</Flex>
            </Typography>
            <Grid container justify="left" spacing={1}>
              <Grid item>
                <TextField
                  id="order_number"
                  type="text"
                  label="Número do pedido"
                  placeholder="Número do pedido"
                  onChange={e => setOrderNumber(e.target.value)}
                  value={orderNumber}
                  error={hasOrderNumberError}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="order_status"
                  type="text"
                  label="Status do pedido"
                  placeholder="Status do pedido"
                  onChange={e => setOrderStatus(e.target.value)}
                  value={orderStatus}
                  error={hasOrderStatusError}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="order_description"
                  type="text"
                  label="Descrição do pedido"
                  placeholder="Descrição do pedido"
                  onChange={e => setOrderDescription(e.target.value)}
                  value={orderDescription}
                  error={hasOrderDescriptionError}
                />
              </Grid>
            </Grid>
            <br />
          </Grid>
        )}

        {ruleItemData.force_invoice_data && (
          <Grid item xs={12}>
            <Typography pt={5} pb={5} variant="h5" component="h3">
              <br />
              <Flex>Dados da Nota</Flex>
            </Typography>
            <Grid container justify="left" spacing={1}>
              <Grid item>
                <TextField
                  id="invoice_number"
                  type="text"
                  label="Número do nota"
                  placeholder="Número da nota"
                  onChange={e => setInvoiceNumber(e.target.value)}
                  value={invoiceNumber}
                  error={hasInvoiceNumberError}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="invoice_status"
                  type="text"
                  label="Status da nota"
                  placeholder="Status da nota"
                  onChange={e => setInvoiceStatus(e.target.value)}
                  value={invoiceStatus}
                  error={hasInvoiceStatusError}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="invoice_value"
                  type="text"
                  label="Valor da nota"
                  placeholder="Valor da nota"
                  onChange={e => setInvoiceValue(e.target.value)}
                  value={invoiceValue}
                  error={hasInvoiceValueError}
                />
              </Grid>
            </Grid>
            <br />
          </Grid>
        )}

        <FormControl error={hasObsError} fullWidth>
          <Typography variant="h5" component="h3">
            <br />
            <Flex>Observação</Flex>
          </Typography>
          <TextField
            id="obs"
            type="text"
            placeholder="Observação"
            value={obs}
            onChange={e => setObs(e.target.value)}
            required
            fullWidth
            variant="outlined"
            aria-describedby="obs"
            error={hasObsError}
          />

          <FormHelperText id="obs-helper-text">
            Informe uma breve observação referente a esta ação.
          </FormHelperText>
        </FormControl>
        <br />
        <br />
      </Grid>
      <Grid>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            loading={loading}
          >
            {action === 'next' ? 'Avançar Etapa' : 'Retroceder Etapa'}
          </Button>
        </Flex>
      </Grid>
    </form>
  );
};

export default ManageStageModal;
