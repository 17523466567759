import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import _ from 'lodash';
import { easeQuadInOut } from 'd3-ease';
import styled from 'styled-components';
import {
  Grid,
  Paper,
  List,
  Button,
  colors,
  Divider,
  ListItem,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Box, Flex } from 'rebass';
import Paginate from '../../../components/Paginate';
import RemoveRedEyeRounded from '@material-ui/icons/RemoveRedEyeRounded';

import { useStyles } from '../../styles';
import Label from '../../../components/Label';
import Modal from '../../../components/Modal';
import 'react-circular-progressbar/dist/styles.css';
import api from '../../../services/api';
import { formatDate } from '../../../utils/format';
import AnimatedProgressProvider from '../../../components/AnimatedProgressProvider';
import Filter from '../../../components/Filter';

const StyledListItemText = styled(ListItemText)`
  max-width: auto;
  @media (max-width: 780px) {
    max-width: 120px;
  }
`;

const EarlyCars = () => {
  const classes = useStyles();
  const token = localStorage.getItem('@icep-app/token');
  const [progress, setProgress] = useState(true);
  const [openNew, setOpenNew] = useState(false);
  const [carsData, setCarsData] = useState([]);
  const [motivation, setMotivation] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);

  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState('');
  const [filterParams, setFilterParams] = useState({});
  const idUser = localStorage.getItem('@icep-app/iduser');
  const unity_id = localStorage.getItem('@icep-app/unity');

  useEffect(() => {
    async function fetch() {
      if (!!selectedLine) {
        const resp = await api.get(
          `/spcearlycars/unity/${unity_id}/line/${selectedLine}?page=${
            page + 1
          }`,
        );
        setTotalRows(resp.data.metadata.totalRows);
        setTotalPages(resp.data.metadata.totalPages);
        setCarsData(resp.data.source);
      }
      setProgress(false);
    }
    fetch();
  }, [page, selectedLine, token, unity_id]);

  const handleOpenModal = async (id) => {
    const resp = await api.get(`/spcearlycars/${id}/items`);
    setMotivation(resp.data);
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const onPageChange = async (item) => {
    setPage(item.selected);
  };

  const params = {
    period: '',
    selectedLine: selectedLine,
  };

  useEffect(() => {
    async function handleGetLines() {
      const resp = await api.get(
        `/userlines/${idUser}/unity/${unity_id}/lines`,
      );
      setLines(resp?.data);
      setSelectedLine(resp?.data[0]?.line_id);
    }
    handleGetLines();
  }, [idUser, unity_id]);

  const handleFilter = async (item) => {
    let line_id =
      item.selectedLine && item.selectedLine.value
        ? item.selectedLine.value
        : params.selectedLine;
    setSelectedLine(line_id);
  };

  const handleClear = async (item) => {
    setProgress(true);
    setFilterParams({ period: filterParams.period });

    const resp = await api.get(
      `/spcearlycars/unity/${unity_id}/line/${params.selectedLine}`,
    );
    setSelectedLine(lines[0].line_id);
    setCarsData(resp.data.source);
    setProgress(false);
  };

  const getLine = (id) => {
    var filtered = lines.filter((item) => {
      return item.line_id === parseInt(id);
    });
    return filtered[0]?.line?.description;
  };
  return (
    <>
      <Grid className={classes.qualityRoot}>
        <Grid
          alignItems="flex-end"
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              Produção
            </Typography>
            <Typography component="h1" variant="h3">
              Carro Adiantado
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {getLine(selectedLine)}
            </Typography>
          </Grid>
          <Grid item>
            <Filter
              onClear={handleClear}
              onFilter={handleFilter}
              className={classes.filterButton}
              showPeriod={false}
              filterParams={filterParams}
            />
          </Grid>
        </Grid>
        {progress ? (
          <Paper className={classes.paper}>
            <Box container justifyContent="center" alignItems="center">
              <Flex justifyContent="center" alignItems="center">
                <CircularProgress
                  className={classes.circularProgress}
                  color="secondary"
                />
              </Flex>
            </Box>
          </Paper>
        ) : (
          <div className={classes.results}>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Total de {totalRows} registros econtrados. Pagina {page + 1} de{' '}
              {totalPages}
            </Typography>
            <Paper>
              {carsData && carsData.length > 0 ? (
                <List dense className={classes.root}>
                  {carsData?.map((row) => (
                    <React.Fragment key={row.id}>
                      <ListItem
                        key={row.id}
                        button
                        alignItems="flex-start"
                        onClick={() => handleOpenModal(row.id)}
                      >
                        <ListItemAvatar className={classes.progress}>
                          <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={row.quality}
                            duration={1.4}
                            easingFunction={easeQuadInOut}
                          >
                            {(value) => {
                              const roundedValue = _.round(value, 2);
                              return (
                                <CircularProgressbar
                                  value={value}
                                  text={`${roundedValue}%`}
                                  styles={{
                                    root: {
                                      width: '70px',
                                    },
                                    path: {
                                      stroke: '#5C6BC0',
                                      strokeLinecap: 'butt',
                                      transition: 'none',
                                    },
                                    trail: {
                                      stroke: '#d6d6d6',
                                    },
                                    text: {
                                      fill: '#9FA8DA',
                                      fontSize: '20px',
                                      fontWeight: '700',
                                    },
                                  }}
                                />
                              );
                            }}
                          </AnimatedProgressProvider>
                        </ListItemAvatar>
                        <ListItemText
                          className={classes.text}
                          primary={
                            row.description +
                            ' ' +
                            '(' +
                            row.product_spec.reference +
                            ' ' +
                            (row.product_spec.format &&
                            row.product_spec.format.description
                              ? row.product_spec.format.description
                              : '') +
                            ')'
                          }
                          secondary={
                            <>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {formatDate(row.date)}
                              </Typography>
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => handleOpenModal(row.id)}
                            edge="end"
                            aria-label="comments"
                          >
                            <RemoveRedEyeRounded color="secondary" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider className={classes.divider} />
                    </React.Fragment>
                  ))}
                </List>
              ) : (
                <Grid
                  alignItems="flex-end"
                  container
                  justify="center"
                  className={classes.root}
                >
                  <Grid item>
                    <Typography
                      align="center"
                      color="textPrimary"
                      variant="body2"
                      className={classes.circularProgress}
                    >
                      Sem dados
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </div>
        )}
        <div className={classes.paginate}>
          <Paginate
            pageCount={totalPages}
            onPageChange={onPageChange}
            pageRangeDisplayed={1}
          />
        </div>
      </Grid>
      <Modal
        title="Vizualizar"
        subheader=""
        open={openNew}
        handleClose={handleCloseNew.bind(this)}
        component={
          <>
            <List
              style={{ overflowY: 'scroll' }}
              component="nav"
              aria-label="main mailbox folders"
            >
              {motivation.map((row) => (
                <ListItem key={row.id} button>
                  <StyledListItemText primary={row.defect.description} />
                  <ListItemSecondaryAction>
                    <div className={classes.conversion}>
                      <Label color={colors.orange[400]}>
                        <Typography variant="h6">
                          Quantidade: {row.quantity}
                        </Typography>
                      </Label>
                    </div>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Divider />
          </>
        }
        actions={<Button size="small">Learn More</Button>}
      />
    </>
  );
};

export default EarlyCars;
