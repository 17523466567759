import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import Filter from './components/Filter';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480,
  },
  filterButton: {
    marginLeft: 'auto',
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
  filterText: {
    '@media (max-width:500px)': {
      display: 'none',
    },
  },
}));

const SearchBar = props => {
  const {
    filterParams,
    label,
    onFilter,
    onClear,
    onSearch,
    endpoint,
    className,
    ...rest
  } = props;
  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  return (
    <Grid {...rest} className={clsx(classes.root, className)}>
      {/*     
        <Grid item>
          <Search
            className={classes.search}
            onSearch={onSearch}
          />
        </Grid> 
      */}
      <Grid item>
        <Button
          size="small"
          color="primary"
          onClick={handleFilterOpen}
          variant="outlined"
          className={classes.filterButton}
        >
          <FilterListIcon className={classes.filterIcon} />
          <Typography className={classes.filterText}>Filtros</Typography>
        </Button>
      </Grid>
      <Filter
        open={openFilter}
        filterParams={filterParams}
        label={label}
        onClose={handleFilterClose}
        onClear={onClear}
        endpoint={endpoint}
        onFilter={onFilter}
      />
    </Grid>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
};

export default SearchBar;
