import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';

export const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: '#43425dd4',
    margin: '4px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeight,

    fontStyle: 'normal',
    fontsize: '16px',
    lineheight: '22px',
  },
  icon: {
    color: '#43425D',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',

    marginRight: theme.spacing(2),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarItem = props => {
  const { item, onClick, onClose } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      {item && item.href && (
        <ListItem
          button
          component={CustomRouterLink}
          activeClassName={classes.active}
          to={onClick ? '/login' : item.href}
          key={item.id}
          className={classes.item}
          onClick={onClick ? onClick : onClose}
          disableGutters
        >
          <ListItemIcon>
            <IconButton disabled edge="end" aria-label="comments">
              <div className={classes.icon}>{item.icon}</div>
              <ListItemText primary={item.title} />
            </IconButton>
          </ListItemIcon>
        </ListItem>
      )}
    </React.Fragment>
  );
};

SidebarItem.propTypes = {
  className: PropTypes.string,
};

export default SidebarItem;
