import React, { useState } from 'react';

import TabPanel from './TabPanel';
import Typography from '@material-ui/core/Typography';
import { Box, Flex } from 'rebass';
import { makeStyles, withStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />);

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  top: {
    flexGrow: 1,
    backgroundColor: 'text',
  },
  title: {
    paddingLeft: '40px',
    paddingTop: '25px',
    flexGrow: 1,
    color: '#43425D',
    backgroundColor: '#f9f9fc',
  },
  tabs: {
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: '#f9f9fc',
  },
}));

const PageLayout = props => {
  const classes = useStyles();
  const { title, tabs } = props;
  const [value, setValue] = useState(0);

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getIndex = item => {
    return item.index;
  };

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Typography className={classes.title} variant="h3">
          {title}
        </Typography>
      </div>

      <StyledTabs
        className={classes.tabs}
        value={value}
        onChange={handleChange}
      >
        {tabs.map(item => (
          <StyledTab
            key={item.index}
            label={
              <>
                <Flex justifyContent="space-between">
                  <Box> {item.icon}</Box>
                  <Box ml={2}> {item.title}</Box>
                </Flex>
              </>
            }
            {...a11yProps(getIndex(item))}
          />
        ))}
      </StyledTabs>

      {tabs.map(item => (
        <TabPanel key={item.index} value={value} index={item.index}>
          {item.content}
        </TabPanel>
      ))}
    </div>
  );
};

export default PageLayout;
