import axios from 'axios';
import { createBrowserHistory } from 'history';
const token = localStorage.getItem('@icep-app/token');

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});

const history = createBrowserHistory({ forceRefresh: true });

api.interceptors.response.use(
  (response) => {
    if (response.status && ![200, 201].includes(response.status)) {
      const error = new Error();
      throw error;
    }

    return response;
  },
  (error) => {
    if ([401, 403].includes(error?.response?.status)) {
      if (history.location.pathname !== '/login') history.push('/login');
    }

    return Promise.reject(error);
  },
);

if (!!token) api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default api;
