import React, { useState } from 'react';
import api from '../../../../../../../services/api';
import { Flex } from 'rebass';
import {
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Button,
} from '@material-ui/core';

const NewAttatchmentModal = props => {
  const token = localStorage.getItem('@icep-app/token');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasDescriptionError, setHasDescriptionError] = useState(false);
  const [hasFileError, setHasFileError] = useState(false);

  const handleImageChange = e => {
    setFile(e.target.files[0]);
  };

  const validateFields = () => {
    setHasDescriptionError(!description || description.trim() === '');
    setHasFileError(!file);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    validateFields();
    if (!description || description.trim() === '' || !file) {
      setLoading(false);
      return;
    }

    try {
      const data = new FormData();
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      data.append('file', file);
      data.append('description', description);
      data.append('showroom_id', props.data.id);

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const res = await api.post('showroomattatchments', data, config);

      if (res && res.status === 200) {
        props.handleClose();
      } else alert('Ops, não conseguimos anexar este arquivo. :(');
      setLoading(false);
    } catch (e) {
      alert('Ops, não conseguimos anexar este arquivo. :( ' + e.message);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <FormControl error={hasDescriptionError} fullWidth>
          <TextField
            id="description"
            type="text"
            placeholder="Descrição do arquivo"
            value={description}
            onChange={e => setDescription(e.target.value)}
            required
            fullWidth
            variant="outlined"
            aria-describedby="attatchment-description"
          />

          <FormHelperText id="description-helper-text">
            Informe uma breve descrição para o arquivo a ser anexado.
          </FormHelperText>
        </FormControl>
        <br />
        <br />
      </Grid>
      <Grid item xs={12}>
        <FormControl error={hasFileError}>
          <input
            id="file"
            type="file"
            label="Arquivo"
            margin="normal"
            required
            data-file={file}
            onChange={e => handleImageChange(e)}
            aria-describedby="attatchment-file"
          />
        </FormControl>

        <br />
        <br />
        {/*  <FileInput
          primary
          icon={<FontAwesomeIcon icon={faPlus} color="white" />}
        /> */}
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            loading={loading}
          >
            Enviar
          </Button>
        </Flex>
      </Grid>
    </form>
  );
};

export default NewAttatchmentModal;
