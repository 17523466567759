import React, { useState, useEffect } from 'react';
import SearchBar from '../../../components/SearchBar';
import Header from '../../../components/Header';
import DetailModal from './DetailModal';
import {
  Card,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Box, Flex } from 'rebass';
import 'react-circular-progressbar/dist/styles.css';
import api from '../../../services/api';
import { useStyles } from '../../styles';
import ProjectCard from '../../../components/ProjectCard';
import Modal from '../../../components/Modal';
import Paginate from '../../../components/Paginate';

const LifeCicleMuratures = () => {
  const classes = useStyles();
  const token = localStorage.getItem('@icep-app/token');
  const unity_id = localStorage.getItem('@icep-app/unity');

  const [progress, setProgress] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [muraturesItemsData, setMuratureItemsData] = useState([]);
  const [detailId, setDetailId] = useState();
  const [filterParams] = useState({});

  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);

  const params = {
    format: 'all',
    cycle: '0',
    type: 'all',
  };

  useEffect(() => {
    async function fetch() {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(
        `/muratureitems/unity/${unity_id}/format/${params.format}/cycle/${params.cycle}/murature/${params.type}/`,
      );
      setTotalRows(resp.data.metadata.totalRows);
      setTotalPages(resp.data.metadata.totalPages);
      setMuratureItemsData(resp.data.source);
      setProgress(false);
    }
    fetch();
  }, [params.cycle, params.format, params.type, token, unity_id]);

  const handleOpenModal = async (id) => {
    setDetailId(id);
    setOpenModal(true);
  };

  const handleClear = async (item) => {
    setProgress(true);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resp = await api.get(
      `/muratureitems/unity/${unity_id}/format/${params.format}/cycle/${params.cycle}/murature/${params.type}/`,
    );
    setTotalRows(resp.data.metadata.totalRows);
    setTotalPages(resp.data.metadata.totalPages);
    setMuratureItemsData(resp.data.source);
    setProgress(false);
  };

  const handleFilter = async (item) => {
    setProgress(true);
    let typeId = item.type ? item.type.value : params.type;
    let cycleId = item.cycle ? item.cycle : params.cycle;
    let formatId = item.format ? item.format.value : params.format;

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resp = await api.get(
      `/muratureitems/unity/${unity_id}/format/${formatId}/cycle/${cycleId}/murature/${typeId}/`,
    );
    setTotalRows(resp.data.metadata.totalRows);
    setTotalPages(resp.data.metadata.totalPages);
    setMuratureItemsData(resp.data.source);
    setProgress(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const onPageChange = async (item) => {
    setPage(item.selected);
  };

  useEffect(() => {
    async function fetch() {
      setProgress(true);
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(
        `/muratureitems/unity/${unity_id}/format/${params.format}/cycle/${
          params.cycle
        }/murature/${params.type}?page=${page + 1}`,
      );

      setMuratureItemsData(resp.data.source);
      setProgress(false);
    }
    fetch();
  }, [page, params.cycle, params.format, params.type, token, unity_id]);

  return (
    <React.Fragment>
      <Grid className={classes.qualityRoot}>
        <Grid alignItems="flex-end" container justify="space-between">
          <Header title="Matrizaria" subtitle="Vida Útil - Muraturas" />
          <SearchBar
            filterParams={filterParams}
            label="Muraturas"
            endpoint="/muratures"
            onClear={handleClear}
            onFilter={handleFilter}
            className={classes.filterButton}
          />
        </Grid>
        {progress ? (
          <Paper className={classes.paper}>
            <Box container justifyContent="center" alignItems="center">
              <Flex justifyContent="center" alignItems="center">
                <CircularProgress
                  className={classes.circularProgress}
                  color="secondary"
                />
              </Flex>
            </Box>
          </Paper>
        ) : (
          <div className={classes.results}>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Total de {totalRows} registros econtrados. Pagina {page + 1} de{' '}
              {totalPages}
            </Typography>
            {muraturesItemsData.length > 0 ? (
              muraturesItemsData.map((row) => (
                <ProjectCard
                  key={row.id}
                  type="murature"
                  data={row}
                  openModal={handleOpenModal}
                />
              ))
            ) : (
              <Card className={classes.container}>
                <Typography
                  className={classes.circularProgress}
                  align="center"
                  variant="body2"
                  color="textPrimary"
                >
                  Sem dados
                </Typography>
              </Card>
            )}
          </div>
        )}
        <div className={classes.paginate}>
          <Paginate
            pageCount={totalPages}
            onPageChange={onPageChange}
            pageRangeDisplayed={1}
          />
        </div>
      </Grid>
      <Modal
        title="Vizualizar"
        subheader="Ultimas recuperações"
        open={openModal}
        handleClose={() => handleClose(false)}
        component={
          <DetailModal
            type="murature"
            label="Muratura"
            typeItem="murature_item"
            endpoint={'/muratureitems'}
            id={detailId}
          />
        }
      />
    </React.Fragment>
  );
};

export default LifeCicleMuratures;
