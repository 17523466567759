import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from './modules/Login/';

import Matrix from './modules/Matrix';
import LifeCicleBox from './modules/Matrix/components/LifeCicleBox';
import LifeCicleReliefs from './modules/Matrix/components/LifeCicleReliefs';
import LifeCicleMuratures from './modules/Matrix/components/LifeCicleMuratures';

import Defects from './modules/Production/components/Defects';
import EarlyCarStocks from './modules/Production/components/EarlyCarStocks';
import EarlyCars from './modules/Production/components/EarlyCars';
import ProductionStop from './modules/Production/components/ProductionStop';
import ProductionStopMotives from './modules/Production/components/ProductionStopMotives';
import SiloMassStocks from './modules/Production/components/SiloMassStocks';

import Quality from './modules/Quality';
import QualityItems from './modules/Quality/components/QualityItems';
import SafetyMachines from './modules/Quality/components/SafetyMachines';
import ShowRoom from './modules/ShowRoom';
import Dashboard from './modules/Dashboards';
import Production from './modules/Production';
import GeneralSetup from './modules/GeneralSetup';
import SlideShowDashboard from './modules/SlideShowDashboard';

import RouteWithLayout from './components/RouteWithLayout';
import MainLayout from './layouts/Main';
import NotFound from './components/NotFound';
import ProductionData from './modules/ProductionData';
import ProductionPerformance from './modules/ProductionPerformance';
import Birthdays from './modules/Birthdays';
import VisualAnalysisOSA from './modules/Dashboards/components/VisualAnalysisOSA';
import SiloMassStocksByFormule from './modules/Production/components/SiloMassStocksByFormule';
import NewReports from './modules/Dashboards/components/NewReports';
import EfficiencyAndQuality from './modules/EfficiencyAndQuality'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />

        <RouteWithLayout
          exact
          path="/dashboard"
          layout={MainLayout}
          component={Dashboard}
        />

        <RouteWithLayout
          path="/showroom"
          exact
          layout={MainLayout}
          component={ShowRoom}
        />

        <RouteWithLayout
          path="/quality"
          exact
          layout={MainLayout}
          component={Quality}
        />

        <RouteWithLayout
          path="/quality/items"
          exact
          layout={MainLayout}
          component={QualityItems}
        />

        <RouteWithLayout
          path="/quality/safety-machines"
          exact
          layout={MainLayout}
          component={SafetyMachines}
        />

        <RouteWithLayout
          path="/defects"
          exact
          layout={MainLayout}
          component={Defects}
        />

        <RouteWithLayout
          path="/production"
          exact
          layout={MainLayout}
          component={Production}
        />

        <RouteWithLayout
          path="/quality/early-cars"
          exact
          layout={MainLayout}
          component={EarlyCars}
        />

        <RouteWithLayout
          path="/production/early-cars-stocks"
          exact
          layout={MainLayout}
          component={EarlyCarStocks}
        />

        <RouteWithLayout
          path="/production/production-stop"
          exact
          layout={MainLayout}
          component={ProductionStop}
        />

        <RouteWithLayout
          path="/production/silo-mass-stocks"
          exact
          layout={MainLayout}
          component={SiloMassStocks}
        />

        <RouteWithLayout
          path="/production/silo-mass-stocks-by-formule"
          exact
          layout={MainLayout}
          component={SiloMassStocksByFormule}
        />

        <RouteWithLayout
          path="/production-stop-motives"
          exact
          layout={MainLayout}
          component={ProductionStopMotives}
        />

        <RouteWithLayout
          path="/general-setup"
          exact
          layout={MainLayout}
          component={GeneralSetup}
        />

        <RouteWithLayout
          path="/matrix"
          exact
          layout={MainLayout}
          component={Matrix}
        />
        <RouteWithLayout
          path="/matrix/box"
          exact
          layout={MainLayout}
          component={LifeCicleBox}
        />

        <RouteWithLayout
          path="/matrix/reliefs"
          exact
          layout={MainLayout}
          component={LifeCicleReliefs}
        />

        <RouteWithLayout
          path="/matrix/muratures"
          exact
          layout={MainLayout}
          component={LifeCicleMuratures}
        />

        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/production-data"
          component={ProductionData}
        />

        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/production-performance"
          component={ProductionPerformance}
        />

        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/birthdays"
          component={Birthdays}
        />

        <RouteWithLayout
          exact
          hideLayout={true}
          layout={MainLayout}
          path="/producaodescarte"
          component={SlideShowDashboard}
        />

        <RouteWithLayout 
          exact
          hideLayout={true}
          layout={MainLayout}
          path="/eficienciaequalidade"
          component={EfficiencyAndQuality}
        />

        <RouteWithLayout
          exact
          hideLayout={false}
          layout={MainLayout}
          path="/visual-analysis"
          component={VisualAnalysisOSA}
        />

        <RouteWithLayout
          exact
          hideLayout={false}
          layout={MainLayout}
          path="/new-reports"
          component={NewReports}
        />

        <Route path="/login" exact component={Login} />

        <RouteWithLayout exact layout={MainLayout} component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
