import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, Link } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#f9f9fc',
    bottom: '0px',
    paddingLeft: '10px',
    paddingBottom: '5px',
  },
}));

const Footer = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const today = new Date();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        &copy;{' '}
        <Link component="a" href="http://www.iforth.net" target="_blank">
          iForth
        </Link>
        . {today.getFullYear()}
      </Typography>
      <Typography variant="caption">Sistemas Inteligentes</Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
