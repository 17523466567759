import React, { useState, useEffect } from 'react';
import { Flex } from 'rebass';
import { TextField } from '@material-ui/core';
import { Box, Grid, Button, Divider, Typography } from '@material-ui/core';
import { ArrowForward, ArrowBackSharp } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import api from '../../../../../../../services/api';
import { useStyles } from './styles';

const Carrier = (props) => {
  const classes = useStyles();
  const { nextStep, prevStep, showroom, token } = props;
  const [carriers, setCarriers] = useState([]);

  const [state, setState] = useState({
    carrier: showroom.carrier,
  });

  const { carrier } = state;

  const [hasCarrier, setHasCarrier] = useState(true);

  useEffect(() => {
    async function fetch() {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(`/carriers`);
      setCarriers(resp.data);
    }
    fetch();
  }, [token]);

  const handleChange = (event, item) => {
    if (item) {
      let name = event.target.id.split('-', 1)[0];
      setState({ ...state, [name]: Number(item.value) });
      showroom[name] = item;
      verifyFields(name);
    }
  };

  const verifyFields = (type) => {
    if (type === 'carrier') {
      !showroom.carrier ? setHasCarrier(false) : setHasCarrier(true);
    }
  };

  const verifyAllFields = () => {
    !showroom.carrier ? setHasCarrier(false) : setHasCarrier(true);
  };

  const continues = (e) => {
    if (showroom.carrier) {
      nextStep();
    } else {
      verifyAllFields();
    }
  };

  const back = (e) => {
    prevStep();
  };

  const carrierProps = {
    options:
      carriers &&
      carriers.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  return (
    <>
      <Box>
        <div className={classes.row}>
          <Typography pt={5} variant="h5" component="h3">
            <Flex justifyContent="center">Informações de Transporte</Flex>
          </Typography>
          <Autocomplete
            {...carrierProps}
            id="carrier"
            name="carrier"
            value={carrier}
            onChange={(event, newValue) => handleChange(event, newValue)}
            clearOnEscape
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!hasCarrier}
                label="Tipo"
                margin="normal"
                fullWidth
              >
                {' '}
                Não encontrado
              </TextField>
            )}
          />
        </div>
      </Box>
      <Box>
        <Divider />
        <div className={classes.bottom}>
          <Grid
            justify="space-between"
            container
            direction="row"
            className={classes.buttons}
            alignItems="flex-end"
          >
            <Button
              variant="outlined"
              onClick={back}
              startIcon={<ArrowBackSharp />}
              className={classes.button}
            >
              Voltar
            </Button>
            <Button
              variant="outlined"
              onClick={continues}
              endIcon={<ArrowForward />}
              className={classes.button}
            >
              Avançar
            </Button>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default Carrier;
