import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu as ExternalMenu } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ITEM_HEIGHT = 48;

const Item = styled.div`
  padding-left: 10px;
`;

const Menu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { options, width } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <ExternalMenu
        id="long-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        keepMounted
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: width,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => {
              option.handleClick();
              handleClose();
            }}
            disabled={option.disabled}
          >
            <FontAwesomeIcon size="1x" icon={option.icon} />
            <Item>{option.item}</Item>
          </MenuItem>
        ))}
      </ExternalMenu>
    </div>
  );
};

export default Menu;
