const Option = (title, subtext, optData, dataNames) => {
  let width = window.screen.width;

  const smLegend = {
    type: 'scroll',
    orient: 'vertical',
    left: 0,
    top: '55%',
    bottom: 20,
    data: dataNames,
  };
  const mdlegend = {
    orient: 'horizontal',
    left: 0,
    top: '70%',
    data: dataNames,
  };

  const lgLegend = {
    type: 'scroll',
    orient: 'vertical',
    right: 10,
    top: 110,
    bottom: 20,
    data: dataNames,
  };

  const handleLegend = () => {
    if (width <= 414) {
      return smLegend;
    } else if (width >= 415 && width <= 639) {
      return mdlegend;
    } else if (width >= 640 && width <= 1024) {
      return mdlegend;
    } else if (width >= 1024) {
      return lgLegend;
    }
  };

  const handleRadius = () => {
    if (width <= 414) {
      return ['20%', '40%'];
    } else if (width >= 415 && width <= 639) {
      return ['25%', '45%'];
    } else if (width >= 640 && width <= 1024) {
      return ['25%', '45%'];
    } else {
      return ['35%', '65%'];
    }
  };
  const handleCenter = () => {
    if (width <= 414) {
      return ['50%', '35%', '50%', '50%'];
    } else if (width >= 415 && width <= 639) {
      return ['50%', '40%', '50%', '50%'];
    } else if (width >= 640 && width <= 1024) {
      return ['50%', '40%', '50%', '50%'];
    } else {
      return ['40%', '50%', '50%', '50%'];
    }
  };

  return {
    tooltip: {
      trigger: 'item',
      formatter: '{a}<br/>{b} <br/><strong>{d}%</strong>',
    },
    title: {
      text: title,
      subtext: subtext,
      left: 'center',
      top: '10px',
    },
    calculable: true,
    legend: handleLegend(),
    series: [
      {
        name: 'Ranking',
        type: 'pie',

        radius: handleRadius(),
        center: handleCenter(),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
          label: {
            show: true,
            fontSize: '22',
          },
        },
        label: {
          position: 'center',
          normal: {
            show: true,
            formatter: '{d}%',
            fontSize: '15',
            fontWeight: 'bold',
          },
        },

        itemStyle: {
          shadowBlur: 100,
          shadowColor: 'rgba(0, 0, 0, 0.1)',
        },

        animationType: 'expansion',
        animationDuration: 10000,
        animationEasing: 'elasticOut',
        animationTypeUpdate: 'expansion',
        animationDelay: function (idx) {
          return Math.random() * 400;
        },
        data: optData,
      },
    ],
  };
};

export default Option;
