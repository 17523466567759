import React, { useState } from 'react';
import { Flex } from 'rebass';
import {
  Grid,
  Button,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';

import { ArrowForward, ArrowBackSharp } from '@material-ui/icons';
import { useStyles } from './styles';

const Roominess = props => {
  const classes = useStyles();
  const { nextStep, prevStep, showroom } = props;

  const [hasForeheadDepth, setHasForeheadDepth] = useState(true);
  const [hasHeight, setHasHeight] = useState(true);
  const [hasWidth, setHasWidth] = useState(true);
  const [hasLength, setHasLength] = useState(true);
  const [hasForeheadHeight, setHasForeheadHeight] = useState(true);

  const [state, setState] = useState({
    width: showroom.width,
    height: showroom.height,
    length: showroom.length,
    forehead_depth: showroom.forehead_depth,
    forehead_height: showroom.forehead_height,
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: Number(event.target.value) });
    showroom[name] = event.target.value;
  };

  const { height, width, length, forehead_height, forehead_depth } = state;

  const verifyFields = hande => {
    if (!height) {
      setHasHeight(false);
    } else {
      setHasHeight(true);
    }

    if (!width) {
      setHasWidth(false);
    } else {
      setHasWidth(true);
    }

    if (!length) {
      setHasLength(false);
    } else {
      setHasLength(true);
    }

    if (!forehead_height) {
      setHasForeheadHeight(false);
    } else {
      setHasForeheadHeight(true);
    }

    if (!forehead_depth) {
      setHasForeheadDepth(false);
    } else {
      setHasForeheadDepth(true);
    }
  };

  const continues = e => {
    if (height && width && length && forehead_height && forehead_depth) {
      nextStep();
    } else {
      verifyFields();
    }
  };

  const back = e => {
    prevStep();
  };

  return (
    <>
      <div className={classes.row}>
        <Typography variant="h5" component="h3">
          <Flex justifyContent="center">Espaço Disponbilizado</Flex>
        </Typography>
        <Grid container spacing={(1, 2)}>
          <Grid item sm={4} xs={6}>
            <TextField
              id="height"
              type="number"
              value={height}
              label="Altura (H)"
              margin="normal"
              fullWidth
              className={classes.textField}
              onChange={handleChange('height')}
              error={!hasHeight}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              id="width"
              className={classes.textField}
              onChange={handleChange('width')}
              value={width}
              type="number"
              label="Largura (L)"
              margin="normal"
              fullWidth
              error={!hasWidth}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              id="length"
              className={classes.textField}
              type="number"
              value={length}
              onChange={handleChange('length')}
              label="Comprimento (C)"
              margin="normal"
              fullWidth
              error={!hasLength}
            />
          </Grid>
          <Grid item sm={6} md={6} xs={6}>
            <TextField
              id="forehead_height"
              type="number"
              onChange={handleChange('forehead_height')}
              label="Testeira Altura (TA)"
              margin="normal"
              fullWidth
              value={forehead_height}
              className={classes.textField}
              error={!hasForeheadHeight}
            />
          </Grid>
          <Grid item sm={6} md={6} xs={6}>
            <TextField
              id="forehead_depth"
              type="number"
              onChange={handleChange('forehead_depth')}
              value={forehead_depth}
              label="Testeira Profundidade (TP)"
              margin="normal"
              fullWidth
              className={classes.textField}
              error={!hasForeheadDepth}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <img
            className={classes.img}
            src="/images/room.png"
            alt="instruction"
          />
        </Grid>
      </div>
      <div className={classes.bottom}>
        <Divider />
        <Grid
          container
          direction="row"
          className={classes.buttons}
          alignItems="flex-end"
          justify="space-between"
        >
          <Button
            variant="outlined"
            onClick={back}
            startIcon={<ArrowBackSharp />}
            className={classes.button}
          >
            Voltar
          </Button>
          <Button
            variant="outlined"
            onClick={continues}
            endIcon={<ArrowForward />}
            className={classes.button}
          >
            Avançar
          </Button>
        </Grid>
      </div>
    </>
  );
};

export default Roominess;
