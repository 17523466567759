import React from 'react';
import { Flex } from 'rebass';
import {
  Grid,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
  ListItemText,
} from '@material-ui/core';
import { Save, ArrowBackSharp } from '@material-ui/icons';
import api from '../../../../../../../services/api';
import { useStyles } from './styles';

const Ending = props => {
  const classes = useStyles();
  const {
    token,
    showroom,
    prevStep,
    handleClose,
    handleOpenNotification,
  } = props;

  const showroomData = {
    agent_id: showroom.agent.value,
    customer_id: showroom.customer.value,
    showroom_type_id: showroom.showroomType.value,
    labor_type_id: showroom.laborType.value,
    project_type_id: showroom.projectType.value,
    payment_method_id: showroom.payment_method_id,
    carrier_id: showroom.carrier.value,
    rule_id: showroom.rule_id,
    user_id: showroom.user_id,
    customer_obs: showroom.customer_obs,
    showroom_obs: showroom.showroom_obs,
    cif_shipping: showroom.cif_shipping,
    fob_shipping: showroom.fob_shipping,
    height: showroom.height,
    width: showroom.width,
    length: showroom.length,
    forehead_height: showroom.forehead_height,
    forehead_depth: showroom.forehead_depth,
    total_height: showroom.total_height,
    total_square_feet_metters: showroom.total_square_feet_metters,
    pilar: showroom.pilar,
    fire_extinguisher: showroom.fire_extinguisher,
    forehead: showroom.forehead,
    circuit_breaker: showroom.circuit_breaker,
    window_or_door: showroom.window_or_door,
    beam: showroom.beam,
    other_items: showroom.other_items,
    mix: showroom.mix,
    product_sugestion: showroom.product_sugestion,
    manager_obs: showroom.manager_obs,
    start_date: showroom.start_date,
    end_date: showroom.end_date,
    budget_limit: showroom.budget_limit,
    flag: showroom.flag,
    flag_description: showroom.flag_description,
  };

  const save = async e => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resp = await api.post(`/showrooms`, showroomData);
    if (resp && resp.data) {
      handleClose();
      handleOpenNotification();
    }
  };

  const back = e => {
    prevStep();
  };

  return (
    <>
      <div className={classes.row}>
        <Typography className={classes.tipografy} variant="h5" component="h3">
          <Flex justifyContent="center">Verifique os dados Informados</Flex>
        </Typography>
        <List
          className={classes.list}
          component="nav"
          aria-label="main mailbox folders"
        >
          <ListItem button>
            <ListItemText
              primary="Solicitante"
              secondary={showroom.agent.title}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Cliente"
              secondary={showroom.customer.title}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Observações do Cliente"
              secondary={showroom.customer_obs}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Transportadora"
              secondary={showroom.carrier.title}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Showroom"
              secondary={<span> Tipo: {showroom.showroomType.title} </span>}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Mão de Obra"
              secondary={<span> Tipo: {showroom.laborType.title} </span>}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Projeto"
              secondary={<span> Tipo: {showroom.projectType.title} </span>}
            />
          </ListItem>

          <ListItem button>
            <ListItemText
              primary="Espaço Disponibilizado"
              secondary={
                <span>
                  <span className={classes.roominessBlock}>
                    <span>Altura (H): {showroom.height}</span>
                    <span>Largura (L): {showroom.width}</span>
                    <span>Comprimento (C): {showroom.length}</span>
                  </span>
                  <span className={classes.roominessBlock}>
                    <span>
                      Testeira Altura (TA): {showroom.forehead_height}
                    </span>
                    <span>
                      Testeira Profundidade (TP): {showroom.forehead_depth}
                    </span>
                  </span>
                </span>
              }
            />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Espaço Possui" secondary={showroom.mix} />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Mix de Produtos da Loja"
              secondary={showroom.mix}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Sugestão de Produtos para Exposição nesse Showroom"
              secondary={showroom.product_sugestion}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Observações Gerente Regional / Nacional"
              secondary={showroom.manager_obs}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Observações Gerais do Showroom"
              secondary={showroom.showroom_obs}
            />
          </ListItem>
        </List>
      </div>
      <div className={classes.bottom}>
        <Divider />
        <Grid className={classes.buttons} container justify="space-between">
          <Button
            variant="outlined"
            onClick={back}
            startIcon={<ArrowBackSharp />}
            className={classes.button}
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            onClick={save}
            color="primary"
            endIcon={<Save />}
            className={classes.button}
          >
            Salvar
          </Button>
        </Grid>
      </div>
    </>
  );
};

export default Ending;
