import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Grid,
  Paper,
  Snackbar,
  Typography,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import 'react-circular-progressbar/dist/styles.css';
import {
  faEye,
  faPlusCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import { Box, Flex } from 'rebass';

import api from '../../../services/api';
import { formatDate } from '../../../utils/format';
import { handleParams } from '../../../utils/handleParams';

import Menu from '../../../components/Menu';
import Modal from '../../../components/Modal';
import Header from '../../../components/Header';
import Status from '../../../components/Status';
import Paginate from '../../../components/Paginate';
import NotificationContent from '../../../components/Notification';

import Filter from './Filter';
import AddProvisionsModal from './AddProvisionsModal';
import ViewProvisionsModal from './ViewProvisionsModal';
import NonComplienceModal from './NonComplienceModal';
import { useStyles } from '../../styles';

import styled from 'styled-components';

const StyledGrid = styled(Grid)`
  padding: 5px;
  ${(props) => props.display && `display: ${props.display};`}
  ${(props) =>
    props.justifyContent && `justify-content: ${props.justifyContent};`}
`;

const DEFAULT_SUCCESS = {
  open: true,
  message: 'Operação realizada com sucesso!',
  variant: 'success',
};

const DEFAULT_ERROR = {
  open: true,
  message: 'Não foi possível realizar esta operação!',
  variant: 'error',
};

const SafetyMachines = () => {
  const classes = useStyles();
  const unity_id = localStorage.getItem('@icep-app/unity');
  const [filterParams] = useState({});
  const [page, setPage] = useState(0);
  const [detailId, setDetailId] = useState();
  const [finalizeDialogId, setFinalizeDialogId] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [progress, setProgress] = useState(true);
  const [openFinalizeDialog, setOpenFinalizeDialog] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    variant: 'success',
  });
  const [totalPages, setTotalPages] = useState(0);
  const [msiReolutionsData, setMsiReolutionsData] = useState([]);

  const [openProvisionsModal, setOpenProvisionsModal] = useState(false);
  const [openNnonComplienceModal, setOpenNonComplienceModal] = useState(false);
  const [openAddProvisionsModal, setOpenAddProvisionsModal] = useState(false);

  const [params, setParams] = useState({
    flag: '0,1',
    end_date: '',
    machine_id: '',
    start_date: '',
  });

  const handleFilter = (item) => {
    let machine_id = item?.selectMachines || params?.machine_id;
    let flag = item?.flag || params?.flag;
    let end_date = item?.endDate || params?.end_date;
    let start_date = item?.startDate || params?.start_date;

    setPage(0);

    setParams({
      machine_id,
      flag,
      end_date,
      start_date,
    });
  };

  const fetchData = useCallback(async () => {
    setProgress(true);

    const resp = await api.get(
      `/msiresolutions/unity/${unity_id}${handleParams(params)}&page=${
        page + 1
      }`,
    );

    setTotalRows(resp.data.metadata.totalRows);
    setTotalPages(resp.data.metadata.totalPages);
    setMsiReolutionsData(resp.data.source);
    setProgress(false);
  }, [page, params, unity_id]);

  useEffect(() => {
    async function fetch() {
      fetchData();
    }
    fetch();
  }, [fetchData]);

  const handleClear = async () => {
    setPage(0);

    setParams({
      flag: '0,1',
      end_date: '',
      machine_id: '',
      start_date: '',
    });
  };

  const handleCloseAddProvisions = () => {
    setOpenAddProvisionsModal(false);
    fetchData();
  };

  const handleCloseProvisions = () => {
    setOpenProvisionsModal(false);
  };

  const handleCloseNonComplience = () => {
    setOpenNonComplienceModal(false);
  };

  const handleAddProvisions = async (id) => {
    setDetailId(id);
    setOpenAddProvisionsModal(true);
  };

  const handleProvisions = async (id) => {
    setDetailId(id);
    setOpenProvisionsModal(true);
  };

  const handleNonComplience = async (id) => {
    setDetailId(id);
    setOpenNonComplienceModal(true);
  };

  const handleFinalize = async (id) => {
    setFinalizeDialogId(id);
    setOpenFinalizeDialog(true);
  };

  const handleCloseFinalizeDialog = () => {
    setFinalizeDialogId();
    setOpenFinalizeDialog(false);
  };

  const handleConfirmFinalizeDialog = async () => {
    try {
      const res = await api.post(`msiresolutions/${finalizeDialogId}/finalize`);

      if (res && res.status === 200) {
        setNotification(DEFAULT_SUCCESS);
        setOpenFinalizeDialog(false);
        setFinalizeDialogId();
        fetchData();
      } else {
        setNotification(DEFAULT_ERROR);
      }
    } catch (e) {
      setNotification(DEFAULT_ERROR);
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification({
      open: false,
      message: '',
      variant: 'success',
    });
  };

  const onPageChange = async (item) => {
    setPage(item.selected);
  };

  const getStatus = (item) => {
    let flag = null;
    switch (item) {
      case 0:
        flag = 9;
        break;
      case 1:
        flag = 10;
        break;
      case 2:
        flag = 11;
        break;
      default:
    }

    return flag;
  };

  return (
    <>
      <Grid className={classes.qualityRoot}>
        <Grid alignItems="flex-end" container justify="space-between">
          <Header
            title="Segurança"
            subtitle="Resolução de Itens de Segurança"
          />

          <Filter
            label="Relevos"
            onClear={handleClear}
            endpoint="/msiresolutions"
            onFilter={handleFilter}
            className={classes.filterButton}
            filterParams={filterParams}
            isDateRequired={false}
          />
        </Grid>
        {progress ? (
          <Paper className={classes.paper}>
            <Box container justifyContent="center" alignItems="center">
              <Flex justifyContent="center" alignItems="center">
                <CircularProgress
                  className={classes.circularProgress}
                  color="secondary"
                />
              </Flex>
            </Box>
          </Paper>
        ) : (
          <div className={classes.results}>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Total de {totalRows} registros econtrados. Pagina {page + 1} de{' '}
              {totalPages}
            </Typography>
            {msiReolutionsData.length > 0 ? (
              msiReolutionsData.map((row) => (
                <Card key={row.id} className={classes.projectCard}>
                  <CardContent className={classes.content}>
                    <StyledGrid item lg={1} xs={6}>
                      <Typography variant="body2">Código</Typography>
                      <Typography variant="h6">{row.id}</Typography>
                    </StyledGrid>

                    <StyledGrid item lg={1} xs={6}>
                      <Typography variant="body2">Data de Abertura</Typography>
                      <Typography variant="h6">
                        {formatDate(row.open_date)}
                      </Typography>
                    </StyledGrid>

                    <StyledGrid item lg={2} xs={6}>
                      <Typography variant="body2">Máquina</Typography>
                      <Typography variant="h6">
                        {' '}
                        {row.machine.description}
                      </Typography>
                    </StyledGrid>

                    <StyledGrid item lg={2} xs={6}>
                      <Typography variant="body2">Local de trabalho</Typography>
                      <Typography variant="h6">
                        {' '}
                        {row.machine_workplace.description}
                      </Typography>
                    </StyledGrid>

                    <StyledGrid item lg={1} xs={6}>
                      <Typography variant="body2">Item</Typography>
                      <Typography variant="h6">
                        {' '}
                        {row.form_subgroup.description}
                      </Typography>
                    </StyledGrid>

                    <StyledGrid item lg={2} xs={6}>
                      <Typography variant="body2">Não conformidade</Typography>
                      <Typography variant="h6">{row.description}</Typography>
                    </StyledGrid>

                    <StyledGrid
                      item
                      lg={2}
                      xs={6}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Status type={getStatus(row.flag)}></Status>
                    </StyledGrid>

                    <StyledGrid
                      item
                      lg={1}
                      xs={6}
                      key={row.id}
                      className={classes.actions}
                    >
                      <Menu
                        width={200}
                        options={[
                          {
                            id: '1',
                            item: 'Adicionar Disposições',
                            icon: faPlusCircle,
                            disabled: row.flag === 2,
                            handleClick: () => handleAddProvisions(row.id),
                          },
                          {
                            id: '2',
                            item: 'Ver Disposições',
                            icon: faEye,
                            handleClick: () => handleProvisions(row.id),
                          },
                          {
                            id: '3',
                            item: 'Ver Não Conformidade',
                            icon: faEye,
                            handleClick: () => handleNonComplience(row.id),
                          },
                          {
                            id: '4',
                            item: 'Finalizar',
                            icon: faCheckCircle,
                            color: 'red',
                            disabled: row.flag !== 1,
                            handleClick: () => handleFinalize(row.id),
                          },
                        ]}
                      />
                    </StyledGrid>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Card className={classes.container}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="body2"
                  className={classes.circularProgress}
                >
                  Sem dados
                </Typography>
              </Card>
            )}
          </div>
        )}
        <div className={classes.paginate}>
          <Paginate
            pageCount={totalPages}
            onPageChange={onPageChange}
            pageRangeDisplayed={1}
          />
        </div>
      </Grid>

      <Dialog
        open={openFinalizeDialog}
        onClose={handleCloseFinalizeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Deseja finalizar este registro?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao finalizar este registro não será possível acrescentar novas
            disposições e esta operação não poderá ser desfeita. Deseja
            prosseguir?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFinalizeDialog} color="primary">
            Cancelar
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={handleConfirmFinalizeDialog}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <NotificationContent
          onClose={handleCloseNotification}
          variant={notification.variant}
          message={notification.message}
        />
      </Snackbar>

      <Modal
        title="Adicionar Disposições"
        open={openAddProvisionsModal}
        handleClose={handleCloseAddProvisions}
        component={
          <AddProvisionsModal
            item={detailId}
            handleClose={handleCloseAddProvisions}
            handleFail={() => setNotification(DEFAULT_ERROR)}
            handleSuccess={() => setNotification(DEFAULT_SUCCESS)}
          />
        }
      />

      <Modal
        title="Vizualizar"
        subheader="Disposições"
        open={openProvisionsModal}
        handleClose={handleCloseProvisions}
        component={<ViewProvisionsModal item={detailId} />}
      />

      <Modal
        title="Vizualizar"
        subheader="Não Conformidades"
        open={openNnonComplienceModal}
        handleClose={handleCloseNonComplience}
        component={<NonComplienceModal item={detailId} />}
      />
    </>
  );
};
export default SafetyMachines;
