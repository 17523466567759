export const isAuthenticated = () => {
  const idUser = localStorage.getItem('@icep-app/iduser');
  const username = localStorage.getItem('@icep-app/username');
  const idProfile = localStorage.getItem('@icep-app/idprofile');
  const profileItems = localStorage.getItem('@icep-app/profileitems');
  const token = localStorage.getItem('@icep-app/token');

  const unity = localStorage.getItem('@icep-app/unity');

  return (
    idUser &&
    idUser !== 0 &&
    idProfile &&
    idProfile !== 0 &&
    profileItems &&
    username &&
    username !== '' &&
    unity &&
    token
  );
};

export const doLogout = () => {
  localStorage.removeItem('@icep-app/unity');

  window.location.reload();
};

export const destroyLocalSession = () => {
  localStorage.removeItem('@icep-app/iduser');
  localStorage.removeItem('@icep-app/idprofile');
  localStorage.removeItem('@icep-app/username');
  localStorage.removeItem('@icep-app/user');
  localStorage.removeItem('@icep-app/profileitems');

  window.location.reload();
};
