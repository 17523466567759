import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Filter from '../../../components/Filter';
import {
  Grid,
  Card,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Box, Flex } from 'rebass';
import PieChart from '../../../components/PieChart';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  top: {
    flexGrow: 1,
    backgroundColor: 'text',
    paddingBottom: '15px',
  },
  title: {
    paddingLeft: '40px',
    paddingTop: '25px',
    flexGrow: 1,
    backgroundColor: '#f9f9fc',
  },
  container: {
    marginTop: theme.spacing(3),
  },
  barChartIcon: {
    marginRight: theme.spacing(1),
  },
  chart: {
    margin: '15px !important',
  },
  circularProgress: {
    margin: '20px',
  },
}));

const ProductionStopMotives = () => {
  const classes = useStyles();
  const [filterParams, setFilterParams] = useState({});
  const [data, setData] = useState([]);
  const [lines, setLines] = useState([]);
  const [period, setPeriod] = useState('');
  // const [selectedLine, setSelectedLine] = useState();
  const [selectedLines, setSelectedLines] = useState('');
  const unity = localStorage.getItem('@icep-app/unity');
  const [progress, setProgress] = useState(true);
  const [subtext, setSubtext] = useState('Este mês');
  const idUser = localStorage.getItem('@icep-app/iduser');

  const params = {
    period: 'thisMonth',
    // selectedLine: selectedLine,
    selectedLines: selectedLines,
  };

  useEffect(() => {
    async function fetchData() {
      if (!!selectedLines) {
        const resp = await api.get(
          `/productionstopmotives/unity/${unity}/line/${selectedLines}/motives/count/${
            period !== 'thisMonth' ? period : ''
          }`,
        );
        setData(resp.data);
      }
    }

    fetchData();
    setProgress(false);
  }, [period, selectedLines, unity]);

  useEffect(() => {
    async function handleGetLines() {
      const resp = await api.get(`/userlines/${idUser}/unity/${unity}/lines`);
      setLines(resp.data);
      setSelectedLines(resp?.data?.line_id);
    }
    handleGetLines();
  }, [idUser, unity]);

  const handleSubText = (item) => {
    switch (item) {
      case 'today':
        setSubtext('Hoje');
        break;
      case 'yesterday':
        setSubtext('Ontem');
        break;
      case 'thisMonth':
        setSubtext('Este Mês');
        break;

      default:
        break;
    }
  };

  const getLine = (id) => {
    if (!!selectedLines && selectedLines !== '') {
      if (selectedLines.split(',').length > 1) {
        return 'Múltiplas linhas selecionadas';
      } else {
        var filtered = lines.filter((item) => {
          return item.line_id === parseInt(id);
        });
        return filtered[0]?.line?.description;
      }
    } else {
      return 'Nenhuma linha selecionada';
    }
  };

  const handleFilter = async (item) => {
    handleSubText(item.period);
    setPeriod(item.period ? item.period : params.period);
    // let line_id =
    //   item.selectedLine && item.selectedLine.value
    //     ? item.selectedLine.value
    //     : params.selectedLine;

    let line_ids = item?.selectedLines
      ? item?.selectedLines
      : params.selectedLines;

    // setSelectedLine(line_id);
    setSelectedLines(line_ids);
  };

  const handleClear = async (item) => {
    setFilterParams({ period: filterParams.period });
    setSelectedLines('');
  };

  return (
    <div className={classes.root}>
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            Ranking de Parada
          </Typography>
          <Typography component="h1" variant="h3">
            Mês e Dia
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {getLine(selectedLines)}
          </Typography>
        </Grid>
        <Grid item>
          <Filter
            onClear={handleClear}
            onFilter={handleFilter}
            className={classes.filterButton}
            filterParams={filterParams}
            showMultipleLines={true}
            showLine={false}
          />
        </Grid>
      </Grid>

      {progress ? (
        <Paper className={classes.container}>
          <Box container justifyContent="center" alignItems="center">
            <Flex justifyContent="center" alignItems="center">
              <CircularProgress
                color="secondary"
                className={classes.circularProgress}
              />
            </Flex>
          </Box>
        </Paper>
      ) : (
        <>
          <Grid className={classes.container} justify="space-between" container>
            <Grid item lg={12} xl={12} xs={12}>
              {data && data.length > 0 ? (
                <Card>
                  <PieChart
                    title="Ranking de Parada"
                    subtext={subtext}
                    data={data}
                  />
                </Card>
              ) : (
                <Card>
                  <Typography
                    align="center"
                    color="textPrimary"
                    variant="body2"
                    className={classes.circularProgress}
                  >
                    Sem dados
                  </Typography>
                </Card>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default ProductionStopMotives;
