import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  top: {
    flexGrow: 1,
    backgroundColor: 'text',
    paddingBottom: '15px',
  },
  title: {
    paddingLeft: '40px',
    paddingTop: '25px',
    flexGrow: 1,
    backgroundColor: '#f9f9fc',
  },
  container: {
    marginTop: theme.spacing(3),
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header title="Página não encontrada" subtitle="" description="" />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={4} sm={6} xs={12}>
          <h1>404</h1>
          <h1>Página não encontrada.</h1>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
