import React, { useState } from 'react';
import api from '../../../services/api';

import { Flex } from 'rebass';
import { Grid, Button, TextField, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },

  fab: {
    right: theme.spacing(1),
    position: 'relative',
  },
}));

const AddProvisionsModal = ({
  handleClose,
  handleFail,
  handleSuccess,
  ...props
}) => {
  const classes = useStyles();
  const idUser = localStorage.getItem('@icep-app/iduser');
  const [description, setDescription] = useState('');
  const [hasDescriptionError, setHasDescriptionError] = useState(false);
  const validateFields = () => {
    setHasDescriptionError(!description || description.trim() === '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateFields();

    try {
      const res = await api.post('msiresolutiondispositions', {
        user_id: idUser,
        msi_resolution_id: props.item,
        description: description,
      });

      if (res && res.status === 200) {
        handleSuccess && handleSuccess();
        handleClose && handleClose();
      } else {
        handleFail && handleFail();
      }
    } catch (e) {
      handleFail && handleFail();
    }
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <FormControl error={hasDescriptionError} fullWidth>
          <TextField
            id="description"
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            fullWidth
            multiline
            rows={20}
            variant="outlined"
            aria-describedby="attatchment-description"
          />
        </FormControl>
        <br />
        <br />
      </Grid>
      <Grid item xs={12}>
        <Flex justifyContent="flex-end">
          <Button type="submit" color="primary" variant="contained">
            Adicionar
          </Button>
        </Flex>
      </Grid>
    </form>
  );
};

export default AddProvisionsModal;
