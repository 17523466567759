import React from "react";
import PageLayout from "../../components/PageLayout";
import Overview from "./components/Overview";
import Progress from "./components/Progress";

const tabs = [
  {
    index: 0,
    title: "Visão Geral",
    content: <Overview />
  },
  {
    index: 1,
    title: "Andamento",
    content: <Progress />
  }
];

const ShowRoom = () => {
  return <PageLayout title="Showroom" tabs={tabs} />;
};

export default ShowRoom;
