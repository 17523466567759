export function handleParams(params) {
  params = params || {};

  const url = () => {
    let paramStr = Object.keys(params)
      .filter((k) => !!params[k])
      .map((k) => `${k}=${params[k]}`)
      .join('&');

    return `?${paramStr}`;
  };
  return url();
}
