import React from 'react';
import palette from '../../theme/palette';
import styled from 'styled-components';
import Chip from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faCheck,
  faStopCircle,
  faCheckDouble,
  faExclamation,
  faHandsHelping,
  faFileContract,
  faHourglassHalf,
  faHandHoldingUsd,
  faComment,
} from '@fortawesome/free-solid-svg-icons';

const Icon = styled.span`
  font-size: 14px;
  padding-right: 5px;
  padding-top: 2px;
`;

const Text = styled.span`
  font-size: 12px;
`;

const getStatusColor = (type) =>
  ({
    0: palette.text,
    1: palette.info,
    2: palette.info,
    3: palette.primary,
    4: palette.primary,
    5: palette.info,
    6: palette.success,
    7: palette.warning,
    8: palette.error,
    9: palette.warning,
    10: palette.primary,
    11: palette.success,
  }[type]);
const getText = (type) =>
  ({
    0: 'Cadastrado',
    1: 'Em Aprovação',
    2: 'Em Projeto',
    3: 'Faturar',
    4: 'Executar',
    5: 'Em Execução',
    6: 'Executado',
    7: 'Executado com Pendência',
    8: 'Suspenso',
    9: 'Pendente',
    10: 'Em andamento',
    11: 'Finalizado',
  }[type] || '');

const getIcon = (type) =>
  ({
    0: <FontAwesomeIcon icon={faPen} />,
    1: <FontAwesomeIcon icon={faHandsHelping} />,
    2: <FontAwesomeIcon icon={faFileContract} />,
    3: <FontAwesomeIcon icon={faHandHoldingUsd} />,
    4: <FontAwesomeIcon icon={faCheck} />,
    5: <FontAwesomeIcon icon={faHourglassHalf} />,
    6: <FontAwesomeIcon icon={faCheckDouble} />,
    7: <FontAwesomeIcon icon={faExclamation} />,
    8: <FontAwesomeIcon icon={faStopCircle} />,
    9: <FontAwesomeIcon icon={faExclamation} />,
    10: <FontAwesomeIcon icon={faComment} />,
    11: <FontAwesomeIcon icon={faCheck} />,
  }[type] || '');

const Status = React.memo(({ type, ...props }) => {
  return (
    <>
      <Chip
        size="medium"
        variant="outlined"
        color={getStatusColor(type, false)}
      >
        <Icon>{getIcon(type)}</Icon>
        <Text>{getText(type)}</Text>
      </Chip>
    </>
  );
});

export default Status;
