import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const ErrorMessage = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  background-color: #f8d7da;
  color: #721c24;
  padding: 20px;
  border-radius: 3px;
  overflow-wrap: break-word;
  ${props => (props.display ? `display: ${props.display};` : ``)}
`;

export const UserBox = styled.div`
  margin: 0px;
  display: flex;
  border-radius: 3px;
  width: 100%;
  background-color: #fafafa;
  padding: 20px;

  h1 {
    margin-bottom: 0px !important;
  }

  small {
    color: #999;
  }

  div {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px !important;
  }
`;

export const AnotherAccountLink = styled.div`
  width: 100%;
  cursor: pointer;
`;
