import React from "react";
import PageLayout from "../../components/PageLayout";
import WorkInProgress from "../../components/WorkInProgress";

const tabs = [
  {
    index: 0,
    title: "Visão Geral",
    content: <WorkInProgress />
  }
];

const GeneralSetup = () => {
  return <PageLayout title="Configurações Gerais" tabs={tabs} />;
};

export default GeneralSetup;
