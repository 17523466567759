import React from 'react';
import NumberFormat from 'react-number-format';
import { Card, Text, Container } from '../../../styles/components';
import { Grid } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie,
  faChartLine,
  faCalendar,
  faCalendarDay,
} from '@fortawesome/free-solid-svg-icons';

const ProductionDiscard = ({ productions, ...props }) => {
  return (
    <div>
      <br />
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12} xs={12}>
          <Card width="100%" minWidth="120px" margin="0px">
            <Container>
              <Text
                fontSize="24px"
                fontWeight={500}
                color="#595d6e"
                fontFamily="Poppins"
              >
                <FontAwesomeIcon icon={faChartLine} color="#5d78ff" /> Produção
                mês
              </Text>
              <Text
                padding="10px 0px"
                fontSize="80px"
                fontWeight={700}
                fontFamily="Poppins"
                color="#5d78ff"
              >
                {productions && productions[0] ? (
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={Number(productions[0].month_production)}
                    displayType={'text'}
                  />
                ) : (
                  '#'
                )}{' '}
                m²
              </Text>
            </Container>
          </Card>
        </Grid>

        <Grid item lg={3} sm={12} xs={12}>
          <Card width="100%" minWidth="120px" margin="0px">
            <Container>
              <Text
                fontSize="24px"
                fontWeight={500}
                color="#595d6e"
                fontFamily="Poppins"
              >
                <FontAwesomeIcon icon={faCalendar} /> Qualidade mês
              </Text>
              <Text
                padding="10px 0px"
                fontSize="80px"
                fontWeight={700}
                fontFamily="Poppins"
                color="#93a2dd"
              >
                {productions && productions[0] ? (
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={Number(productions[0].month_quality)}
                    displayType={'text'}
                  />
                ) : (
                  '#'
                )}
                %
              </Text>
            </Container>
          </Card>
        </Grid>
        <Grid item lg={3} sm={12} xs={12}>
          <Card width="100%" minWidth="120px" margin="0px">
            <Container>
              <Text
                fontSize="24px"
                fontWeight={500}
                color="#595d6e"
                fontFamily="Poppins"
              >
                <FontAwesomeIcon icon={faCalendar} /> Eficiência mês
              </Text>
              <Text
                padding="10px 0px"
                fontSize="80px"
                fontWeight={700}
                fontFamily="Poppins"
                color="#93a2dd"
              >
                {productions && productions[0] ? (
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={Number(productions[0].month_efficiency)}
                    displayType={'text'}
                  />
                ) : (
                  '#'
                )}
                %
              </Text>
            </Container>
          </Card>
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <Card width="100%" minWidth="120px" margin="0px">
            <Container>
              <Text
                fontSize="24px"
                fontWeight={500}
                color="#595d6e"
                fontFamily="Poppins"
              >
                <FontAwesomeIcon icon={faChartPie} /> Produção dia
              </Text>
              <Text
                padding="10px 0px"
                fontSize="80px"
                fontWeight={700}
                fontFamily="Poppins"
                color="#0abb87"
              >
                {productions && productions[0] ? (
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={Number(productions[0].day_production)}
                    displayType={'text'}
                  />
                ) : (
                  '#'
                )}{' '}
                m²
              </Text>
            </Container>
          </Card>
        </Grid>
        <Grid item lg={3} sm={12} xs={12}>
          <Card width="100%" minWidth="120px" margin="0px">
            <Container>
              <Text
                fontSize="24px"
                fontWeight={500}
                color="#595d6e"
                fontFamily="Poppins"
              >
                <FontAwesomeIcon icon={faCalendarDay} /> Qualidade dia
              </Text>
              <Text
                padding="10px 0px"
                fontSize="80px"
                fontWeight={700}
                fontFamily="Poppins"
                color="#93a2dd"
              >
                {productions && productions[0] ? (
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={Number(productions[0].day_quality)}
                    displayType={'text'}
                  />
                ) : (
                  '#'
                )}
                %
              </Text>
            </Container>
          </Card>
        </Grid>
        <Grid item lg={3} sm={12} xs={12}>
          <Card width="100%" minWidth="120px" margin="0px">
            <Container>
              <Text
                fontSize="24px"
                fontWeight={500}
                color="#595d6e"
                fontFamily="Poppins"
              >
                <FontAwesomeIcon icon={faCalendarDay} /> Eficiência dia
              </Text>
              <Text
                padding="10px 0px"
                fontSize="80px"
                fontWeight={700}
                fontFamily="Poppins"
                color="#93a2dd"
              >
                {productions && productions[0] ? (
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={Number(productions[0].day_efficiency)}
                    displayType={'text'}
                  />
                ) : (
                  '#'
                )}
                %
              </Text>
            </Container>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductionDiscard;
