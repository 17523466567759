import { faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import api from '../../services/api';
import {
  Button,
  Content,
  Row,
  Select,
  Span,
  Text,
} from '../../styles/components';

import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;

  td,
  th {
    border: none;
    padding: 8px;
    font-size: 42px;
    text-align: center;
    font-weight: 600;
  }

  tr:nth-child(even) {
    background-color: #ddd;
  }
`;

const getHourOfDate = (date) => {
  if (!date) {
    return '--';
  }

  const splitedDate = date.split('T')[1].split(':');
  return splitedDate[0] + 'h';
};

export default function EfficiencyAndQuality() {
  const [unity, setUnity] = useState();
  const [qualityLine, setQualityLine] = useState();
  const [efficiencyLine, setEfficiencyLine] = useState();

  const [lines, setLines] = useState([]);
  const [unities, setUnities] = useState([]);
  const [defects, setDefects] = useState([]);
  const [showFilter, setShowFilter] = useState(true);

  const [qualityData, setQualityData] = useState([]);
  const [efficiencyData, setEfficiencyData] = useState([]);
  const [accumQualityData, setAccumQualityData] = useState([]);
  const [accumEfficiencyData, setAccumEfficiencyData] = useState([]);

  const efficiencyLineDescription = () => {
    if (!!lines && !!efficiencyLine) {
      const selected = lines.find((item) => item.id === Number(efficiencyLine));
      return selected?.description || '';
    }

    return '';
  };

  const qualityLineDescription = () => {
    if (!!lines && !!efficiencyLine) {
      const selected = lines.find((item) => item.id === Number(qualityLine));
      return selected?.description || '';
    }

    return '';
  };

  const getUnities = useCallback(async () => {
    const res = await api.get(`/unities`);

    if (!res.status || res.status !== 200 || !res.data[0].id) {
    } else {
      setUnities(res.data);
      setUnity(res.data[0].id);
    }
  }, []);

  const getLines = useCallback(async () => {
    const res = await api.get(`/lines/unity/${unity}`);

    if (!res.status || res.status !== 200) {
      return;
    } else {
      setLines(res.data);
      setQualityLine(res.data[0].id);
      setEfficiencyLine(res.data[0].id);
    }
  }, [unity]);

  const getData = useCallback(async () => {
    if (!!unity && !!efficiencyLine && !!qualityLine) {
      const res = await api.get(
        `/efficiencyandqualityperforms/v2/unity/${unity}?efficiency_line_id=${efficiencyLine}&quality_line_id=${qualityLine}`,
      );

      const defectsRes = await api.get(
        `/spcqualityitems/unity/${unity}}/line/${qualityLine}/defects/count/today`,
      );

      setQualityData(res.data?.quality);
      setEfficiencyData(res.data?.efficiency);

      setAccumEfficiencyData(res.data?.accumEfficiency);
      setAccumQualityData(res.data?.accumQuality);

      setDefects(defectsRes.data);
    }
  }, [efficiencyLine, qualityLine, unity]);

  useEffect(() => {
    getUnities();
  }, [getUnities]);

  useEffect(() => {
    if (!!unity) {
      getLines();
    }
  }, [getLines, unity]);

  useEffect(() => {
    if (!!qualityLine && !!efficiencyLine) {
      getData();
    }
  }, [efficiencyLine, getData, qualityLine]);

  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 60000);
    return () => clearInterval(interval);
  }, [getData]);

  return (
    <Content>
      <Row margin="10px 0px 0px 10px">
        <Span display="flex" flexDirection="column" width="33%">
          <Text fontSize="32px" fontWeight={600} fontFamily="Poppins">
            Eficiência e Qualidade
          </Text>
        </Span>

        <Span
          display="flex"
          flexDirection="column"
          width="34%"
          alignItems="center"
        >
          {showFilter && (
            <>
              <Select
                width="120px"
                margin="0px 0px 5px 0px"
                padding="3px"
                heigth="20px"
                required={false}
                onChange={(e) => setUnity(e.target.value)}
                type="text"
                name="unity"
                placeholder=""
                value={unity}
              >
                {unities &&
                  unities.map(
                    (item) =>
                      item.id !== 99 && (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ),
                  )}
              </Select>
              <span>Eficiência</span>
              <Select
                width="120px"
                margin="0px 0px 5px 0px"
                padding="3px"
                required={false}
                onChange={(e) => setEfficiencyLine(e.target.value)}
                type="text"
                name="efficiencyLine"
                placeholder=""
                value={efficiencyLine}
              >
                {lines &&
                  lines.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
              </Select>

              <span>Qualidade</span>
              <Select
                width="120px"
                margin="0px 0px 5px 0px"
                padding="3px"
                required={false}
                onChange={(e) => setQualityLine(e.target.value)}
                type="text"
                name="qualityLine"
                placeholder=""
                value={qualityLine}
              >
                {lines &&
                  lines.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
              </Select>
            </>
          )}
          <Button
            kind="outlined"
            padding="8px 5px"
            width="120px"
            margin="0px 0px 5px 0px"
            onClick={() => setShowFilter(!showFilter)}
          >
            <FontAwesomeIcon
              icon={showFilter ? faSearchMinus : faSearchPlus}
              color="#0069ff"
            />
          </Button>
        </Span>
        <Span
          display="flex"
          flexDirection="column"
          width="33%"
          alignItems="flex-end"
        >
          <Text
            padding="0px 20px 0px 0px"
            fontSize="24px"
            fontWeight={500}
            fontFamily="Poppins"
          >
            Qualidade: {qualityLineDescription()}
          </Text>
          <Text
            padding="0px 20px 0px 0px"
            fontSize="24px"
            fontWeight={500}
            fontFamily="Poppins"
          >
            Eficiência: {efficiencyLineDescription()}
          </Text>
        </Span>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <StyledTable style={{ width: '100%' }}>
          <thead>
            <tr>
              <th></th>
              <th>
                <Text fontFamily="Poppins">EFICIÊNCIA</Text>
              </th>
              <th>
                <Text fontFamily="Poppins">QUALIDADE</Text>
              </th>
            </tr>
          </thead>

          <tr style={{ height: 75, background: '#0abb87' }}>
            <td style={{ fontSize: 64 }}>
              <Text color="#fff" fontWeight={600} fontFamily="Poppins">
                DIA
              </Text>
            </td>
            <td style={{ fontSize: 64 }}>
              <Text color="#fff" fontWeight={600} fontFamily="Poppins">
                {Number(accumEfficiencyData[0]?.count) > 0
                  ? (
                      Number(accumEfficiencyData[0]?.sum) /
                      Number(accumEfficiencyData[0]?.count)
                    ).toFixed(2)
                  : ''}
                %
              </Text>
            </td>
            <td style={{ fontSize: 64 }}>
              <Text color="#fff" fontWeight={600} fontFamily="Poppins">
                {Number(accumQualityData[0]?.count) > 0
                  ? (
                      Number(accumQualityData[0]?.sum) /
                      Number(accumQualityData[0]?.count)
                    ).toFixed(2)
                  : ''}
                %
              </Text>
            </td>
          </tr>

          <tr>
            <td rowSpan={4}>
              <Text fontFamily="Poppins">HORA</Text>
            </td>
            <td>
              <Text fontFamily="Poppins">
                {`${getHourOfDate(efficiencyData[0]?.date)} - ${
                  efficiencyData[0]?.eficiency || '--'
                }%`}
              </Text>
            </td>
            <td>
              <Text fontFamily="Poppins">{`${getHourOfDate(
                qualityData[0]?.date,
              )} - ${qualityData[0]?.quality || '--'}%`}</Text>
            </td>
          </tr>

          <tr>
            <td>
              <Text fontFamily="Poppins">
                {`${getHourOfDate(efficiencyData[1]?.date)} - ${
                  efficiencyData[1]?.eficiency || '--'
                }%`}
              </Text>
            </td>
            <td>
              <Text fontFamily="Poppins">
                {`${getHourOfDate(qualityData[1]?.date)} - ${
                  qualityData[1]?.quality || '--'
                }%`}
              </Text>
            </td>
          </tr>

          <tr>
            <td>
              <Text fontFamily="Poppins">
                {`${getHourOfDate(efficiencyData[2]?.date)} - ${
                  efficiencyData[2]?.eficiency || '--'
                }%`}
              </Text>
            </td>
            <td>
              <Text fontFamily="Poppins">
                {`${getHourOfDate(qualityData[2]?.date)} - ${
                  qualityData[2]?.quality || '--'
                }%`}
              </Text>
            </td>
          </tr>

          <tr>
            <td>
              <Text fontFamily="Poppins">{`${getHourOfDate(
                efficiencyData[3]?.date,
              )} - ${efficiencyData[3]?.eficiency || '--'}%`}</Text>
            </td>
            <td>
              <Text fontFamily="Poppins">{`${getHourOfDate(
                qualityData[3]?.date,
              )} - ${qualityData[3]?.quality || '--'}%`}</Text>
            </td>
          </tr>

          <tr style={{ backgroundColor: '#bed1f1' }}>
            <td rowSpan={4}>
              <Text fontFamily="Poppins">DEFEITOS</Text>
            </td>
            <td style={{ fontSize: 32, textAlign: 'left' }} colSpan={5}>
              <Text fontFamily="Poppins">
                {defects[0]?.defect?.description}
              </Text>
            </td>
          </tr>
          <tr style={{ backgroundColor: '#bed1f1' }}>
            <td style={{ fontSize: 32, textAlign: 'left' }} colSpan={5}>
              <Text fontFamily="Poppins">
                {defects[1]?.defect?.description}
              </Text>
            </td>
          </tr>
          <tr style={{ backgroundColor: '#bed1f1' }}>
            <td style={{ fontSize: 32, textAlign: 'left' }} colSpan={5}>
              <Text fontFamily="Poppins">
                {defects[2]?.defect?.description}
              </Text>
            </td>
          </tr>
          <tr style={{ backgroundColor: '#bed1f1' }}>
            <td style={{ fontSize: 32, textAlign: 'left' }} colSpan={5}>
              <Text fontFamily="Poppins">
                {defects[3]?.defect?.description}
              </Text>
            </td>
          </tr>
        </StyledTable>
      </Row>
    </Content>
  );
}
