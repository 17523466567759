import React, { useState, useEffect } from 'react';
import { easeQuadInOut } from 'd3-ease';
import { CircularProgressbar } from 'react-circular-progressbar';
import _ from 'lodash';
import {
  Grid,
  List,
  Paper,
  Divider,
  Tooltip,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
} from '@material-ui/core';
import Paginate from '../../../components/Paginate';

import AnimatedProgressProvider from '../../../components/AnimatedProgressProvider';
import { Box, Flex } from 'rebass';

import Filter from '../../../components/Filter';
import { useStyles } from '../../styles';
import 'react-circular-progressbar/dist/styles.css';
import api from '../../../services/api';

const SiloMassStocksByFormule = () => {
  const classes = useStyles();
  const token = localStorage.getItem('@icep-app/token');
  const unity = localStorage.getItem('@icep-app/unity');
  const [progress, setProgress] = useState(true);
  const [silosData, setSilosData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(0);
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState();
  const [filterParams, setFilterParams] = useState({});
  const idUser = localStorage.getItem('@icep-app/iduser');
  const unity_id = localStorage.getItem('@icep-app/unity');
  const params = {
    period: '',
    selectedLine: selectedLine,
  };

  useEffect(() => {
    async function fetch() {
      if (!!selectedLine) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const resp = await api.get(
          `/silomassstocks/unity/${unity}/line/${selectedLine}/groupbyformule?page=${
            page + 1
          }`,
        );
        setTotalRows(
          resp.data && resp.data.metadata && resp.data.metadata.totalRows,
        );
        setTotalPages(
          resp.data && resp.data.metadata && resp.data.metadata.totalPages,
        );
        setSilosData(resp.data.source);
        setProgress(false);
      }
    }
    fetch();
  }, [page, selectedLine, token, unity]);

  const onPageChange = async (item) => {
    setPage(item.selected);
  };

  useEffect(() => {
    async function handleGetLines() {
      const resp = await api.get(
        `/userlines/${idUser}/unity/${unity_id}/lines`,
      );
      setLines(resp.data);
      setSelectedLine(resp?.data[0]?.line_id);
    }
    handleGetLines();
  }, [idUser, unity_id]);

  function getHeightPercentage(row) {
    if (
      parseFloat(row?.total_height) <= 0 ||
      parseFloat(row?.total_full_height) <= 0
    )
      return 0;
    else
      return (
        (parseFloat(row?.total_full_height) / parseFloat(row?.total_height)) *
        100
      );
  }

  const handleFilter = async (item) => {
    let line_id =
      item.selectedLine && item.selectedLine.value
        ? item.selectedLine.value
        : params.selectedLine;
    setSelectedLine(line_id);
  };

  const handleClear = async (item) => {
    setFilterParams({ period: filterParams.period });
    setSelectedLine(lines[0]?.line_id);
  };

  const getLine = (id) => {
    var filtered = lines.filter((item) => {
      return item.line_id === parseInt(id);
    });
    return filtered[0]?.line?.description;
  };

  return (
    <>
      <Grid className={classes.qualityRoot}>
        <Grid
          alignItems="flex-end"
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              Produção
            </Typography>
            <Typography component="h1" variant="h3">
              Estoque de Massa dos Silos - Por Fórmula
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {getLine(selectedLine)}
            </Typography>
          </Grid>
          <Grid item>
            <Filter
              onClear={handleClear}
              onFilter={handleFilter}
              className={classes.filterButton}
              showPeriod={false}
              filterParams={filterParams}
            />
          </Grid>
        </Grid>
        {progress ? (
          <Paper className={classes.paper}>
            <Box container justifyContent="center" alignItems="center">
              <Flex justifyContent="center" alignItems="center">
                <CircularProgress
                  className={classes.circularProgress}
                  color="secondary"
                />
              </Flex>
            </Box>
          </Paper>
        ) : (
          <div className={classes.results}>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Total de {totalRows} registros econtrados. Pagina {page + 1} de{' '}
              {totalPages}
            </Typography>
            <Paper>
              <List dense className={classes.root}>
                {silosData && silosData.length > 0 ? (
                  silosData &&
                  silosData.length > 0 &&
                  silosData.map((row) => (
                    <>
                      <ListItem key={row.id} alignItems="flex-start">
                        <Tooltip
                          title="Estoque"
                          placement="left"
                          aria-label="add"
                        >
                          <ListItemAvatar className={classes.progress}>
                            <AnimatedProgressProvider
                              valueStart={0}
                              valueEnd={getHeightPercentage(row)}
                              duration={1.4}
                              easingFunction={easeQuadInOut}
                            >
                              {(value) => {
                                const roundedValue = _.round(value, 2);
                                return (
                                  <CircularProgressbar
                                    value={value}
                                    text={`${roundedValue}%`}
                                    styles={{
                                      root: {
                                        width: '70px',
                                      },
                                      path: {
                                        stroke: '#5C6BC0',
                                        strokeLinecap: 'butt',
                                        transition: 'none',
                                      },
                                      trail: {
                                        stroke: '#d6d6d6',
                                      },
                                      text: {
                                        fill: '#9FA8DA',
                                        fontSize: '20px',
                                        fontWeight: '700',
                                      },
                                    }}
                                  />
                                );
                              }}
                            </AnimatedProgressProvider>
                          </ListItemAvatar>
                        </Tooltip>
                        <ListItemText
                          className={classes.textDense}
                          primary={<>&nbsp;</>}
                          secondary={
                            <>
                              <Box>
                                <Flex>
                                  <Typography
                                    className={classes.labelList}
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    Fórmula:
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    {row.formule.description}
                                  </Typography>
                                </Flex>
                              </Box>
                              <Box>
                                <Flex>
                                  <Typography
                                    className={classes.labelList}
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    Capacidade:
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    {row?.total_capacity}
                                  </Typography>
                                  &nbsp;/&nbsp;
                                  <Typography
                                    className={classes.labelList}
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    Quantidade:
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    {row?.quantity}.
                                  </Typography>
                                </Flex>
                              </Box>
                              <Box>
                                <Flex>
                                  <Typography
                                    className={classes.labelList}
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    Altura:
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    {row?.total_height}
                                  </Typography>
                                  &nbsp;/&nbsp;
                                  <Typography
                                    className={classes.labelList}
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    Altura cheia:
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    {row?.total_full_height}.
                                  </Typography>
                                </Flex>
                              </Box>
                            </>
                          }
                        />
                      </ListItem>
                      <Divider className={classes.divider} />
                    </>
                  ))
                ) : (
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            align="center"
                            variant="body2"
                            color="textPrimary"
                          >
                            Sem dados
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </Paper>
          </div>
        )}
        <div className={classes.paginate}>
          <Paginate
            pageCount={totalPages}
            onPageChange={onPageChange}
            pageRangeDisplayed={1}
          />
        </div>
      </Grid>
    </>
  );
};
export default SiloMassStocksByFormule;
