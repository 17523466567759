import React, { useState, useEffect } from 'react';
import api from '../../../../../../services/api';
import {
  Fab,
  Table,
  Paper,
  Tooltip,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Flex } from 'rebass';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import Modal from '../../../../../../components/Modal';
import NewAttatchmentModal from './components/NewAttatchmentModal';

const AttatchmentsModal = props => {
  const token = localStorage.getItem('@icep-app/token');
  const [attatchments, setAttatchments] = useState([]);
  const [openNewAttatchment, setOpenNewAttatchment] = useState(false);

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    fab: {
      right: theme.spacing(1),
      position: 'relative',
    },
  }));

  const updateAttatchments = async () => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resp = await api.get(
      `/showroomattatchments/showroom/${props.data.id}`,
    );
    getAttatchments(resp.data);
  };

  const handleDeleteAttatchment = async item => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resp = await api.delete(`/showroomattatchments/${item.id}`);

    if (resp.status !== 200) {
      alert('Ops, não conseguimos excluir este registro. :(');
    }

    updateAttatchments();
  };

  const getAttatchments = async data => {
    const rows = [];

    data.forEach(item => {
      const obj = {};
      obj.id = item.id;
      obj.description = item.description;
      obj.path = item.path;
      obj.file = item.file;
      obj.type = item.file.split('.')[1];
      obj.url = item.path + item.file;
      rows.push(obj);
    });

    setAttatchments(rows);
  };

  const handleCloseNew = () => {
    setOpenNewAttatchment(false);
    updateAttatchments();
  };

  const classes = useStyles();

  useEffect(() => {
    async function fetch() {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(
        `/showroomattatchments/showroom/${props.data.id}`,
      );
      getAttatchments(resp.data);
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Descrição</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Arquivo</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attatchments.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.description}
                </TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>
                  <a href={row.url} target="_blank" rel="noopener noreferrer">
                    {row.file}
                  </a>
                </TableCell>
                <TableCell align="center" width={50}>
                  <Tooltip
                    placement="top"
                    title="Remover Anexo"
                    aria-label="add"
                  >
                    <IconButton
                      aria-label="remove"
                      onClick={() => handleDeleteAttatchment(row)}
                    >
                      <FontAwesomeIcon
                        size="sm"
                        color="red"
                        icon={faTrashAlt}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <br />
      <Flex justifyContent="flex-end">
        <Tooltip placement="top" title="Adicionar anexo" aria-label="add">
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            onClick={() => setOpenNewAttatchment(true)}
          >
            <FontAwesomeIcon icon={faPlus} color="white" />
          </Fab>
        </Tooltip>
      </Flex>
      <Modal
        size="small"
        title="Anexos"
        subheader={`Adicionar anexo ${props.data &&
          'ao showrrom ' + props.data.id + ' ' + props.data.client}`}
        open={openNewAttatchment}
        handleClose={handleCloseNew.bind(this)}
        component={
          <NewAttatchmentModal
            data={props.data}
            handleClose={handleCloseNew.bind(this)}
          />
        }
      />
    </>
  );
};

export default AttatchmentsModal;
