import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import {
  Button,
  Drawer,
  Divider,
  TextField,
  Typography,
  FormControl,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import DoneAll from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:320px)': {
      marginLeft: '90px',
      marginTop: '15px',
      marginBottom: '0px',
    },
  },
  drawer: {
    width: 420,
    maxWidth: '100%',
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  contentSection: {
    padding: theme.spacing(2, 0),
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  tags: {
    marginTop: theme.spacing(1),
  },
  minAmount: {
    marginRight: theme.spacing(3),
  },
  maxAmount: {
    marginLeft: theme.spacing(3),
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Filter = (props) => {
  const {
    filterParams,
    open,
    label,
    endpoint,
    onClose,
    onClear,
    onFilter,
    className,
    ...rest
  } = props;
  const token = localStorage.getItem('@icep-app/token');
  const classes = useStyles();

  const [formats, setFormats] = useState();
  const [types, setTypes] = useState();

  const [state, setState] = useState({
    type: filterParams.type,
    cycle: filterParams.cicle,
    format: filterParams.format,
  });

  const { type, cycle, format } = state;

  const formatsProps = {
    options:
      formats &&
      formats.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  const typesProps = {
    options:
      types &&
      types.map((option) => ({
        value: option.id,
        title: option.description,
      })),
    getOptionLabel: (option) => option.title,
  };

  useEffect(() => {
    async function fetchFormats() {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(`/formats`);
      setFormats(resp.data);
    }

    async function fetchTypes() {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const resp = await api.get(endpoint);
      setTypes(resp.data);
    }

    fetchTypes();
    fetchFormats();
  }, [endpoint, token]);

  const handleChange = (event, item) => {
    if (item) {
      let name = event.target.id.split('-', 1)[0];
      setState({ ...state, [name]: item });
      filterParams[name] = item;
    }
  };
  const handleInputChange = (name) => (event) => {
    setState({ ...state, [name]: Number(event.target.value) });
    filterParams[name] = event.target.value;
  };
  const handleClear = () => {
    onClear && onClear(state);
    setState({
      type: undefined,
      cicle: '',
      format: undefined,
    });
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onFilter && onFilter(state);
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.header}>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Fechar
          </Button>
        </div>
        <Divider />
        <div className={classes.content}>
          <div className={classes.contentSectionContent}>
            <div className={classes.formGroup}>
              <Typography component="p" gutterBottom variant="overline">
                Formatos
              </Typography>

              <Autocomplete
                {...formatsProps}
                id="format"
                name="format"
                value={format}
                clearOnEscape
                getOptionLabel={(option) => option.title}
                onChange={(event, newValue) => handleChange(event, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Formato"
                    variant="outlined"
                    fullWidth
                  >
                    {' '}
                    Não encontrado
                  </TextField>
                )}
              />
            </div>
          </div>
          <div className={classes.formGroup}>
            <Typography component="p" gutterBottom variant="overline">
              Ciclo Atual Maior que:
            </Typography>
            <div className={classes.fieldGroup}>
              <FormControl
                variant="outlined"
                error={false}
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  id="outlined-basic"
                  label="Ciclo"
                  value={cycle}
                  onChange={handleInputChange('cycle')}
                  variant="outlined"
                />
              </FormControl>
            </div>
          </div>
          <div className={classes.formGroup}>
            <Typography component="p" gutterBottom variant="overline">
              {label}
            </Typography>
            <Autocomplete
              {...typesProps}
              id="type"
              name="type"
              value={type}
              clearOnEscape
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => handleChange(event, newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  fullWidth
                >
                  {' '}
                  Não encontrado
                </TextField>
              )}
            />
          </div>
        </div>
        <div className={classes.actions}>
          <Button fullWidth onClick={handleClear} variant="contained">
            <DeleteIcon className={classes.buttonIcon} />
            Limpar
          </Button>
          <Button color="primary" fullWidth type="submit" variant="contained">
            <DoneAll className={classes.buttonIcon} />
            Aplicar Filtro
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default Filter;
