import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Header from '../../components/Header';
import CardRoute from '../../components/CardRoute';
import { faCheck, faListUl, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  top: {
    flexGrow: 1,
    backgroundColor: 'text',
    paddingBottom: '15px',
  },
  title: {
    paddingLeft: '40px',
    paddingTop: '25px',
    flexGrow: 1,
    backgroundColor: '#f9f9fc',
  },
  container: {
    marginTop: theme.spacing(3),
  },
}));

const Quality = () => {
  const classes = useStyles();
  const { hasPermission } = useAuth();

  return (
    <div className={classes.root}>
      <Header title="Qualidade" description="Veja o que está acontecendo." />
      <Grid className={classes.container} container spacing={3}>
        {hasPermission('EARLY_CARS') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              path="/quality/early-cars"
              title="Carro Adiantado"
              description="Qualidade do carro adiantado"
              icon={<FontAwesomeIcon icon={faListUl} />}
              color="indigo"
            />
          </Grid>
        )}
        {hasPermission('QUALITY_ITEMS') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              color="indigo"
              title="Qualidade"
              path="/quality/items"
              icon={<FontAwesomeIcon icon={faCheck} />}
              description="Acompanhamento de qualidade dos itens"
            />
          </Grid>
        )}
        {hasPermission('MSI_RESOLUTIONS') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              color="indigo"
              title="Segurança"
              path="/quality/safety-machines"
              icon={<FontAwesomeIcon icon={faTools} />}
              description="Resolução de itens de segurança das máquinas"
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Quality;
