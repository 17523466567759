import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../components/Header';
import CardRoute from '../../components/CardRoute';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  top: {
    flexGrow: 1,
    backgroundColor: 'text',
    paddingBottom: '15px',
  },
  title: {
    paddingLeft: '40px',
    paddingTop: '25px',
    flexGrow: 1,
    backgroundColor: '#f9f9fc',
  },
  container: {
    marginTop: theme.spacing(3),
  },
}));

const Matrix = () => {
  const classes = useStyles();
  const { hasPermission } = useAuth();

  return (
    <div className={classes.root}>
      <Header title="Matrizaria" description="Veja o que está acontecendo." />
      <Grid className={classes.container} container spacing={3}>
        {hasPermission('BOX') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              icon={<FontAwesomeIcon icon={faGripHorizontal} />}
              path="/matrix/box"
              title="CAIXAS"
              color="indigo"
              description="Histórico da vida útil das caixas"
            />
          </Grid>
        )}
        {hasPermission('RELIEFS') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              icon={<FontAwesomeIcon icon={faGripHorizontal} />}
              path="/matrix/reliefs"
              title="RELEVOS"
              color="indigo"
              description="Histórico da vida útil dos relevos"
            />
          </Grid>
        )}
        {hasPermission('MURATURES') && (
          <Grid item lg={4} sm={6} xs={12}>
            <CardRoute
              icon={<FontAwesomeIcon icon={faGripHorizontal} />}
              path="/matrix/muratures"
              color="indigo"
              title="MURATURAS"
              description="Histórico da vida útil das muraturas"
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Matrix;
